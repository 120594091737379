<script>
  import { onMount } from "svelte";
  import loadData from "../../utils/loadData";
  import colorScale from "../../utils/colorScale";
  export let ccity;
  import * as d3 from "d3";
  document.addEventListener("touchstart", function () {}, true);

  let cityNames = [];
  let descriptorNames = [];
  let heatMapData = [];
  let descriptorData = [];
  let currentIndex, currentIndexD;

  onMount(async (d) => {
    let dataset = await loadData();
    let cities = dataset[1];
    cities.sort((a, b) => d3.descending(b.city, a.city));
    let descriptors = dataset[2];
    descriptors.sort((a, b) => d3.descending(b.city, a.city));

    cityNames = cities.map((a) => a.city);
    currentIndex = cityNames.indexOf(ccity.city);
    heatMapData = [cities[currentIndex]][0];

    descriptorNames = descriptors.map((a) => a.city);
    currentIndexD = descriptorNames.indexOf(ccity.city);
    descriptorData = [descriptors[currentIndexD]][0];
  });
</script>

<div id="dept-data">
  Access the {ccity.dept}'s
  <a class="records" target="_blank" href={ccity.icomplaints_url}
    >complaints data</a
  >
</div>
<p class="definitions">
  <a href="/#/complaints">Definitions of each variable →</a>
</p>
<div class="strong-1">Internal Complaints</div>
<div id="wrap">
  <div id="vars-col-1">
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.icomplaints_download)}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_download}">
          {heatMapData.icomplaints_download}
        </div>
      </div>
      Downloadable
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.icomplaints_url)}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_url}">
          {heatMapData.icomplaints_url}
        </div>
      </div>
      URL
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.icomplaints_agg_type)}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_agg_type}">
          {heatMapData.icomplaints_agg_type}
        </div>
      </div>
      Aggregation level
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.icomplaints_civ_demo)}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_civ_demo}">
          {heatMapData.icomplaints_civ_demo}
        </div>
      </div>
      Civilian demographics
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.icomplaints_codebook)}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_codebook}">
          {heatMapData.icomplaints_codebook}
        </div>
      </div>
      Codebook or data dictionary
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.icomplaints_data_type)}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_data_type}">
          {heatMapData.icomplaints_data_type}
        </div>
      </div>
      Data format
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.icomplaints_embed)}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_embed}">
          {heatMapData.icomplaints_embed}
        </div>
      </div>
      Embedded analytics
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.icomplaints_FAQ)}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_FAQ}">
          {heatMapData.icomplaints_FAQ}
        </div>
      </div>
      FAQ/User guide
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.icomplaints_location)}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_location}">
          {heatMapData.icomplaints_location}
        </div>
      </div>
      Location
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.icomplaints_off_demo)}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_off_demo}">
          {heatMapData.icomplaints_off_demo}
        </div>
      </div>
      Officer demographics
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.icomplaints_coverage)}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_coverage}">
          {heatMapData.icomplaints_coverage}
        </div>
      </div>
      Timespan
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.icomplaints_freq)}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_freq}">
          {heatMapData.icomplaints_freq}
        </div>
      </div>
      Update frequency
    </div>
  </div>
  <div id="vars-col-2">
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.icomplaints_int_ext)}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_int_ext}">
          {heatMapData.icomplaints_int_ext}
        </div>
      </div>
      Internal/External
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.icomplaints_all_cat)}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_all_cat}">
          {heatMapData.icomplaints_all_cat}
        </div>
      </div>
      Allegation Category
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(
          heatMapData.icomplaints_allegation,
        )}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_allegation}">
          {heatMapData.icomplaints_allegation}
        </div>
      </div>
      Specific Allegation
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(
          heatMapData.icomplaints_occ_timestamp,
        )}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_occ_timestamp}">
          {heatMapData.icomplaints_occ_timestamp}
        </div>
      </div>
      Occurrence Timestamp
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(
          heatMapData.icomplaints_rept_timestamp,
        )}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_rept_timestamp}">
          {heatMapData.icomplaints_rept_timestamp}
        </div>
      </div>
      Complaint Reported Timestamp
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(
          heatMapData.icomplaints_inv_timestamp,
        )}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_inv_timestamp}">
          {heatMapData.icomplaints_inv_timestamp}
        </div>
      </div>
      Investigation Timestamp
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(
          heatMapData.icomplaints_inv_findings,
        )}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_inv_findings}">
          {heatMapData.icomplaints_inv_findings}
        </div>
      </div>
      Investigation Finding
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.icomplaints_disc)}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_disc}">
          {heatMapData.icomplaints_disc}
        </div>
      </div>
      Disciplinary Process
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.icomplaints_dispo)}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_dispo}">
          {heatMapData.icomplaints_dispo}
        </div>
      </div>
      Final Disposition
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.icomplaints_penalty)}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_penalty}">
          {heatMapData.icomplaints_penalty}
        </div>
      </div>
      Penalty Imposed
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.icomplaints_appeal)}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_appeal}">
          {heatMapData.icomplaints_appeal}
        </div>
      </div>
      Appeal
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(
          heatMapData.icomplaints_appeal_results,
        )}"
      >
        <div class="scorenumber score{heatMapData.icomplaints_appeal_results}">
          {heatMapData.icomplaints_appeal_results}
        </div>
      </div>
      Appeal Result
    </div>
  </div>
</div>

<div class="strong-1">External Complaints</div>
<div id="wrap">
  <div id="vars-col-1">
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.ecomplaints_download)}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_download}">
          {heatMapData.ecomplaints_download}
        </div>
      </div>
      Downloadable
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.ecomplaints_url)}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_url}">
          {heatMapData.ecomplaints_url}
        </div>
      </div>
      URL
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.ecomplaints_agg_type)}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_agg_type}">
          {heatMapData.ecomplaints_agg_type}
        </div>
      </div>
      Aggregation level
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.ecomplaints_civ_demo)}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_civ_demo}">
          {heatMapData.ecomplaints_civ_demo}
        </div>
      </div>
      Civilian demographics
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.ecomplaints_codebook)}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_codebook}">
          {heatMapData.ecomplaints_codebook}
        </div>
      </div>
      Codebook or data dictionary
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.ecomplaints_data_type)}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_data_type}">
          {heatMapData.ecomplaints_data_type}
        </div>
      </div>
      Data format
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.ecomplaints_embed)}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_embed}">
          {heatMapData.ecomplaints_embed}
        </div>
      </div>
      Embedded analytics
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.ecomplaints_FAQ)}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_FAQ}">
          {heatMapData.ecomplaints_FAQ}
        </div>
      </div>
      FAQ/User guide
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.ecomplaints_location)}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_location}">
          {heatMapData.ecomplaints_location}
        </div>
      </div>
      Location
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.ecomplaints_off_demo)}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_off_demo}">
          {heatMapData.ecomplaints_off_demo}
        </div>
      </div>
      Officer demographics
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.ecomplaints_coverage)}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_coverage}">
          {heatMapData.ecomplaints_coverage}
        </div>
      </div>
      Timespan
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.ecomplaints_freq)}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_freq}">
          {heatMapData.ecomplaints_freq}
        </div>
      </div>
      Update frequency
    </div>
  </div>
  <div id="vars-col-2">
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.ecomplaints_int_ext)}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_int_ext}">
          {heatMapData.ecomplaints_int_ext}
        </div>
      </div>
      Internal/External
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.ecomplaints_all_cat)}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_all_cat}">
          {heatMapData.ecomplaints_all_cat}
        </div>
      </div>
      Allegation Category
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(
          heatMapData.ecomplaints_allegation,
        )}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_allegation}">
          {heatMapData.ecomplaints_allegation}
        </div>
      </div>
      Specific Allegation
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(
          heatMapData.ecomplaints_occ_timestamp,
        )}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_occ_timestamp}">
          {heatMapData.ecomplaints_occ_timestamp}
        </div>
      </div>
      Occurrence Timestamp
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(
          heatMapData.ecomplaints_rept_timestamp,
        )}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_rept_timestamp}">
          {heatMapData.ecomplaints_rept_timestamp}
        </div>
      </div>
      Complaint Reported Timestamp
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(
          heatMapData.ecomplaints_inv_timestamp,
        )}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_inv_timestamp}">
          {heatMapData.ecomplaints_inv_timestamp}
        </div>
      </div>
      Investigation Timestamp
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(
          heatMapData.ecomplaints_inv_findings,
        )}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_inv_findings}">
          {heatMapData.ecomplaints_inv_findings}
        </div>
      </div>
      Investigation Findings
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.ecomplaints_disc)}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_disc}">
          {heatMapData.ecomplaints_disc}
        </div>
      </div>
      Disciplinary Process
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.ecomplaints_dispo)}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_dispo}">
          {heatMapData.ecomplaints_dispo}
        </div>
      </div>
      Final Disposition
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.ecomplaints_penalty)}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_penalty}">
          {heatMapData.ecomplaints_penalty}
        </div>
      </div>
      Penalty Imposed
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(heatMapData.ecomplaints_appeal)}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_appeal}">
          {heatMapData.ecomplaints_appeal}
        </div>
      </div>
      Appeal
    </div>
    <div class="variable">
      <div
        class="var-score"
        style="background-color:{colorScale(
          heatMapData.ecomplaints_appeal_results,
        )}"
      >
        <div class="scorenumber score{heatMapData.ecomplaints_appeal_results}">
          {heatMapData.ecomplaints_appeal_results}
        </div>
      </div>
      Appeal Result
    </div>
  </div>
</div>

<style>
  #wrap {
    display: flex;
    padding: 0 2% 2% 2%;
  }
  #vars-col-1,
  #vars-col-2 {
    width: 40%;
    padding-right: 5%;
  }
  .variable {
    display: flex;
    margin-bottom: 8px;
  }
  .var-score {
    width: 50px;
    height: 25px;
    margin-right: 20px;
    border: 1px solid #0a0319;
  }
  .scorenumber {
    text-align: center;
    color: #fff;
    visibility: hidden;
  }
  .score0 {
    color: #000;
  }
  .variable:hover .scorenumber,
  .var-score:hover .scorenumber {
    visibility: visible;
  }
  #dept-data {
    padding: 2%;
    font-size: 18px;
    border-bottom: 1px solid #000;
    margin-bottom: 1.5em;
  }
  .definitions {
    padding-left: 1em;
  }
  .strong-1 {
    font-family: "Whyte Bold";
    padding: 0 0.8em;
    font-size: 20px;
    margin-bottom: 15px;
  }
  @media all and (max-width: 650px) {
    #wrap {
      flex-direction: column;
    }
    #vars-col-1,
    #vars-col-2,
    #dept-data {
      width: 90%;
      padding: 5% 5% 0 5%;
      margin: 0;
      background-color: #fff;
    }
    #dept-data {
      font-size: 16px;
    }
  }
</style>
