<script>
  import { createEventDispatcher } from "svelte";
  import { push, pop, replace } from "svelte-spa-router";

  export let searchString = "";

  const dispatch = createEventDispatcher();

  function handleKeyUp(e) {
    search();
  }

  let windowWidth = window.innerWidth;

  function search() {
    dispatch("change", searchString);
    push("/");
    if (windowWidth > 600) {
      window.scrollTo({
        top: 250,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 630,
        behavior: "smooth",
      });
      console.log("MOBILE");
    }
  }
</script>

<div class="search-text">
  <div class="search">
    <input
      id="table-search-field"
      type="text"
      placeholder="Search the Index"
      bind:value={searchString}
      on:keyup={(e) => handleKeyUp(e)}
    />
  </div>
</div>

<style>
  .search-text {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    font-family: var(--font-02);
    max-width: 200px;
    margin: 0.3rem 0.3rem 0 0.3rem;
    position: relative;
    pointer-events: all;
  }

  .search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 1.7rem;
    font-size: 0.8rem;
    background-color: var(--bg);
    border: 2px solid var(--usa-blue);
    border-radius: 3px;
    position: relative;
  }

  input {
    padding: 0.3rem 2.3rem 0.3rem 0.3rem;
    font-size: 1rem;
    color: #fff;
    background-color: var(--bg);
    border: 1px solid #abffeb;
    width: 210px;
    background-image: url("https://www.vera.org/dist/img/v2/s-iconw.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    margin: 0 !important;
  }
  input::placeholder {
    color: #fff;
  }
  @media all and (max-width: 870px) {
    input {
      font-size: 14px;
      width: 150px;
    }
  }
  @media all and (max-width: 870px) {
    input#table-search-field {
      width: 200px;
    }
  }
  @media all and (max-width: 500px) {
    input#table-search-field {
      width: 250px;
    }
  }
</style>
