<script>
  import { onMount } from "svelte";
  import loadData from "../../../utils/loadData";
  import * as d3 from "d3";
  import { LayerCake, Svg } from "layercake";
  import Sankey from "../../sankey/Sankey.svelte";
  import data from "../../../data/traffic";
  import CategoryColumn from "../../city-column/CategoryColumn.svelte";
  import CategoryNav from "../../CategoryNav.svelte";
  import { cityTitle } from "../../../utils/store.js";

  cityTitle.set("Traffic/Pedestrian Stops");

  let cities = [],
    ccategory = [],
    categoryData = [];

  onMount(async () => {
    window.scrollTo(0, 0);
    let data = await loadData();
    cities = data[0];

    cities.sort((a, b) => d3.ascending(b.cityScore, a.cityScore));
  });
</script>

<div class="categorypage">
  <h2>Traffic/Pedestrian Stops</h2>
  <p>
    Traffic stops are the most frequent kind of encounter between police officers and members of the public. Police may also stop people outside of a car, which is a pedestrian stop.
  </p>
  <p>
    These encounters are often called “investigative detentions” because the person stopped is not free to leave and the officer is trying to determine whether a crime has occurred or is in progress.
  </p>
  <div class="chart-wrap">
    <CategoryColumn categoryVar={"stops_subtotal"} />
  </div>
  <p class="anno">Of the 94 jurisdictions in the index, 54 do not provide information on traffic and pedestrian stops and received a score of zero.</p>
  <p>
    Cities and counties should publicly report information on all incidents in which police officers stop drivers or pedestrians. Vera examined available traffic and pedestrian stop information for the following transparency characteristics:
  </p>
  <ul>
    <li>
      <span class="strong">Suspected crime</span>: Whether the person being stopped was suspected of committing a crime.
    </li>
    <li>
      <span class="strong">Stop type</span>: Whether the nature of the stop (traffic or pedestrian) is noted.
    </li>
    <li>
      <span class="strong">Source</span>: Whether the dataset provides information about the source of the suspicion that led police to stop the person.
    </li>
    <li>
      <span class="strong">Frisk</span>: Whether data indicates if police conducted a frisk (a limited search consisting of patting the outside of a person’s clothing) during the incident.
    </li>
    <li>
      <span class="strong">Person search</span>: Whether the data indicates incidents in which the police searched a person (e.g., looked inside the person’s pockets, backpack, purse, or other closed areas).
    </li>
    <li>
      <span class="strong">Car search</span>: Whether the dataset flags incidents in which police searched the interior of a car during a traffic stop.
    </li>
    <li>
      <span class="strong">Search reason</span>: Whether information is provided about the reason for any search.
    </li>
    <li>
      <span class="strong">Search result</span>: Whether the results of any searches are contained in the dataset.
    </li>
    <li>
      <span class="strong">Use of force</span>: Whether the data notes stop incidents in which police used force.
    </li>
    <li>
      <span class="strong">Use of force reason</span>: Whether the data indicates the reason for any force used during a stop.
    </li>
    <li>
      <span class="strong">Removed from car</span>: Whether the dataset distinguishes car stops in which police commanded one or more people to exit the vehicle.
    </li>
    <li>
      <span class="strong">Outcome</span>: Whether the data indicates how the stop was resolved (e.g., arrest, ticket issued, no enforcement action, etc.).
    </li>
    <li>
      <span class="strong">URL</span>: Whether the city or county makes data available via an easily located and accessible website.
    </li>
    <li>
      <span class="strong">Downloadability</span>: Whether the information can be downloaded.
    </li>
    <li>
      <span class="strong">Data format</span>: The extent to which the data is in a file format that can be independently analyzed.
    </li>
    <li>
      <span class="strong">Aggregation level</span>: Ideally, data should be available describing each incident; Vera gave a lower score for data that is supplied only in aggregate summary over time (e.g., quarterly or annual totals).
    </li>
    <li>
      <span class="strong">Codebook or data dictionary</span>: Visitors to the website should be provided with a dictionary that explains what kinds of events are recorded in the data and the meanings of any abbreviations, codes, or specialized jargon in the data.
    </li>
    <li>
      <span class="strong">Officer demographics</span>: Whether the data contains the race, ethnicity, or both of involved officers.
    </li>
    <li>
      <span class="strong">Civilian demographics</span>: Whether the data contains the race, ethnicity, or both of involved community members.
    </li>
    <li>
      <span class="strong">Location</span>: Whether the data provides the locations of incidents in the form of GPS or other geocode coordinates, street address, or nearest intersection.
    </li>
    <li>
      <span class="strong">Embedded analytics</span>: Whether the website has online tools with which a visitor can create maps and/or make visualizations such as charts or graphs without downloading data.
    </li>
    <li>
      <span class="strong">Timespan</span>: The length of time the website provides data from: more than two years, one to two years, less than a year, or none (if data is taken down shortly after posting without being archived).
    </li>
    <li>
      <span class="strong">Update frequency</span>: Whether the data is updated biweekly, monthly, quarterly, biannually, annually, or less than annually.
    </li>
    <li>
      <span class="strong">FAQ/User guide</span>: Whether the website contains information to help users understand how to use the website and orient themselves to its features.
    </li>
  </ul>
  <div class="chart-container">
    <LayerCake {data}>
      <Svg>
        <Sankey colorNodes={(d) => "#2E1D97"} colorLinks={(d) => "#97DFD1"} />
      </Svg>
    </LayerCake>
  </div>
</div>

<style>
  .categorypage {
    margin-left: 120px;
  }
  h2 {
    font-family: "Whyte Black";
    font-size: 50px;
    font-weight: bold;
    margin: 0px 0 50px 0;
    padding-top: 5px;
  }
  span.strong {
    font-family: "Whyte Bold";
  }

  .chart-wrap {
    margin: 50px 0 20px;
  }
  .chart-container {
    display: flex;
    width: 100%;
    height: 500px;
    margin: 50px 0;
  }
  .anno{
    font-style: italic;
    color: #555;
    text-align: right;
    width: 60%;
    margin-left: 30%;
    margin-right: 10%;
    margin-top: 0;
    font-size: 14px;
    margin-bottom: 50px;
  }
  @media all and (max-width: 767px) {
    .categorypage {
      margin-left: 0px;
    }
    h2 {
      margin-top: 0px;
      margin-bottom: 30px;
      font-size: 35px;
      line-height: 130%;
    }
    .chart-wrap {
      margin: 30px 0 10px;
    }
    .anno{
      margin-bottom: 30px;
    }
  }
  @media all and (max-width: 500px) {
    .categorypage {
      padding: 0 1em 0 1em;
    }
    h2{
      bottom:15px;
    }
  }
</style>