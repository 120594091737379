export default {
  nodes: [
    // { id: "City Score" },
    // { id: "Use of Force" },
    // { id: "Officer Involved Shootings" },
    { id: "Arrest" },
    // { id: "CFS/911" },
    // { id: "Crime/Crime Mapping" },
    // { id: "Traffic/Ped stops" },
    // { id: "Complaints" },
    // { id: "Police contact info" },
    // { id: "Training" },
    // { id: "Policies" },
    { id: "Presence" },
    { id: "Basic Data Collection" },
    { id: "Dataset Specific Variables" },
    { id: "URL" },
    { id: "Downloadable" },
    { id: "Data Format" },
    { id: "Aggregate Level" },
    { id: "Codebook/Data Dictionary" },
    { id: "Officer Demographics" },
    { id: "Civilian Demographics" },
    { id: "Location" },
    { id: "Embedded Analytics" },
    { id: "Timespan" },
    { id: "Update Frequency" },
    { id: "FAQ/User Guide" },
    { id: "Charge" },
    { id: "Use of Force" },
  ],
  links: [
    // { source: "City Score", target: "Use of Force", value: 13.3 },
    // { source: "City Score", target: "Officer Involved Shootings", value: 13.3 },
    // { source: "City Score", target: "Arrest", value: 10.0 },
    // { source: "City Score", target: "CFS/911", value: 10.0 },
    // { source: "City Score", target: "Crime/Crime Mapping", value: 13.3 },
    // { source: "City Score", target: "Traffic/Ped stops", value: 10.0 },
    // { source: "City Score", target: "Complaints", value: 13.3 },
    // { source: "City Score", target: "Police contact info", value: 6.6 },
    // { source: "City Score", target: "Training", value: 10.0 },
    // { source: "City Score", target: "Policies", value: 6.6 },
    { source: "Arrest", target: "Presence", value: 2 },
    { source: "Arrest", target: "Basic Data Collection", value: 7 },
    {
      source: "Arrest",
      target: "Dataset Specific Variables",
      value: 1,
    },
    { source: "Presence", target: "URL", value: 1 },
    { source: "Presence", target: "Downloadable", value: 1 },
    { source: "Basic Data Collection", target: "Data Format", value: .525 },
    {
      source: "Basic Data Collection",
      target: "Aggregate Level",
      value: .7,
    },
    {
      source: "Basic Data Collection",
      target: "Codebook/Data Dictionary",
      value: 1.05,
    },
    {
      source: "Basic Data Collection",
      target: "Officer Demographics",
      value: 1.05,
    },
    {
      source: "Basic Data Collection",
      target: "Civilian Demographics",
      value: 1.05,
    },
    { source: "Basic Data Collection", target: "Location", value: .35 },
    {
      source: "Basic Data Collection",
      target: "Embedded Analytics",
      value: 0.525,
    },
    { source: "Basic Data Collection", target: "Timespan", value: 0.35 },
    {
      source: "Basic Data Collection",
      target: "Update Frequency",
      value: 0.7,
    },
    { source: "Basic Data Collection", target: "FAQ/User Guide", value: 0.7 },
    {
      source: "Dataset Specific Variables",
      target: "Charge",
      value: 0.5,
    },
    {
      source: "Dataset Specific Variables",
      target: "Use of Force",
      value: 0.5,
    },
  ],
};
