import { csv } from "d3";
import { ptiData as dataPath } from "../inputs/dataPaths";
import { numericSet as heatData } from "../inputs/dataPaths";
import { characterSet as characterData } from "../inputs/dataPaths";
import { updateDiff as diffUpdate } from "../inputs/dataPaths";
import * as d3 from "d3";
const parseDate = d3.timeParse("%Y-%m-%d");

const loadData = async () => {
  const data = await csv(dataPath, (d, i) => {
    return {
      city: d.city,
      state_abbr: d.StateAbr,
      cityId: d.city.split(" ")[0],
      uof_subtotal: (+d.uof_subtotal / 2) * 100,
      shooting_subtotal: (+d.shooting_subtotal / 2) * 100,
      arr_subtotal: (+d.arr_subtotal / 1.5) * 100,
      crime_subtotal: (+d.crime_subtotal / 1) * 100,
      stops_subtotal: (+d.stops_subtotal / 1.5) * 100,
      complaints_subtotal:
        ((+d.icomplaints_subtotal + +d.ecomplaints_subtotal) / 2) *
        100,
      cfs_subtotal: (+d.cfs_subtotal / 1.5) * 100,
      contact_info_subtotal: (+d.police_contact_subtotal / 1) * 100,
      training_subtotal: (+d.training_subtotal / 1.5) * 100,
      policy_subtotal: (+d.policy_subtotal / 1) * 100,
      cityScore: +d.TotalPoints100,
      location: d.Location,
      state: d.State,
      dept: d.NAME,
      type: d.Type,
      uof_url: d.uof_url,
      shooting_url: d.shooting_url,
      arr_url: d.arr_url,
      cfs_url: d.cfs_url,
      crime_url: d.crime_url,
      map_url: d.map_url,
      stops_url: d.stops_url,
      icomplaints_url: d.icomplaints_url,
      police_contact_url: d.police_contact_url,
      ops_manual_url: d.ops_manual_url,
      training_url: d.training_url,
      uof_policy_url: d.uof_policy_url,
    };
  });
  const heatMapData = await csv(heatData, (d, i) => {
    return {
      city: d.city,
      arr_FAQ: +(d.arr_FAQ / 1) * 100,
      arr_agg_type: +(d.arr_agg_type / 1) * 100,
      arr_charge: +(d.arr_charge / 1) * 100,
      arr_civ_demo: +(d.arr_civ_demo / 1) * 100,
      arr_codebook: +(d.arr_codebook / 1) * 100,
      arr_coverage: +(d.arr_coverage / 1) * 100,
      arr_data_type: +(d.arr_data_type / 1) * 100,
      arr_download: +(d.arr_download / 1) * 100,
      arr_embed: +(d.arr_embed / 1) * 100,
      arr_freq: +(d.arr_freq / 1) * 100,
      arr_location: +(d.arr_location / 1) * 100,
      arr_off_demo: +(d.arr_off_demo / 1) * 100,
      arr_uof: +(d.arr_uof / 1) * 100,
      arr_url: +(d.arr_url / 1) * 100,
      cfs_FAQ: +(d.cfs_FAQ / 1) * 100,
      cfs_Priority: +(d.cfs_Priority / 1) * 100,
      cfs_agg_type: +(d.cfs_agg_type / 1) * 100,
      cfs_calltype: +(d.cfs_calltype / 1) * 100,
      cfs_codebook: +(d.cfs_codebook / 1) * 100,
      cfs_coverage: +(d.cfs_coverage / 1) * 100,
      cfs_data_type: +(d.cfs_data_type / 1) * 100,
      cfs_dispatchedFlag: +(d.cfs_dispatchedFlag / 1) * 100,
      cfs_download: +(d.cfs_download / 1) * 100,
      cfs_embed: +(d.cfs_embed / 1) * 100,
      cfs_freq: +(d.cfs_freq / 1) * 100,
      cfs_location: +(d.cfs_location / 1) * 100,
      cfs_officer_initiated: +(d.cfs_officer_initiated / 1) * 100,
      cfs_outcome: +(d.cfs_outcome / 1) * 100,
      cfs_timestamps: +(d.cfs_timestamps / 1) * 100,
      cfs_url: +(d.cfs_url / 1) * 100,
      crime_FAQ: +(d.crime_FAQ / 1) * 100,
      crime_agg_type: +(d.crime_agg_type / 1) * 100,
      crime_charge: +(d.crime_charge / 1) * 100,
      crime_civ_demo: +(d.crime_civ_demo / 1) * 100,
      crime_cleared: +(d.crime_cleared / 1) * 100,
      crime_codebook: +(d.crime_codebook / 1) * 100,
      crime_coverage: +(d.crime_coverage / 1) * 100,
      crime_data_type: +(d.crime_data_type / 1) * 100,
      crime_download: +(d.crime_download / 1) * 100,
      crime_embed: +(d.crime_embed / 1) * 100,
      crime_freq: +(d.crime_freq / 1) * 100,
      crime_location: +(d.crime_location / 1) * 100,
      crime_off_demo: +(d.crime_off_demo / 1) * 100,
      crime_url: +(d.crime_url / 1) * 100,
      crime_vict_demo: +(d.crime_vict_demo / 1) * 100,
      icomplaints_url: +(d.icomplaints_url / 1) * 100,
      icomplaints_data_type: +(d.icomplaints_data_type / 1) * 100,
      icomplaints_agg_type: +(d.icomplaints_agg_type / 1) * 100,
      icomplaints_coverage: +(d.icomplaints_coverage / 1) * 100,
      icomplaints_FAQ: +(d.icomplaints_FAQ / 1) * 100,
      icomplaints_freq: +(d.icomplaints_freq / 1) * 100,
      icomplaints_download: +(d.icomplaints_download / 1) * 100,
      icomplaints_int_ext: +(d.icomplaints_int_ext / 1) * 100,
      icomplaints_codebook: + (d.icomplaints_codebook / 1) * 100,
      icomplaints_off_demo: +(d.icomplaints_off_demo / 1) * 100,
      icomplaints_civ_demo: + (d.icomplaints_civ_demo / 1) * 100,
      icomplaints_location: +(d.icomplaints_location / 1) * 100,
      icomplaints_embed: +(d.icomplaints_embed / 1) * 100,
      icomplaints_all_cat: +(d.icomplaints_all_cat / 1) * 100,
      icomplaints_allegation: + (d.icomplaints_allegation / 1) * 100,
      icomplaints_occ_timestamp: +(d.icomplaints_occ_timestamp / 1) * 100,
      icomplaints_rept_timestamp: +(d.icomplaints_rept_timestamp / 1) * 100,
      icomplaints_inv_timestamp: +(d.icomplaints_inv_timestamp / 1) * 100,
      icomplaints_inv_findings: +(d.icomplaints_inv_findings / 1) * 100,
      icomplaints_disc: +(d.icomplaints_disc / 1) * 100,
      icomplaints_dispo: +(d.icomplaints_dispo / 1) * 100,
      icomplaints_penalty: +(d.icomplaints_penalty / 1) * 100,
      icomplaints_appeal: +(d.icomplaints_appeal / 1) * 100,
      icomplaints_appeal_results: +(d.icomplaints_appeal_results / 1) * 100,
      ecomplaints_url: +(d.ecomplaints_url / 1) * 100,
      ecomplaints_data_type: +(d.ecomplaints_data_type / 1) * 100,
      ecomplaints_agg_type: +(d.ecomplaints_agg_type / 1) * 100,
      ecomplaints_coverage: +(d.ecomplaints_coverage / 1) * 100,
      ecomplaints_freq: +(d.ecomplaints_freq / 1) * 100,
      ecomplaints_FAQ: +(d.ecomplaints_FAQ / 1) * 100,
      ecomplaints_download: +(d.ecomplaints_download / 1) * 100,
      ecomplaints_int_ext: +(d.ecomplaints_int_ext / 1) * 100,
      ecomplaints_codebook: +(d.ecomplaints_codebook / 1) * 100,
      ecomplaints_off_demo: +(d.ecomplaints_off_demo / 1) * 100,
      ecomplaints_civ_demo: +(d.ecomplaints_civ_demo / 1) * 100,
      ecomplaints_location: +(d.ecomplaints_location / 1) * 100,
      ecomplaints_embed: +(d.ecomplaints_embed / 1) * 100,
      ecomplaints_all_cat: +(d.ecomplaints_all_cat / 1) * 100,
      ecomplaints_allegation: +(d.ecomplaints_allegation / 1) * 100,
      ecomplaints_occ_timestamp: +(d.ecomplaints_occ_timestamp / 1) * 100,
      ecomplaints_rept_timestamp: +(d.ecomplaints_rept_timestamp / 1) * 100,
      ecomplaints_inv_timestamp: +(d.ecomplaints_inv_timestamp / 1) * 100,
      ecomplaints_inv_findings: +(d.ecomplaints_inv_findings / 1) * 100,
      ecomplaints_disc: +(d.ecomplaints_disc / 1) * 100,
      ecomplaints_dispo: +(d.ecomplaints_dispo / 1) * 100,
      ecomplaints_penalty: +(d.ecomplaints_penalty / 1) * 100,
      ecomplaints_appeal: +(d.ecomplaints_appeal / 1) * 100,
      ecomplaints_appeal_results: +(d.ecomplaints_appeal_results / 1) * 100,
      ecomplaints_add: +(d.ecomplaints_add / 1) * 100,
      // Tcomplaints_url: +(d.Tcomplaints_url / 1) * 100,
      // Tcomplaints_data_type: +(d.Tcomplaints_data_type / 1) * 100,
      // Tcomplaints_agg_type: +(d.Tcomplaints_agg_type / 1) * 100,
      // Tcomplaints_coverage: +(d.Tcomplaints_coverage / 1) * 100,
      // Tcomplaints_FAQ: +(d.Tcomplaints_FAQ / 1) * 100,
      // Tcomplaints_freq: +(d.Tcomplaints_freq / 1) * 100,
      // Tcomplaints_download: +(d.Tcomplaints_download / 1) * 100,
      // Tcomplaints_int_ext: +(d.Tcomplaints_int_ext / 1) * 100,
      // Tcomplaints_codebook: +(d.Tcomplaints_codebook / 1) * 100,
      // Tcomplaints_off_demo: +(d.Tcomplaints_off_demo / 1) * 100,
      // Tcomplaints_civ_demo: +(d.Tcomplaints_civ_demo / 1) * 100,
      // Tcomplaints_location: +(d.Tcomplaints_location / 1) * 100,
      // Tcomplaints_embed: +(d.Tcomplaints_embed / 1) * 100,
      // Tcomplaints_all_cat: +(d.Tcomplaints_all_cat / 1) * 100,
      // Tcomplaints_allegation: +(d.Tcomplaints_allegation / 1) * 100,
      // Tcomplaints_occ_timestamp: +(d.Tcomplaints_occ_timestamp / 1) * 100,
      // Tcomplaints_rept_timestamp: +(d.Tcomplaints_rept_timestamp / 1) * 100,
      // Tcomplaints_inv_timestamp: +(d.Tcomplaints_inv_timestamp / 1) * 100,
      // Tcomplaints_inv_findings: +(d.Tcomplaints_inv_findings / 1) * 100,
      // Tcomplaints_disc: +(d.Tcomplaints_disc / 1) * 100,
      // Tcomplaints_dispo: +(d.Tcomplaints_dispo / 1) * 100,
      // Tcomplaints_penalty: +(d.Tcomplaints_penalty / 1) * 100,
      // Tcomplaints_appeal: +(d.Tcomplaints_appeal / 1) * 100,
      // Tcomplaints_appeal_results: +(d.Tcomplaints_appeal_results / 1) * 100,
      ops_manual_url: +(d.ops_manual_url / 1) * 100,
      police_contact_level: +(d.police_contact_level / 1) * 100,
      police_contact_type: +(d.police_contact_type / 1) * 100,
      police_contact_url: +(d.police_contact_url / 1) * 100,
      shooting_FAQ: +(d.shooting_FAQ / 1) * 100,
      shooting_acc_discharge: +(d.shooting_acc_discharge / 1) * 100,
      shooting_agg_type: +(d.shooting_agg_type / 1) * 100,
      shooting_bodycam: +(d.shooting_bodycam / 1) * 100,
      shooting_civ_demo: +(d.shooting_civ_demo / 1) * 100,
      shooting_codebook: +(d.shooting_codebook / 1) * 100,
      shooting_coverage: +(d.shooting_coverage / 1) * 100,
      shooting_data_type: +(d.shooting_data_type / 1) * 100,
      shooting_download: +(d.shooting_download / 1) * 100,
      shooting_embed: +(d.shooting_embed / 1) * 100,
      shooting_freq: +(d.shooting_freq / 1) * 100,
      shooting_justification: +(d.shooting_justification / 1) * 100,
      shooting_location: +(d.shooting_location / 1) * 100,
      shooting_nonfatal_only: +(d.shooting_nonfatal_only / 1) * 100,
      shooting_off_demo: +(d.shooting_off_demo / 1) * 100,
      shooting_url: +(d.shooting_url / 1) * 100,
      stops_FAQ: +(d.stops_FAQ / 1) * 100,
      stops_agg_type: +(d.stops_agg_type / 1) * 100,
      stops_civ_demo: +(d.stops_civ_demo / 1) * 100,
      stops_codebook: +(d.stops_codebook / 1) * 100,
      stops_coverage: +(d.stops_coverage / 1) * 100,
      stops_crime: +(d.stops_crime / 1) * 100,
      stops_data_type: +(d.stops_data_type / 1) * 100,
      stops_download: +(d.stops_download / 1) * 100,
      stops_embed: +(d.stops_embed / 1) * 100,
      stops_freq: +(d.stops_freq / 1) * 100,
      stops_frisk: +(d.stops_frisk / 1) * 100,
      stops_location: +(d.stops_location / 1) * 100,
      stops_off_demo: +(d.stops_off_demo / 1) * 100,
      stops_outcome: +(d.stops_outcome / 1) * 100,
      stops_person_search: +(d.stops_person_search / 1) * 100,
      stops_removed: +(d.stops_removed / 1) * 100,
      stops_search_reason: +(d.stops_search_reason / 1) * 100,
      stops_search_result: +(d.stops_search_result / 1) * 100,
      stops_source: +(d.stops_source / 1) * 100,
      stops_type: +(d.stops_type / 1) * 100,
      stops_uof: +(d.stops_uof / 1) * 100,
      stops_uof_reason: +(d.stops_uof_reason / 1) * 100,
      stops_url: +(d.stops_url / 1) * 100,
      stops_vehicle_search: +(d.stops_vehicle_search / 1) * 100,
      training_download: +(d.training_download / 1) * 100,
      training_url: +(d.training_url / 1) * 100,
      uof_FAQ: +(d.uof_FAQ / 1) * 100,
      uof_agg_type: +(d.uof_agg_type / 1) * 100,
      uof_arrest_made: +(d.uof_arrest_made / 1) * 100,
      uof_bodycam: +(d.uof_bodycam / 1) * 100,
      uof_civ_demo: +(d.uof_civ_demo / 1) * 100,
      uof_civ_injury: +(d.uof_civ_injury / 1) * 100,
      uof_civ_weapon: +(d.uof_civ_weapon / 1) * 100,
      uof_codebook: +(d.uof_codebook / 1) * 100,
      uof_coverage: +(d.uof_coverage / 1) * 100,
      uof_data_type: +(d.uof_data_type / 1) * 100,
      uof_download: +(d.uof_download / 1) * 100,
      uof_embed: +(d.uof_embed / 1) * 100,
      uof_fatal: +(d.uof_fatal / 1) * 100,
      uof_foia_requested: +(d.uof_foia_requested / 1) * 100,
      uof_force_type: +(d.uof_force_type / 1) * 100,
      uof_freq: +(d.uof_freq / 1) * 100,
      uof_justification: +(d.uof_justification / 1) * 100,
      uof_location: +(d.uof_location / 1) * 100,
      uof_off_demo: +(d.uof_off_demo / 1) * 100,
      uof_off_injury: +(d.uof_off_injury / 1) * 100,
      uof_off_weapon: +(d.uof_off_weapon / 1) * 100,
      uof_policy_download: +(d.uof_policy_download / 1) * 100,
      uof_policy_url: +(d.uof_policy_url / 1) * 100,
      uof_shootings_only: +(d.uof_shootings_only / 1) * 100,
      uof_url: +(d.uof_url / 1) * 100,
    };
  });
  const descriptors = await csv(characterData, (d, i) => {
    return d;
  });
  const diffUpdateData = await csv(diffUpdate, (d, i) => {
    return {
      city: d.city,
      Dataset: d.Dataset,
      Original: +d.Original,
      Difference: +d.Difference,
      Validation: +d.Validation,
      Updates: +d.Updates,
      UpdateDate: parseDate(d.Date),
      UpdateTime: d.Time,
    };
  });
  return [
    data.filter(
      (d) =>
        d.city !== "Additional Cities from Major City Chiefs" &&
        d.city !==
        "Largest Cities in States with No City in US Top 50 Largest Cities"
    ),
    heatMapData,
    descriptors,
    diffUpdateData,
  ];
};
export default loadData;
