<script>
  import { onMount } from "svelte";
  import { LayerCake, Svg, Html } from "layercake";
  import { scaleBand } from "d3-scale";
  import Column from "./Column.svelte";
  import AxisX from "./AxisX.svelte";
  import AxisY from "./AxisY.svelte";
  import Tooltip from "../Tooltip.html.svelte";
  import * as d3 from "d3";
  export let categoryVar;
  import loadData from "../../utils/loadData";

  let cities = [],
    data = [];
  let cityNames = [];
  let cityScores = [];
  let xKey = "city";
  const yKey = categoryVar;
  let yKeyVar = "a." + categoryVar;

  onMount(async (d) => {
    let dataset = await loadData();

    cities = dataset[0];
    data = d3.filter(cities, (d) => d.cityScore != 0);
    data.sort((a, b) => d3.descending(b.city, a.city));
    sortCity(data, "init");
  });

  let scaleX;

  function sortCity(data, key, yKeyVar) {
    let columns = data.data;
    if (key == "city") {
      data = columns.sort((a, b) => d3.ascending(a[key], b[key]));
      cityNames = data.map((a) => a.city);
      cityScores = data.map((a) => a[yKey].toFixed(0));
      data.forEach((d) => {
        d[yKey] = +d[yKey];
      });
      scaleX = cityNames;
      d3.selectAll(".sort.city").classed("active", true);
      d3.selectAll(".sort.score").classed("active", false);
    } else if (key == "init") {
      data = data;
      cityNames = data.map((a) => a.city);
      cityScores = data.map((a) => a[yKey].toFixed(0));
      data.forEach((d) => {
        d[yKey] = +d[yKey];
      });
      scaleX = cityNames;
      d3.selectAll(".sort.city").classed("active", true);
      d3.selectAll(".sort.score").classed("active", false);
    } else {
      data = columns.sort((a, b) => d3.descending(a[yKey], b[yKey]));
      cityNames = data.map((a) => a.city);
      cityScores = data.map((a) => a[yKey].toFixed(0));
      scaleX = cityNames;
      data.forEach((d) => {
        d[yKey] = +d[yKey];
      });
      d3.selectAll(".sort.city").classed("active", false);
      d3.selectAll(".sort.score").classed("active", true);
    }
  }

  let evt;
  let hideTooltip = false;
</script>

<div id="sort-buttons">
  <div class="sort city active" on:click={sortCity({ data }, "city")}>
    Sort City
  </div>
  <div class="sort score" on:click={sortCity({ data }, "cityScore")}>
    Sort Score
  </div>
</div>
<div id="wrap">
  <div class="chart-container">
    <LayerCake
      padding={{ top: 0, right: 0, bottom: 0, left: 20 }}
      x={xKey}
      y={yKey}
      xScale={scaleBand().paddingInner([0.07]).round(true)}
      xDomain={cityNames}
      yDomain={[0, 100]}
      {data}
    >
      <Svg>
        <AxisX gridlines={false} ticks={4} />
        <AxisY gridlines={true} />
        <Column
          {cityScores}
          {data}
          on:mousemove={(event) => (evt = hideTooltip = event)}
          on:mouseout={() => (hideTooltip = true)}
        />
      </Svg>
      <Html pointerEvents={false}>
        {#if hideTooltip !== true}
          <Tooltip {evt} let:detail>
            {@const tooltipData = { ...detail.props }}
            {@const keys = Object.keys(tooltipData)}
            {@const vals = Object.values(tooltipData)}
            <div class="row">
              {tooltipData.location}, {tooltipData.state}: {vals[
                keys.indexOf(yKey)
              ].toFixed(0)}
            </div>
          </Tooltip>
        {/if}
      </Html>
    </LayerCake>
  </div>
</div>

<style>
  #wrap {
    display: flex;
    margin-top: 2em;
  }
  .chart-container {
    width: 90%;
    height: 270px;
    background-color: #fff;
  }
  #sort-buttons {
    width: 100%;
    margin-top: 4em;
    display: flex;
  }
  .sort {
    padding: 8px 10px;
    border: 2px solid #0e002c;
    margin-right: 15px;
    border-radius: 2px;
    cursor: pointer;
  }
  .sort.active,
  .sort:hover {
    background-color: #0e002c !important;
    color: #fff !important;
  }
  .annotations {
    font-size: 14px;
    margin-left: 10px;
  }
  .median {
    margin-top: 120px;
  }
  @media all and (max-width: 767px) {
    .annotations {
      display: none;
    }
    #sort-buttons {
      margin-top: 1em;
    }
  }
</style>
