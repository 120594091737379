export default {
  nodes: [
    // { id: "City Score" },
    { id: "Use of Force" },
    // { id: "Officer Involved Shootings" },
    // { id: "Arrest" },
    // { id: "CFS/911" },
    // { id: "Crime/Crime Mapping" },
    // { id: "Traffic/Ped stops" },
    // { id: "Complaints" },
    // { id: "Police contact info" },
    // { id: "Training" },
    // { id: "Policies" },
    { id: "Presence" },
    { id: "Basic Data Collection" },
    { id: "Dataset Specific Variables" },
    { id: "URL" },
    { id: "Downloadable" },
    { id: "Data Format" },
    { id: "Aggregate Level" },
    { id: "Codebook/Data Dictionary" },
    { id: "Officer Demographics" },
    { id: "Civilian Demographics" },
    { id: "Location" },
    { id: "Embedded Analytics" },
    { id: "Timespan" },
    { id: "Update Frequency" },
    { id: "FAQ/User Guide" },
    { id: "Fatalities Included" },
    { id: "Type of Force Used" },
    { id: "Officer Injury" },
    { id: "Civilian Injury" },
    { id: "Justification" },
    { id: "Civilian Weapon" },
    { id: "Weapon Used by Officer" },
    { id: "Bodycam Footage" },
    { id: "Arrest Made" },
  ],
  links: [
    // { source: "City Score", target: "Use of Force", value: 13.3 },
    // { source: "City Score", target: "Officer Involved Shootings", value: 13.3 },
    // { source: "City Score", target: "Arrest", value: 10.0 },
    // { source: "City Score", target: "CFS/911", value: 10.0 },
    // { source: "City Score", target: "Crime/Crime Mapping", value: 13.3 },
    // { source: "City Score", target: "Traffic/Ped stops", value: 10.0 },
    // { source: "City Score", target: "Complaints", value: 13.3 },
    // { source: "City Score", target: "Police contact info", value: 6.6 },
    // { source: "City Score", target: "Training", value: 10.0 },
    // { source: "City Score", target: "Policies", value: 6.6 },
    { source: "Use of Force", target: "Presence", value: 2.66 },
    { source: "Use of Force", target: "Basic Data Collection", value: 6.65 },
    {
      source: "Use of Force",
      target: "Dataset Specific Variables",
      value: 3.99,
    },
    { source: "Presence", target: "URL", value: 1.33 },
    { source: "Presence", target: "Downloadable", value: 1.33 },
    { source: "Basic Data Collection", target: "Data Format", value: 0.49875 },
    {
      source: "Basic Data Collection",
      target: "Aggregate Level",
      value: 0.665,
    },
    {
      source: "Basic Data Collection",
      target: "Codebook/Data Dictionary",
      value: 0.9975,
    },
    {
      source: "Basic Data Collection",
      target: "Officer Demographics",
      value: 0.9975,
    },
    {
      source: "Basic Data Collection",
      target: "Civilian Demographics",
      value: 0.9975,
    },
    { source: "Basic Data Collection", target: "Location", value: 0.3325 },
    {
      source: "Basic Data Collection",
      target: "Embedded Analytics",
      value: 0.49875,
    },
    { source: "Basic Data Collection", target: "Timespan", value: 0.3325 },
    {
      source: "Basic Data Collection",
      target: "Update Frequency",
      value: 0.665,
    },
    { source: "Basic Data Collection", target: "FAQ/User Guide", value: 0.665 },
    {
      source: "Dataset Specific Variables",
      target: "Fatalities Included",
      value: 0.798,
    },
    {
      source: "Dataset Specific Variables",
      target: "Type of Force Used",
      value: 0.798,
    },
    {
      source: "Dataset Specific Variables",
      target: "Officer Injury",
      value: 0.3192,
    },
    {
      source: "Dataset Specific Variables",
      target: "Civilian Injury",
      value: 0.3192,
    },
    {
      source: "Dataset Specific Variables",
      target: "Justification",
      value: 0.3192,
    },
    {
      source: "Dataset Specific Variables",
      target: "Civilian Weapon",
      value: 0.3192,
    },
    {
      source: "Dataset Specific Variables",
      target: "Weapon Used by Officer",
      value: 0.7182,
    },
    {
      source: "Dataset Specific Variables",
      target: "Bodycam Footage",
      value: 0.1995,
    },
    {
      source: "Dataset Specific Variables",
      target: "Arrest Made",
      value: 0.1995,
    },
  ],
};
