<script>
  import loadData from "../../utils/loadData";
  import * as d3 from "d3";
  import { onMount } from "svelte";

  export let ccity;
  let diffsD = [];
  let diffdate;

  $: updateData();

  const formatDate = d3.timeFormat("%B %d, %Y");

  async function getDiffDate() {
    let data = await loadData();
    diffsD = d3.filter(
      data[3],
      (d) => d.city == ccity.city && d.Difference !== 0
    );
    diffsD.sort((a, b) => a.Date - b.Date);
    diffdate = formatDate(diffsD[0].UpdateDate);
    return diffdate;
  }

  async function updateData() {
    diffdate = await getDiffDate();
  }
</script>

{#if diffdate !== undefined}
  <button on:click={() => window.scrollTo(0, document.body.scrollHeight - 100)}>
    Updated: {diffdate}
  </button>
{/if}

<style>
  button {
    font-family: inherit;
    font-size: inherit;
    -webkit-padding: 0;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    border: 0px;
    border-radius: 0px;
    background-color: #fff7f0;
    color: #0050a0;
  }
  button:hover {
    text-decoration: underline;
  }
</style>
