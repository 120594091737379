<script>
  import { onMount } from "svelte";
  import loadData from "../utils/loadData";
  import * as d3 from "d3";

  const colors = [
    { color: "#FFF7F0", value: 0, desc: "min" },
    // { color: "#CEC0BC", value: 1, desc: '' },
    { color: "#BEAFBB", value: 10, desc: "" },
    { color: "#AE9FBA", value: 20, desc: "" },
    { color: "#9E8EB8", value: 30, desc: "" },
    { color: "#9988b8", value: 37, desc: "median" },
    { color: "#8E7EB7", value: 40, desc: "" },
    { color: "#7E6DB6", value: 50, desc: "" },
    { color: "#6E5DB5", value: 60, desc: "" },
    { color: "#5543b2", value: 66, desc: "highest" },
    { color: "#5E4CB3", value: 70, desc: "" },
    { color: "#4E3CB2", value: 80, desc: "" },
    { color: "#3E2BB1", value: 90, desc: "" },
    { color: "#0E002C", value: 100, desc: "max" },
  ];

  const extent = d3.extent(colors, (d) => d.value);

  let pageWidth = d3.select(".app-wrapper").node().getBoundingClientRect();

  const padding = 9;
  const width = pageWidth.width;
  const barHeight = 30;
  const height = 76;

  let appPad;
  if (width > 1150 && width <= 1360) {
    appPad = 246;
  } else if (width > 767 && width <= 1150) {
    appPad = 198;
  } else if (width <= 767) {
    appPad = 55;
  } else {
    appPad = 310;
  }
  const innerWidth = width - appPad;

  const xScale = d3.scaleLinear().range([0, innerWidth]).domain(extent);

  let minScore,
    maxScore,
    cities = [];

  var xTicks = colors
    .filter((f) => f.value == 0 || f.value == 100)
    .map((d) => d.value);

  var xAxis = d3
    .axisBottom(xScale)
    .tickSize(barHeight * 1.5)
    .tickValues(xTicks);

  let el;

  onMount(() => {
    var svg = d3.select(el).attr("width", innerWidth).attr("height", height);

    var g = svg.append("g").attr("transform", "translate(" + padding + ", 0)");

    var defs = svg.append("defs");
    var linearGradient = defs
      .append("linearGradient")
      .attr("id", "ptiGradient");
    linearGradient
      .selectAll("stop")
      .data(colors)
      .enter()
      .append("stop")
      .attr(
        "offset",
        (d) => ((d.value - extent[0]) / (extent[1] - extent[0])) * 100 + "%"
      )
      .attr("stop-color", (d) => d.color);

    g.append("rect")
      .attr("width", innerWidth)
      .attr("height", barHeight)
      .style("fill", "url(#ptiGradient)");

    g.selectAll("text").attr("font-size", 16);

    g.append("g").call(xAxis).select(".domain").remove();
  });
  onMount(async () => {
    let data = await loadData();
    cities = data[0];
    maxScore = d3.min(cities, (d) => d.cityScore);
    minScore = d3.min(cities, (d) => d.cityScore);
  });
</script>

<svg bind:this={el} id="legend">
  <g />
</svg>

<style>
  svg {
    width: 100%;
    height: 100%;
  }
  #legend {
    height: 70px;
  }
</style>
