<div id="intro">
  <p class="lead">
    Community demands for police accountability require information about what
    police do. However, police data transparency is low nationwide.
  </p>
  <p>
    Use Vera’s Police Data Transparency Index to see how locations across the United
    States are performing in their release of data and to access the data that
    is available. The vision of police transparency reflected in the index is
    rigorous and informed by conversations with justice advocates, service
    providers, and people directly impacted by the criminal legal system.
  </p>
  <div class="tabs">
    <div class="tab">
      <input type="checkbox" id="chck1" />
      <label class="tab-label" for="chck1"
        ><span class="label-text">How Vera calculated the scores </span></label
      >
      <div class="tab-content">
        <p>
          Covering 94 cities and counties where 25 percent of the U.S.
          population lives, the Police Data Transparency Index assigns each location
          a score out of 100 measuring its level of data transparency.
        </p>
        <p>
          Vera identified 10 core data-transparency categories, grouping and
          scoring them as follows:
        </p>
        <ol>
          <li>
            Police use of physical force or weapons, and complaints about police
            conduct (up to 40 points).
          </li>
          <li>
            Police patrol activities—including responses to calls for service,
            arrests, and traffic and pedestrian stops—and police training (up to
            40 points).
          </li>
          <li>
            Crime reports, department policies, and information about
            nonemergency ways to contact the department (up to 20 points).
          </li>
        </ol>
        <p>
          To earn top scores, police data must be accessible and usable. For
          example, cities should make their police department’s data
          downloadable for independent analysis and should publish guidance on
          how to use the data. The index only considers data that governments
          proactively make available; it excludes data that is only accessible
          via records requests or other methods that place the burden of
          information gathering on the public.
        </p>
        <p>
          Data also needs to be meaningful. Vera awarded points to cities that
          regularly update their police data, detail individual incidents, and
          include information about the race and ethnicity of the people
          involved.
        </p>
        <p>
          Vera collected information on a total of 74 such criteria for each
          location. You can find a complete list of these criteria and details
          of how Vera calculated scores in the <a href="/#/Methodology"
            >methodology</a
          >.
        </p>
      </div>
    </div>
  </div>
</div>

<style>
  #intro {
  }
  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  .tabs {
    margin-top: 20px;
    margin-bottom: -10px;
  }
  .lead {
    font-size: 30px;
    font-family: "Whyte Bold";
    color: #0e002c;
    margin: 0 auto 1em;
    line-height: 130%;
  }
  /* .lead {
    font-family: "GT Sectra Bold";
    color: #0a0319;
    margin: 0 auto 1.2em;
    font-size: 35px;
    line-height: 130%;
  } */
  p {
    margin: 0.8em 0;
  }

  /* Accordion styles */

  .tab {
    width: 100%;
    overflow: hidden;
  }
  .tab-label {
    cursor: pointer;
  }
  .label-text {
    border-bottom: 1px solid #d2bab4;
  }
  .tab-label::after {
    content: "❯";
    width: 1em;
    height: 1em;
    text-align: center;
    margin-left: 10px;
  }
  .tab-content {
    max-height: 0;
    padding: 1em 1em 0 1em;
    transition: all 0.35s;
  }

  input:checked + .tab-label::after {
    transform: rotate(90deg) !important;
    transition: all 0.5s;
  }

  input:checked ~ .tab-content {
    max-height: 100vh;
    padding: 1em;
  }

  @media all and (max-width: 767px) {
    .tab-label {
      flex-direction: column;
    }
    .lead {
      font-size: 22px;
      margin: 0 auto 1em;
    }
    input:checked ~ .tab-content {
      max-height: 190vh;
    }
    h6 {
      font-size: 18px;
      line-height: 135%;
    }
  }
  @media all and (max-width: 500px) {
    #intro {
      padding: 0 1em;
    }
  }
</style>
