export default {
  nodes: [
    // { id: "City Score" },
    // { id: "Use of Force" },
    // { id: "Officer Involved Shootings" },
    // { id: "Arrest" },
    // { id: "CFS/911" },
    { id: "Crime Reports" },
    // { id: "Traffic/Ped stops" },
    // { id: "Complaints" },
    // { id: "Police contact info" },
    // { id: "Training" },
    // { id: "Policies" },
    { id: "Presence" },
    { id: "Basic Data Collection" },
    { id: "Dataset Specific Variables" },
    { id: "URL" },
    { id: "Downloadable" },
    { id: "Data Format" },
    { id: "Aggregate Level" },
    { id: "Codebook/Data Dictionary" },
    { id: "Officer Demographics" },
    { id: "Victim Demographics" },
    { id: "Location" },
    { id: "Embedded Analytics" },
    { id: "Timespan" },
    { id: "Update Frequency" },
    { id: "FAQ/User Guide" },
    { id: "Charge" },
    { id: "Accused Demographic" },
    { id: "Cleared" },
  ],
  links: [
    // { source: "City Score", target: "Use of Force", value: 13.3 },
    // { source: "City Score", target: "Officer Involved Shootings", value: 13.3 },
    // { source: "City Score", target: "Arrest", value: 10.0 },
    // { source: "City Score", target: "CFS/911", value: 10.0 },
    // { source: "City Score", target: "Crime Reports", value: 13.3 },
    // { source: "City Score", target: "Traffic/Ped stops", value: 10.0 },
    // { source: "City Score", target: "Complaints", value: 13.3 },
    // { source: "City Score", target: "Police contact info", value: 6.6 },
    // { source: "City Score", target: "Training", value: 10.0 },
    // { source: "City Score", target: "Policies", value: 6.6 },
    { source: "Crime Reports", target: "Presence", value: 2 },
    { source: "Crime Reports", target: "Basic Data Collection", value: 6 },
    {
      source: "Crime Reports",
      target: "Dataset Specific Variables",
      value: 2,
    },
    { source: "Presence", target: "URL", value: 1 },
    { source: "Presence", target: "Downloadable", value: 1 },
    { source: "Basic Data Collection", target: "Data Format", value: 0.45 },
    {
      source: "Basic Data Collection",
      target: "Aggregate Level",
      value: 0.6,
    },
    {
      source: "Basic Data Collection",
      target: "Codebook/Data Dictionary",
      value: 0.9,
    },
    {
      source: "Basic Data Collection",
      target: "Officer Demographics",
      value: 0.9,
    },
    {
      source: "Basic Data Collection",
      target: "Victim Demographics",
      value: 0.9,
    },
    { source: "Basic Data Collection", target: "Location", value: .3 },
    {
      source: "Basic Data Collection",
      target: "Embedded Analytics",
      value: 0.45,
    },
    { source: "Basic Data Collection", target: "Timespan", value: 0.3 },
    {
      source: "Basic Data Collection",
      target: "Update Frequency",
      value: 0.6,
    },
    { source: "Basic Data Collection", target: "FAQ/User Guide", value: 0.6 },
    {
      source: "Dataset Specific Variables",
      target: "Charge",
      value: 0.9,
    },
    {
      source: "Dataset Specific Variables",
      target: "Accused Demographic",
      value: 0.6,
    },
    {
      source: "Dataset Specific Variables",
      target: "Cleared",
      value: 0.5,
    },
  ],
};
