<script>
  import { onMount } from "svelte";
  import loadData from "../../utils/loadData";
  import colorScale from "../../utils/colorScale";
  export let ccity;
  import * as d3 from "d3";
  import Btn from "../Btn.svelte";

  let cityNames = [];
  let descriptorNames = [];
  let heatMapData = [];
  let descriptorData = [];
  let currentIndex, currentIndexD;

  onMount(async (d) => {
    let dataset = await loadData();
    let cities = dataset[1];
    cities.sort((a, b) => d3.descending(b.city, a.city));
    let descriptors = dataset[2];
    descriptors.sort((a, b) => d3.descending(b.city, a.city));

    cityNames = cities.map((a) => a.city);
    currentIndex = cityNames.indexOf(ccity.city);
    heatMapData = [cities[currentIndex]][0];

    descriptorNames = descriptors.map((a) => a.city);
    currentIndexD = descriptorNames.indexOf(ccity.city);
    descriptorData = [descriptors[currentIndexD]][0];
  });
</script>

<div id="dept-data">
  Access the {ccity.dept}'s
  <a class="records" target="_blank" href={ccity.police_contact_url}
    >police contact information data</a
  >
</div>
<p class="definitions">
  <a href="/#/policecontactinformation">Definitions of each variable →</a>
</p>
<div id="wrap">
  <div id="vars-col-1">
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(
            heatMapData.police_contact_level
          )}"
        >
        <div class="scorenumber score{heatMapData.police_contact_level}">{heatMapData.police_contact_level}</div>
        </div>
      Contact level
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.police_contact_type)}"
        >
        <div class="scorenumber score{heatMapData.police_contact_type}">{heatMapData.police_contact_type}</div>
        </div>
      Contact type
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.police_contact_url)}"
        >
        <div class="scorenumber score{heatMapData.police_contact_url}">{heatMapData.police_contact_url}</div>
        </div>
      URL
    </div>
  </div>
</div>

<style>
  #wrap {
    display: flex;
    padding: 0 2% 2% 2%;
  }
  #vars-col-1 {
    width: 90%;
    padding-right: 5%;
  }
  .variable {
    display: flex;
    margin-bottom: 8px;
  }
  .var-score {
    width: 50px;
    height: 25px;
    margin-right: 20px;
    border: 1px solid #0a0319;
  }
  .scorenumber{
    text-align: center;
    color:#fff;
    visibility: hidden;
  }
  .score0{
    color:#000;
  }
  .variable:hover .scorenumber,
  .var-score:hover .scorenumber{
    visibility: visible;
  }
  #dept-data {
    padding: 2%;
    font-size: 18px;
    border-bottom: 1px solid #000;
    margin-bottom: 1.5em;
  }
  .definitions {
    padding-left: 1em;
  }
  .strong {
    font-family: "Whyte Bold";
    padding: 0.5em 0;
  }
  @media all and (max-width: 650px) {
    #wrap {
      flex-direction: column;
    }
    #vars-col-1,
    #dept-data {
      width: 90%;
      padding: 5% 5% 0 5%;
      margin: 0;
      background-color: #fff;
    }
    #dept-data {
      font-size: 16px;
    }
  }
</style>
