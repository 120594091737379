<script>
  document.addEventListener("touchstart", function(){}, true);
  let hide = "";  // Svelte style property

  function closeMenu(){
    hide = 'yes'
  }
</script>

<button class="menu">
  <ul>
    <li>
      Menu
      <ul class:hidden={hide === 'yes'}>
        <div class="row">
          <div class="col">
            <a href="/#/" on:click={closeMenu}><div class="link level1">Cities</div></a>
            <div class="tab">
              <input type="checkbox" id="chck2" />
              <label class="tab-label" for="chck2">Data Types</label>
              <div class="tab-content">
                <a href="/#/arrests" on:click={closeMenu}><div class="link level2">Arrests</div></a>
                <a href="/#/callsforservice"  on:click={closeMenu}
                  ><div class="link level2">Calls for Service/911 Calls</div></a
                >
                <a href="/#/complaints"  on:click={closeMenu}
                  ><div class="link level2">
                    Complaints About Police Misconduct
                  </div></a
                >
                <a href="/#/crime"  on:click={closeMenu}
                  ><div class="link level2">Crime Reports</div></a
                >
                <a href="/#/instancesofofficersshootingfirearms" on:click={closeMenu}
                  ><div class="link level2">
                    Instances of Officers Shooting Firearms
                  </div></a
                >
                <a href="/#/policecontactinformation" on:click={closeMenu}
                  ><div class="link level2">Police Contact Information</div></a
                >
                <a href="/#/policies" on:click={closeMenu}><div class="link level2">Policies</div></a
                >
                <a href="/#/trafficpedestrianstops" on:click={closeMenu}
                  ><div class="link level2">Traffic/Pedestrian Stops</div></a
                >
                <a href="/#/training" on:click={closeMenu}><div class="link level2">Training</div></a
                >
                <a href="/#/useofforce" on:click={closeMenu}
                  ><div class="link level2">Use of Force</div></a
                >
              </div>
            </div>
            <a href="/#/methodology" on:click={closeMenu}
              ><div class="link level1">Methodology</div></a
            >
            <a href="/#/acknowledgments" on:click={closeMenu}
              ><div class="link level1">Acknowledgments</div></a
            >
          </div>
        </div>
      </ul>
    </li>
  </ul>
</button>

<style>
  button.menu{
    margin:0 0 0 20px;
    padding:0;
  }
  .menu {
    box-sizing: border-box;
    width: 100px;
    border: 1px solid #abffeb;
    border-radius: 3px;
    margin-left: 15px;
    margin-top: 1px;
    z-index: 1000;
  }
  .menu:hover {
    background-color: #abffeb !important;
    color: #0e002c !important;
  }
  .menu ul {
    list-style: none;
    padding: 5px 0;
    margin: 0;
  }
  .menu ul li,
  .menu ul li a {
    opacity: 1;
    color: #ffffff;
    cursor: pointer;
    transition: 200ms;
    text-decoration: none;
    white-space: nowrap;
    font-weight: 700;
  }
  .menu ul:hover li {
    color: #0e002c;
  }
  .menu ul li:hover,
  .menu ul li a:hover {
    opacity: 1;
  }
  .menu ul:hover li ul,
  .menu ul:active li ul {
    opacity: 1;
    visibility: visible;
  }
  .menu ul:hover,
  .menu ul a:hover {
    background-color: #abffeb;
    color: #0e002c !important;
  }
  .menu ul li a,
  .menu ul li a a {
    background-color: #0e002c;
    display: flex;
    align-items: center;
    height: 100%;
    width: calc(100% - .5em);
    border-bottom: 1px solid #2e1d97;
  }
  .menu ul li:hover a,
  .menu ul li:hover a a {
    background-color: #0e002c;
    color: #fff;
  }
  .menu ul li a,
  .menu ul li .tab-content a{
    background-color: #0a0319;
    border-left:.5em solid #0a0319;
  }
  .menu ul li a:hover,
  .menu ul li .tab-content a:hover{
    border-left:.5em solid #97dfd1;
  }

  .menu ul .link::before {
    padding-right: 0;
    display: none;
  }
  .menu > ul {
    display: flex;
    height: var(--menu-height);
    align-items: center;
    background-color: #0e002c;
  }
  .menu > ul li {
    position: relative;
    margin: 0 8px;
  }
  .menu > ul li ul.hidden {
    visibility: hidden;
    max-height: 0;  
  }
  .menu > ul li ul {
    visibility: hidden;
    opacity: 0;
    padding: 0;
    min-width: 160px;
    background-color: #0e002c;
    position: absolute;
    top: 28px;
    right: 0;
    transform: translateX(14%);
    transition: 200ms;
    transition-delay: 200ms;
    border: 1px solid #97dfd1;
  }
  .menu > ul li:hover > ul,
  .menu > ul li:active > ul {
    opacity: 1;
    visibility: visible;
    transition-delay: 0ms;
  }
  .link.level1 {
    padding: 10px 15px;
    color: #fff;
  }
  .link.level2 {
    padding: 10px;
    border-bottom: 0px !important;
    color: #fff;
  }

  /* Accordion styles */
  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  .tab {
    width: 100%;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid #2e1d97;
  }
  .tab-label {
    display: flex;
    justify-content: space-between;
    padding: .75em 1em .75em 1.5em;
    background: #0e002c;
    font-weight: bold;
    cursor: pointer;
  }
  .tab-label:hover {
    background: #0e002c;
  }
  .tab-label::after {
    content: "❯";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.35s;
  }
  .tab-content {
    max-height: 0;
    padding: 0 1em 0 0;
    color: #fff;
    background-color: #0a0319;
    transition: all 0.35s;
  }
  .tab-content a {
    border-bottom: 0px !important;
    /* add left hover effect */
  }
  .tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #0e002c;
    cursor: pointer;
  }
  .tab-close:hover {
    background: #0e002c;
  }

  input:checked + .tab-label {
    background: #0e002c;
    border-bottom: 1px solid #2e1d97;
  }
  input:checked + .tab-label::after {
    transform: rotate(90deg);
  }
  input:checked ~ .tab-content {
    max-height: 100vh;
    padding: 0 1em 0 0;
  }

  @media (hover: none) {
    .menu > ul li ul,
    .menu ul li,
    .menu ul li a,
    .menu ul li:hover,
    .menu ul li a:hover,
    .menu > ul li:hover > ul,
    .menu > ul li:active > ul,
    input {
      opacity: unset;
    }

    ul.hidden {
      visibility: hidden !important;
    }
  }
</style>
