<script>
  import { getContext, createEventDispatcher } from "svelte";
  import { raise } from "layercake";
  import * as d3 from "d3";

  const { data, xGet, yGet, yRange, xScale } = getContext("LayerCake");

  $: columnWidth = (d) => {
    const vals = $xGet(d);
    return Math.max(0, vals[1] - vals[0]);
  };

  export let cityScores;

  $: columnHeight = (d) => {
    // console.log('d',d)
    return $yRange[0] - $yGet(d);
  };

  $: medianHeight = (d) => {
    return $yRange[0] - $yGet(d);
  };

  /** @type {String} [fill='#00e047'] - The shape's fill color. */
  export const fill = "#333";

  /** @type {String} [stroke='#0A0319'] - The shape's stroke color. */
  export let stroke = "#0A0319";

  /** @type {Number} [strokeWidth=0] - The shape's stroke width. */
  export let strokeWidth = 0;

  export let highlight;

  const dispatch = createEventDispatcher();

  function handleMousemove(feature) {
    return function handleMousemoveFn(e) {
      raise(this);
      // When the element gets raised, it flashes 0,0 for a second so skip that
      if (e.layerX !== 0 && e.layerY !== 0) {
        dispatch("mousemove", { e });
      }
    };
  }
</script>

<g
  class="column-group"
  on:mouseout={(e) => dispatch("mouseout")}
  on:blur={(e) => dispatch("mouseout")}
>
  {#each $data as d, i}
    <rect
      class="group-rect"
      data-id={i}
      x={$xScale.bandwidth ? $xGet(d) : $xGet(d)[0]}
      y={$yGet(d, cityScores[i])}
      width={$xScale.bandwidth ? $xScale.bandwidth() : columnWidth(d)}
      height={columnHeight(d, cityScores[i])}
      fill={i == highlight ? "#2E1D97" : "#97DFD1"}
      {stroke}
      stroke-width={strokeWidth}
      on:mouseover={(e) => dispatch("mousemove", { e, props: d })}
      on:focus={(e) => dispatch("mousemove", { e, props: d })}
      on:mousemove={(e) => handleMousemove(e, d)}
    />
  {/each}
  {#if d3.median(cityScores.map(Number)) === 100}
    <line
      class="max-score"
      x1="-20"
      x2="97.8%"
      y1="0"
      y2="0"
      stroke-width="1"
      stroke="#000"
    />
    <text x="98.5%" y="5" font-size="12"> Max & med. score </text>
  {:else}
    <line
      class="max-score"
      x1="-20"
      x2="97.8%"
      y1="0"
      y2="0"
      stroke-width="1"
      stroke="#000"
    />
    <line
      class="median-score"
      x1="-20"
      x2="97.8%"
      y1={(1 - d3.median(cityScores.map(Number)) / 100) * 270}
      y2={(1 - d3.median(cityScores.map(Number)) / 100) * 270}
      stroke-width="1"
      stroke="#000"
    />
    <text x="98.5%" y="5" font-size="12"> Maximum score </text>
    <text
      x="98.5%"
      y={(1 - d3.median(cityScores.map(Number)) / 100) * 270 + 5}
      font-size="12"
    >
      Median score
    </text>
  {/if}
</g>

<style>
  .group-rect:hover {
    /* fill: #abffeb; */
    opacity: 0.75;
  }
  .group-rect:focus {
    outline: none;
    border: 0;
  }
</style>
