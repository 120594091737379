import { writable } from "svelte/store";

export const cityTitle = writable("");
export const dataTypeTitle = writable("");

export const textSearchFilter = createTextSearchFilter();
export const citiesData = createCities();

function createTextSearchFilter() {
  const { subscribe, set } = writable("");

  return {
    subscribe,
    set,
    reset: () => set(""),
  };
}

function createCities() {
  const { subscribe, set } = writable("[]");

  return {
    subscribe,
    set,
    reset: () => set(""),
  };
}
