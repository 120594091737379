<script>
  import { onMount } from "svelte";
  import loadData from "../../../utils/loadData";
  import * as d3 from "d3";
  import { LayerCake, Svg } from "layercake";
  import Sankey from "../../sankey/Sankey.svelte";
  import data from "../../../data/complaints";
  import CategoryColumn from "../../city-column/CategoryColumn.svelte";
  import CategoryNav from "../../CategoryNav.svelte";
  import { cityTitle } from "../../../utils/store.js";

  cityTitle.set("Complaints");

  let cities = [],
    ccategory = [],
    categoryData = [];

  onMount(async () => {
    window.scrollTo(0, 0);
    let data = await loadData();
    cities = data[0];

    cities.sort((a, b) => d3.ascending(b.cityScore, a.cityScore));
  });
</script>

<div class="categorypage">
  <h2>Complaints About Police Misconduct</h2>
  <p>
    Communities often express great concern about misbehavior by police
    officers. Police departments ordinarily have an internal affairs,
    professional standards, or similar internal office dedicated to receiving
    and investigating complaints against police officers. Complaints may be
    filed by community members or by other police officers.
  </p>
  <p>
    Many places have determined that the police should not police themselves and
    have created independent or quasi-independent agencies to remove complaints
    about officer conduct from police control. Vera believes that implementing
    an independent civilian oversight body aligns with community needs and best
    practices.
  </p>
  <p>
    However, parallel internal review of complaints should still be conducted
    even where an external oversight agency exists. The results of internal
    reviews can inform department leadership of problems with training, policy,
    and supervision.
  </p>
  <div class="chart-wrap">
    <CategoryColumn categoryVar={"complaints_subtotal"} />
  </div>
  <p class="anno">Of the 94 jurisdictions in the index, 21 do not provide information on complaints and received a score of zero.</p>
  <p>
    Vera therefore evaluated the availability of data both from internal
    investigative bodies—such as internal affairs departments—and from
    independent oversight bodies when measuring the transparency of police data
    about misconduct complaints. Vera examined the presence of the following
    characteristics of police complaint data:
  </p>
  <ul>
    <li>
      <span class="strong">Internal/External</span>: Whether information
      distinguishes complaints filed by members of the public from complaints
      filed by police officers.
    </li>
    <li>
      <span class="strong">Allegation category</span>: Whether the general topic
      area of the complaint is provided (for example, use of force or failure to
      take police action).
    </li>
    <li>
      <span class="strong">Specific allegation</span>: Whether the detailed
      nature of the complaint is given (for example, an unjustified frisk, or an
      unjustified strip search).
    </li>
    <li>
      <span class="strong">Occurrence timestamp</span>: Whether the dataset
      indicates the date of the complaint event.
    </li>
    <li>
      <span class="strong">Complaint reported timestamp</span>: Whether the
      dataset indicates the date on which the complaint was filed.
    </li>
    <li>
      <span class="strong">Investigation timestamp</span>: Whether the dataset
      indicates the date on which investigation of the complaint was completed.
    </li>
    <li>
      <span class="strong">Investigation finding</span>: Whether the data
      provides the outcome of the investigation.
    </li>
    <li>
      <span class="strong">Disciplinary process</span>: Whether information is
      given about the disciplinary process stemming from the complaint (for
      example, whether summary discipline was given or a formal disciplinary
      hearing was held).
    </li>
    <li>
      <span class="strong">Final disposition</span>: Whether the dataset
      indicates the outcome of the complaint at the end of the disciplinary
      investigation.
    </li>
    <li>
      <span class="strong">Penalty imposed</span>: Whether the dataset indicates
      the type of discipline imposed (for example, a week’s suspension,
      retraining, or a letter of reprimand).
    </li>
    <li>
      <span class="strong">Appeal</span>: Whether information is given about
      officer appeals to overturn disciplinary penalties.
    </li>
    <li>
      <span class="strong">Appeal result</span>: Whether the dataset provides
      the results of appeals to overturn disciplinary penalties (for example,
      whether a fired officer is ordered to be reinstated or the length of a
      suspension is altered on appeal).
    </li>
    <li>
      <span class="strong">Presence of additional agencies with data</span>: In
      a small number of places, Vera discovered multiple external police
      oversight agencies. Vera indexed the primary agency and added information
      about the number of additional agencies reporting data.
    </li>
    <li>
      <span class="strong">URL</span>: Whether the city or county makes data
      available via an easily located and accessible website.
    </li>
    <li>
      <span class="strong">Downloadability</span>: Whether the information can
      be downloaded.
    </li>
    <li>
      <span class="strong">Data format</span>: The extent to which the data is
      in a file format that can be independently analyzed.
    </li>
    <li>
      <span class="strong">Aggregation level</span>: Ideally, data should be
      available describing each incident; Vera gave a lower score for data that
      is supplied only in aggregate summary over time (e.g., quarterly or annual
      totals).
    </li>
    <li>
      <span class="strong">Codebook or data dictionary</span>: Visitors to the
      website should be provided with a dictionary that explains what kinds of
      events are recorded in the data and the meanings of any abbreviations,
      codes, or specialized jargon in the data.
    </li>
    <li>
      <span class="strong">Officer demographics</span>: Whether the data
      contains the race, ethnicity, or both of involved officers.
    </li>
    <li>
      <span class="strong">Civilian demographics</span>: Whether the data
      contains the race, ethnicity, or both of involved community members.
    </li>
    <li>
      <span class="strong">Location</span>: Whether the data provides the
      locations of incidents in the form of GPS or other geocode coordinates,
      street address, or nearest intersection.
    </li>
    <li>
      <span class="strong">Embedded analytics</span>: Whether the website has
      online tools with which a visitor can create maps and/or make
      visualizations such as charts or graphs without downloading data.
    </li>
    <li>
      <span class="strong">Timespan</span>: The length of time the website
      provides data from: more than two years, one to two years, less than a
      year, or none (if data is taken down shortly after posting without being
      archived).
    </li>
    <li>
      <span class="strong">Update frequency</span>: Whether the data is updated
      biweekly, monthly, quarterly, biannually, annually, or less than annually.
    </li>
    <li>
      <span class="strong">FAQ/User guide</span>: Whether the website contains
      information to help users understand how to use the website and orient
      themselves to its features.
    </li>
  </ul>
  <div class="chart-container">
    <LayerCake {data}>
      <Svg>
        <Sankey colorNodes={(d) => "#2E1D97"} colorLinks={(d) => "#97DFD1"} />
      </Svg>
    </LayerCake>
  </div>
</div>

<style>
  .categorypage {
    margin-left: 120px;
  }
  h2 {
    font-family: "Whyte Black";
    font-size: 50px;
    font-weight: bold;
    margin: 0px 0 50px 0;
    padding-top: 5px;
  }
  span.strong {
    font-family: "Whyte Bold";
  }

  .chart-wrap {
    margin: 50px 0 20px;
  }
  .chart-container {
    display: flex;
    width: 100%;
    height: 700px;
    margin: 50px 0;
  }
  .anno{
    font-style: italic;
    color: #555;
    text-align: right;
    width: 60%;
    margin-left: 30%;
    margin-right: 10%;
    margin-top: 0;
    font-size: 14px;
    margin-bottom: 50px;
  }
  @media all and (max-width: 767px) {
    .categorypage {
      margin-left: 0px;
    }
    h2 {
      margin-top: 0px;
      margin-bottom: 30px;
      font-size: 35px;
      line-height: 130%;
    }
    .chart-wrap {
      margin: 30px 0 10px;
    }
    .anno{
      margin-bottom: 30px;
    }
  }
  @media all and (max-width: 500px) {
    .categorypage {
      padding: 0 1em 0 1em;
    }
    h2{
      bottom:15px;
    }
  }
</style>