import Home from "../components/pages/Home.svelte";
import City from "../components/pages/City.svelte";
import DataType from "../components/pages/DataType.svelte";
import UseOfForce from "../components/pages/categories/UseOfForce.svelte";
import OfficerInvolvedShootings from "../components/pages/categories/OfficerInvolvedShootings.svelte";
import Arrests from "../components/pages/categories/Arrests.svelte";
import CallsForService from "../components/pages/categories/CallsForService.svelte";
import Crime from "../components/pages/categories/Crime.svelte";
import TrafficPedestrianStops from "../components/pages/categories/TrafficPedestrianStops.svelte";
import Complaints from "../components/pages/categories/Complaints.svelte";
import PoliceContactInformation from "../components/pages/categories/PoliceContactInformation.svelte";
import Training from "../components/pages/categories/Training.svelte";
import Policies from "../components/pages/categories/Policies.svelte";
import Acknowledgments from "../components/pages/Acknowledgments.svelte";
import Methodology from "../components/pages/Methodology.svelte";
import NotFound from "../components/pages/NotFound.svelte";
import Contact from "../components/pages/Contact.svelte";

const routes = {
  "/": Home,
  "/city/:cityName": City,
  "/datatype/:dataType": DataType,
  "/useofforce": UseOfForce,
  "/instancesofofficersshootingfirearms": OfficerInvolvedShootings,
  "/arrests": Arrests,
  "/callsforservice": CallsForService,
  "/crime": Crime,
  "/trafficpedestrianstops": TrafficPedestrianStops,
  "/complaints": Complaints,
  "/policecontactinformation": PoliceContactInformation,
  "/training": Training,
  "/policies": Policies,
  "/acknowledgments": Acknowledgments,
  "/methodology": Methodology,
  "/report": Contact,
  "*": NotFound,
};

export default routes;
