<script>
  import * as d3 from "d3";
  import colorScale from "../../utils/colorScale";
  export let ccity;
  import UoFDetail from "../score-detail/UoFDetail.svelte";
  import OISDetail from "../score-detail/OISDetail.svelte";
  import ArrestDetail from "../score-detail/ArrestDetail.svelte";
  import CFSDetail from "../score-detail/CFSDetail.svelte";
  import CrimeDetail from "../score-detail/CrimeDetail.svelte";
  import StopsDetail from "../score-detail/StopsDetail.svelte";
  import ComplaintsDetail from "../score-detail/ComplaintsDetail.svelte";
  import TrainingDetail from "../score-detail/TrainingDetail.svelte";
  import PolicyDetail from "../score-detail/PolicyDetail.svelte";
  import ContactInfoDetail from "../score-detail/ContactInfoDetail.svelte";
  let pageWidth = d3.select(".app-wrapper").node().getBoundingClientRect();
  let width = pageWidth.width;
</script>

<div class="tabs">
  <div class="tab">
    <input type="checkbox" id="rd7" name="rd" />
    <label
      class="tab-label {ccity.complaints_subtotal === 0 ? 'zero' : ''}"
      for="rd7"
      style="background-color:{colorScale(ccity.complaints_subtotal)}"
      ><div
        class="category-name {ccity.complaints_subtotal === 0 ? 'zero' : ''}"
      >
        Complaints
      </div>
      <div
        class="category-score {ccity.complaints_subtotal === 0 ? 'zero' : ''}"
      >
        {ccity.complaints_subtotal.toFixed(0)}
      </div></label
    >
    <div class="tab-content">
      <div class="category-name" />
      <div class="category-score"><ComplaintsDetail {ccity} /></div>
    </div>
  </div>
  <div class="tab ois">
    <input type="checkbox" id="rd2" name="rd" />
    <label
      class="tab-label {ccity.shooting_subtotal === 0 ? 'zero' : ''}"
      for="rd2"
      style="background-color:{colorScale(ccity.shooting_subtotal)}"
      ><div class="category-name {ccity.shooting_subtotal === 0 ? 'zero' : ''}">
        Instances of Officers Shooting Firearms
      </div>
      <div class="category-score {ccity.shooting_subtotal === 0 ? 'zero' : ''}">
        {ccity.shooting_subtotal.toFixed(0)}
      </div></label
    >
    <div class="tab-content">
      <div class="category-name" />
      <div class="category-score"><OISDetail {ccity} /></div>
    </div>
  </div>
  <div class="tab">
    <input type="checkbox" id="rd1" name="rd" />
    <label
      class="tab-label {ccity.uof_subtotal === 0 ? 'zero' : ''}"
      for="rd1"
      style="background-color:{colorScale(ccity.uof_subtotal)}"
      ><div class="category-name {ccity.uof_subtotal === 0 ? 'zero' : ''}">
        Use of Force
      </div>
      <div class="category-score {ccity.uof_subtotal === 0 ? 'zero' : ''}">
        {ccity.uof_subtotal.toFixed(0)}
      </div></label
    >
    <div class="tab-content">
      <!-- <div class="definition">use of physical force or weapons</div> -->
      <div class="category-name" />
      <div class="category-score {ccity.uof_subtotal === 0 ? 'zero' : ''}">
        <UoFDetail {ccity} />
      </div>
    </div>
  </div>
  <div class="tab">
    <input type="checkbox" id="rd3" name="rd" />
    <label
      class="tab-label {ccity.arr_subtotal === 0 ? 'zero' : ''}"
      for="rd3"
      style="background-color:{colorScale(ccity.arr_subtotal)}"
      ><div class="category-name {ccity.arr_subtotal === 0 ? 'zero' : ''}">
        Arrests
      </div>
      <div class="category-score {ccity.arr_subtotal === 0 ? 'zero' : ''}">
        {ccity.arr_subtotal.toFixed(0)}
      </div></label
    >
    <div class="tab-content">
      <div class="category-name" />
      <div class="category-score"><ArrestDetail {ccity} /></div>
    </div>
  </div>
  <div class="tab">
    <input type="checkbox" id="rd4" name="rd" />
    <label
      class="tab-label {ccity.cfs_subtotal === 0 ? 'zero' : ''}"
      for="rd4"
      style="background-color:{colorScale(ccity.cfs_subtotal)}"
      ><div class="category-name {ccity.cfs_subtotal === 0 ? 'zero' : ''}">
        Calls for Service/911
      </div>
      <div class="category-score {ccity.cfs_subtotal === 0 ? 'zero' : ''}">
        {ccity.cfs_subtotal.toFixed(0)}
      </div></label
    >
    <div class="tab-content">
      <div class="category-name" />
      <div class="category-score"><CFSDetail {ccity} /></div>
    </div>
  </div>
  <div class="tab">
    <input type="checkbox" id="rd6" name="rd" />
    <label
      class="tab-label {ccity.stops_subtotal === 0 ? 'zero' : ''}"
      for="rd6"
      style="background-color:{colorScale(ccity.stops_subtotal)}"
      ><div class="category-name {ccity.stops_subtotal === 0 ? 'zero' : ''}">
        Traffic & Pedestrian Stops
      </div>
      <div class="category-score {ccity.stops_subtotal === 0 ? 'zero' : ''}">
        {ccity.stops_subtotal.toFixed(0)}
      </div></label
    >
    <div class="tab-content">
      <div class="category-name" />
      <div class="category-score"><StopsDetail {ccity} /></div>
    </div>
  </div>
  <div class="tab">
    <input type="checkbox" id="rd9" name="rd" />
    <label
      class="tab-label {ccity.training_subtotal === 0 ? 'zero' : ''}"
      for="rd9"
      style="background-color:{colorScale(ccity.training_subtotal)}"
      ><div class="category-name {ccity.training_subtotal === 0 ? 'zero' : ''}">
        Training
      </div>
      <div class="category-score {ccity.training_subtotal === 0 ? 'zero' : ''}">
        {ccity.training_subtotal.toFixed(0)}
      </div></label
    >
    <div class="tab-content">
      <div class="category-name" />
      <div class="category-score"><TrainingDetail {ccity} /></div>
    </div>
  </div>
  <div class="tab">
    <input type="checkbox" id="rd5" name="rd" />
    <label
      class="tab-label {ccity.crime_subtotal === 0 ? 'zero' : ''}"
      for="rd5"
      style="background-color:{colorScale(ccity.crime_subtotal)}"
      ><div class="category-name {ccity.crime_subtotal === 0 ? 'zero' : ''}">
        Crime Reports
      </div>
      <div class="category-score {ccity.crime_subtotal === 0 ? 'zero' : ''}">
        {ccity.crime_subtotal.toFixed(0)}
      </div></label
    >
    <div class="tab-content">
      <div class="category-name" />
      <div class="category-score"><CrimeDetail {ccity} /></div>
    </div>
  </div>
  <div class="tab">
    <input type="checkbox" id="rd8" name="rd" />
    <label
      class="tab-label {ccity.contact_info_subtotal === 0 ? 'zero' : ''}"
      for="rd8"
      style="background-color:{colorScale(ccity.contact_info_subtotal)}"
      ><div
        class="category-name {ccity.contact_info_subtotal === 0 ? 'zero' : ''}"
      >
        Police Contact Info
      </div>
      <div
        class="category-score {ccity.contact_info_subtotal === 0 ? 'zero' : ''}"
      >
        {ccity.contact_info_subtotal.toFixed(0)}
      </div></label
    >
    <div class="tab-content">
      <div class="category-name" />
      <div class="category-score"><ContactInfoDetail {ccity} /></div>
    </div>
  </div>
  <div class="tab">
    <input type="checkbox" id="rd10" name="rd" />
    <label
      class="tab-label {ccity.policy_subtotal === 0 ? 'zero' : ''}"
      for="rd10"
      style="background-color:{colorScale(ccity.policy_subtotal)}"
      ><div class="category-name {ccity.policy_subtotal === 0 ? 'zero' : ''}">
        Policies
      </div>
      <div class="category-score {ccity.policy_subtotal === 0 ? 'zero' : ''}">
        {ccity.policy_subtotal.toFixed(0)}
      </div></label
    >
    <div class="tab-content">
      <div class="category-name" />
      <div class="category-score"><PolicyDetail {ccity} /></div>
    </div>
  </div>
</div>

<style>
  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  /* Accordion styles */
  .tabs {
    overflow: hidden;
  }
  .tab {
    width: 100%;
    color: fff;
    overflow: hidden;
    border-bottom: 1px solid #0a0319;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .tab:first-of-type {
    border-top: 1px solid #0a0319;
    margin-top: 10px;
    padding-top: 10px;
  }
  .tab:last-of-type {
    border-bottom: 1px solid #fff7f0;
    margin-bottom: 0px;
  }
  .tab-label {
    display: flex;
    justify-content: space-between;
    background: #2c3e50;
    font-weight: bold;
    cursor: pointer;
    height: 80px;
  }
  .tab-label:hover {
    background: #1a252f;
  }
  .tab-label.zero::after {
    content: "";
  }
  .tab-label::after {
    content: "❯";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.5s;
    padding: 0.3em;
    color: #fff;
    font-size: 40px;
  }
  .tab-label .category-name {
    background-color: #fff7f0;
    color: #0a0319;
  }
  .tab-content {
    max-height: 0;
    padding: 0;
    color: #2c3e50;
    transition: all 0.5s;
    display: flex;
  }
  .tab-content .category-name {
    width: 0%;
    padding: 0;
  }
  .tab-content .category-score {
    width: 100%;
    font-family: "Whyte Book";
    color: #0a0319;
    font-size: 15px;
    padding: 0.5em;
    line-height: 180%;
  }
  .category-name {
    /* background-color: #fff7f0; */
    color: #fff;
    padding: 1em;
    width: 30%;
    font-size: 20px;
  }
  .category-score {
    width: 57%;
    font-family: "Whyte Black";
    font-size: 45px;
    color: #fff;
    padding-top: 5px;
    padding-left: 10px;
  }
  .category-name.zero {
    color: #0a0319;
  }
  .category-score.zero {
    color: #d2bab4;
  }
  input:checked + .tab-label {
    background: #1a252f;
  }
  input:checked + .tab-label::after {
    transform: rotate(90deg);
  }
  input:checked ~ .tab-content {
    max-height: 400vh;
    background-color: #fff;
  }
  input:checked ~ .tab-content .category-score {
    padding: 1em;
  }
  label.zero {
    pointer-events: none;
  }
  @media all and (max-width: 767px) {
    input:checked ~ .tab-content {
      max-height: 1000vh;
    }
    .tab-label {
      display: flex;
    }
    .category-name {
      width: 100%;
      padding: 0.6em 1em;
    }
    .category-score {
      width: 50%;
      text-align: center;
    }
    .tab-content .category-name {
      width: 0%;
      padding: 0;
    }
    .tab-content .category-score {
      width: 100%;
      text-align: left;
    }
    .tab.ois .tab-label .category-name {
      padding: 0 1em;
    }
  }
</style>
