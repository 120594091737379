<script>
  import * as d3 from "d3";

  export let ccity;

  const emptyData = [];
  const nonEmptyData = [];
  const allCategories = [
    ccity.uof_subtotal,
    ccity.shooting_subtotal,
    ccity.arr_subtotal,
    ccity.cfs_subtotal,
    ccity.crime_subtotal,
    ccity.stops_subtotal,
    ccity.complaints_subtotal,
    ccity.contact_info_subtotal,
    ccity.training_subtotal,
    ccity.policy_subtotal,
  ];

  const categoryNames = [
    "use of force",
    "officer involved shootings",
    "arrests",
    "calls for service and 911",
    "crime and crime mapping",
    "traffic and pedestrian stops",
    "complaints",
    "police contact information",
    "training",
    "policies",
  ];

  for (let i = 0; i < allCategories.length; i++) {
    if (allCategories[i] == 0) {
      emptyData.push(categoryNames[i]);
    } else {
      nonEmptyData.push({
        category: categoryNames[i],
        score: allCategories[i],
      });
    }
  }

  const maxScore = d3.max(nonEmptyData, (d) => d.score);
  console.log("maxScore", maxScore);

  const highest = d3.filter(nonEmptyData, (d) => d.score == maxScore);

  const highestCats = highest.map((d) => d.category);

  for (let o = 0; o < emptyData.length; o++) {
    let last = emptyData[emptyData.length - 1];
  }
  for (let p = 0; p < nonEmptyData.length; p++) {
    let last = nonEmptyData[nonEmptyData.length - 1];
  }
  let last;
  let zeroString;
  let nonZeroString;

  if (emptyData.length > 2) {
    last = emptyData.pop();
    zeroString = emptyData + ",or " + last;
  } else if (emptyData.length == 2) {
    last = emptyData.pop();
    zeroString = emptyData + " or " + last;
  } else {
    zeroString = emptyData;
  }

  if (highestCats.length > 2) {
    last = highestCats.pop();
    nonZeroString = highestCats + ",and " + last;
  } else if (highestCats.length == 2) {
    last = highestCats.pop();
    nonZeroString = highestCats + " and " + last;
  } else {
    nonZeroString = highestCats;
  }

  let emptyString = zeroString.toString().replace(/,(?=[^\s])/g, ", ");
  let nonEmptyString = nonZeroString.toString().replace(/,(?=[^\s])/g, ", ");

  function quarterPlacement(value) {
    if (value <= 20) {
      return "bottom ten";
    } else if (value > 20 && value <= 37) {
      return "bottom half";
    } else if (value > 37 && value < 53) {
      return "top half";
    } else {
      return "top ten";
    }
  }
</script>

{#if ccity.cityScore > 25}
  <p>
    The {ccity.dept} is located in the state of {ccity.state}. The Police Data Transparency Index score for this location was {ccity.cityScore.toFixed(0)} out
    of 100 possible points. This score places {ccity.location} in the {quarterPlacement(
      ccity.cityScore
    )} of the 94 cities and counties reviewed. The {ccity.dept} scored highest for
    its {nonEmptyString} data, scoring {maxScore} out of a possible 100.
    {#if emptyString.length > 0}
      The {ccity.dept} does not provide any data or information on {emptyString}.
    {/if}
  </p>
{:else}
  <p>
    The {ccity.dept} is located in the state of {ccity.state}. The Police Data Transparency Index score for this location was {ccity.cityScore.toFixed(0)} out
    of 100 possible points. {ccity.dept} provides information on {nonEmptyString} but does not make available any data on {emptyString}.
  </p>
{/if}
<p>
  Access to data and information about policing is foundational to transforming
  our systems of public safety. The <a href="/#/">Police Data Transparency Index</a> documents
  the successes and gaps in police transparency in 94 cities and counties across
  the United States, which are home to 25 percent of the U.S. population. Use the
  index as a tool to identify specific datasets that should be made available in
  cities and counties. Follow the links in the index to connect with and directly
  examine the police data that is available in these places.
</p>
