<script>
  export let params;
</script>
{#if params.dataType == "useofforce"}
  <p>
    Vera examined available use of force information for the following
    transparency characteristics:
  </p>
  <ul>
    <li>
      <span class="strong">Fatalities included</span>: Whether the dataset
      includes information about uses of force that ended in death
    </li>
    <li>
      <span class="strong">Type of force used</span>: Whether the dataset
      described the kind of force used (for example, physical force, pepper
      spray, strike with an object, gunshot)
    </li>
    <li>
      <span class="strong">Civilian injury</span>: Whether information about
      injuries to involved community members is included
    </li>
    <li>
      <span class="strong">Officer injury</span>: Whether information about
      injuries to involved police officers is included
    </li>
    <li>
      <span class="strong">Justification</span>: Whether a reason for the use of
      force is provided
    </li>
    <li>
      <span class="strong">Civilian weapon</span>: Whether the data notes what
      kind of weapon, if any, the involved community member possessed or used
    </li>
    <li>
      <span class="strong">Officer weapon</span>: Whether the data notes the
      kind of weapon, if any, the involved police officer used
    </li>
    <li>
      <span class="strong">Bodycam footage</span>: Whether body-worn camera
      footage of the incident is publicly available
    </li>
    <li>
      <span class="strong">Arrest made</span>: Whether the police arrested the
      involved community member
    </li>
    <li>
      <span class="strong">URL</span>: An easily located and accessible website
      containing the use of force data
    </li>
    <li>
      <span class="strong">Downloadability</span>: Whether the information can
      be downloaded
    </li>
    <li>
      <span class="strong">Data format</span>: The extent to which the data is
      in a file format that can be independently analyzed
    </li>
    <li>
      <span class="strong">Aggregation level</span>: Ideally, data should be
      available describing each incident; a lower score is given for data that
      supplied only in aggregate summary over time (e.g. quarterly or annual
      totals)
    </li>
    <li>
      <span class="strong">Codebook or data dictionary</span>: Visitors to the
      website should be provided with a dictionary that explains what kinds of
      events are recorded in the data and the meanings of any abbreviations,
      codes, or specialized jargon in the data that may be unfamiliar.
    </li>
    <li>
      <span class="strong">Officer demographics</span>: Whether the data
      contains the race, ethnicity, or both of involved officers
    </li>
    <li>
      <span class="strong">Civilian demographics</span>: Whether the data
      contains the race, ethnicity, or both of involved community members
    </li>
    <li>
      <span class="strong">Location</span>: Whether the data provides the
      locations of incidents in the form of GPS or other geocode coordinates,
      street address, or nearest intersection
    </li>
    <li>
      <span class="strong">Embedded analytics</span>: Whether the website has
      online tools with which a visitor can create maps and/or make
      visualizations such as charts or graphs without downloading anything
    </li>
    <li>
      <span class="strong">Timespan</span>: How long a span of time the website
      provides data from: more than 2 years, 1-2 years, less than a year, or
      none (if data is taken down shortly after posting without being archived)
    </li>
    <li>
      <span class="strong">Update frequency</span>: Whether the data is updated
      biweekly, monthly, quarterly, biannually, annually, or less than annually
    </li>
    <li>
      <span class="strong">FAQ/User guide</span>: Whether the website contains
      information to help users understand how to use the website and orient
      themselves to its features
    </li>
  </ul>
{:else if params.dataType == "arrests"}
  <p>
    Vera examined available arrest information for the following transparency
    characteristics:
  </p>
  <ul>
    <li>
      <span class="strong">Charge</span>: Whether the data describes the alleged
      violation of the law leading to arrest
    </li>
    <li>
      <span class="strong">Use of force</span>: Whether information is provided
      about police use of force during arrests
    </li>
    <li>
      <span class="strong">URL</span>: An easily located and accessible website
      containing arrest data
    </li>
    <li>
      <span class="strong">Downloadability</span>: Whether the information can
      be downloaded
    </li>
    <li>
      <span class="strong">Data format</span>: The extent to which the data is
      in a file format that can be independently analyzed
    </li>
    <li>
      <span class="strong">Aggregation level</span>: Ideally, data should be
      available describing each incident; a lower score is given for data that
      supplied only in aggregate summary over time (e.g. quarterly or annual
      totals)
    </li>
    <li>
      <span class="strong">Codebook or data dictionary</span>: Visitors to the
      website should be provided with a dictionary that explains what kinds of
      events are recorded in the data and the meanings of any abbreviations,
      codes, or specialized jargon in the data that may be unfamiliar.
    </li>
    <li>
      <span class="strong">Officer demographics</span>: Whether the data
      contains the race, ethnicity, or both of involved officers
    </li>
    <li>
      <span class="strong">Civilian demographics</span>: Whether the data
      contains the race, ethnicity, or both of involved community members
    </li>
    <li>
      <span class="strong">Location</span>: Whether the data provides the
      locations of incidents in the form of GPS or other geocode coordinates,
      street address, or nearest intersection
    </li>
    <li>
      <span class="strong">Embedded analytics</span>: Whether the website has
      online tools with which a visitor can create maps and/or make
      visualizations such as charts or graphs without downloading anything
    </li>
    <li>
      <span class="strong">Timespan</span>: How long a span of time the website
      provides data from: more than 2 years, 1-2 years, less than a year, or
      none (if data is taken down shortly after posting without being archived)
    </li>
    <li>
      <span class="strong">Update frequency</span>: Whether the data is updated
      biweekly, monthly, quarterly, biannually, annually, or less than annually
    </li>
    <li>
      <span class="strong">FAQ/User guide</span>: Whether the website contains
      information to help users understand how to use the website and orient
      themselves to its features
    </li>
  </ul>
{:else if params.dataType == "callsforservice"}
  <p>
    Police departments should publicly report all calls for service received.
    Vera examined available calls for service/911 call information for the
    following transparency characteristics:
  </p>
  <ul>
    <li>
      <span class="strong">Officer initiated</span>: Whether the data
      distinguishes police responses that were initiated by an officer from
      those based on a call or request from the public
    </li>
    <li>
      <span class="strong">Dispatch</span>: Whether the dataset distinguishes
      calls that did and did not result in an officer being dispatched to the
      location
    </li>
    <li>
      <span class="strong">Timestamps</span>: Whether the dataset specifies the
      time at which a call was received and/or when it was dispatched
    </li>
    <li>
      <span class="strong">Outcome</span>: Whether information about the
      resolution of the call is included in the dataset
    </li>
    <li>
      <span class="strong">Call type</span>: Whether the dataset indicates the
      categories or types of calls received
    </li>
    <li>
      <span class="strong">Priority</span>: Whether information is given about
      the relative priority level assigned to the call by the staff of the
      emergency call center
    </li>
    <li>
      <span class="strong">URL</span>: An easily located and accessible website
      containing the data
    </li>
    <li>
      <span class="strong">Downloadability</span>: Whether the information can
      be downloaded
    </li>
    <li>
      <span class="strong">Data format</span>: The extent to which the data is
      in a file format that can be independently analyzed
    </li>
    <li>
      <span class="strong">Aggregation level</span>: Ideally, data should be
      available describing each incident; a lower score is given for data that
      supplied only in aggregate summary over time (e.g. quarterly or annual
      totals)
    </li>
    <li>
      <span class="strong">Codebook or data dictionary</span>: Visitors to the
      website should be provided with a dictionary that explains what kinds of
      events are recorded in the data and the meanings of any abbreviations,
      codes, or specialized jargon in the data that may be unfamiliar.
    </li>
    <li>
      <span class="strong">Location</span>: Whether the data provides the
      locations of incidents in the form of GPS or other geocode coordinates,
      street address, or nearest intersection
    </li>
    <li>
      <span class="strong">Embedded analytics</span>: Whether the website has
      online tools with which a visitor can create maps and/or make
      visualizations such as charts or graphs without downloading anything
    </li>
    <li>
      <span class="strong">Timespan</span>: How long a span of time the website
      provides data from: more than 2 years, 1-2 years, less than a year, or
      none (if data is taken down shortly after posting without being archived)
    </li>
    <li>
      <span class="strong">Update frequency</span>: Whether the data is updated
      biweekly, monthly, quarterly, biannually, annually, or less than annually
    </li>
    <li>
      <span class="strong">FAQ/User guide</span>: Whether the website contains
      information to help users understand how to use the website and orient
      themselves to its features
    </li>
  </ul> 
{:else if params.dataType == "complaints"}
  <p>
    Vera has therefore evaluated the availability of data from both internal
    investigative bodies such as internal affairs departments and from
    independent oversight bodies when measuring the transparency of police data
    about misconduct complaints. Vera examined the presence of the following
    characteristics of police complaint data:
  </p>
  <ul>
    <li>
      <span class="strong">Internal/external</span>: Whether information
      distinguishes complaints filed by members of the public from complaints
      filed by police officers
    </li>
    <li>
      <span class="strong">Allegation Category</span>: Whether the general topic
      area the complaint is provided (for example, use of force, or failure to
      take police action)
    </li>
    <li>
      <span class="strong">Specific Allegation</span>: Whether the detailed
      nature of the complaint is given (for example, an unjustified frisk, or an
      unjustified strip search)
    </li>
    <li>
      <span class="strong">Occurrence Timestamp</span>: Whether information
      indicates the date of the complaint event
    </li>
    <li>
      <span class="strong">Complaint Reported Timestamp</span>: Whether
      information indicates the date on which the complaint was filed.
    </li>
    <li>
      <span class="strong">Investigation Timestamp</span>: Whether information
      indicates the date on which investigation of the complaint was completed
    </li>
    <li>
      <span class="strong">Investigation Finding</span>: Whether information
      indicates the outcome of the investigation
    </li>
    <li>
      <span class="strong">Disciplinary Process</span>: Whether information is
      given about the disciplinary process stemming from the complaint (for
      example, whether summary discipline was given or a formal disciplinary
      hearing was held)
    </li>
    <li>
      <span class="strong">Final Disposition</span>: Whether the dataset
      indicates the outcome of the complaint at the end of the disciplinary
      investigation
    </li>
    <li>
      <span class="strong">Penalty Imposed</span>: Whether the dataset indicates
      the type of discipline imposed (for example, a week’s suspension,
      retraining, or a letter of reprimand)
    </li>
    <li>
      <span class="strong">Appeal</span>: Whether information is given about
      officer appeals filed to overturn disciplinary penalties
    </li>
    <li>
      <span class="strong">Appeal Result</span>: Whether the dataset provides
      the results of appeals to overturn disciplinary penalties (for example,
      whether a fired officer is ordered to be reinstated or the length of a
      suspension is altered on appeal)
    </li>
    <li>
      <span class="strong">Proportion of Additional Agencies with Data</span>:
      In a small number of places, Vera discovered multiple external police
      oversight agencies. Vera indexed the primary agency and added information
      about the number of additional ones reporting data.
    </li>
    <li>
      <span class="strong">URL</span>: An easily located and accessible website
      containing internal complaint data
    </li>
    <li>
      <span class="strong">Downloadability</span>: Whether the information can
      be downloaded
    </li>
    <li>
      <span class="strong">Data format</span>: The extent to which the data is
      in a file format that can be independently analyzed
    </li>
    <li>
      <span class="strong">Aggregation level</span>: Ideally, data should be
      available describing each incident; a lower score is given for data that
      supplied only in aggregate summary over time (e.g. quarterly or annual
      totals)
    </li>
    <li>
      <span class="strong">Codebook or data dictionary</span>: Visitors to the
      website should be provided with a dictionary that explains what kinds of
      events are recorded in the data and the meanings of any abbreviations,
      codes, or specialized jargon in the data that may be unfamiliar.
    </li>
    <li>
      <span class="strong">Officer demographics</span>: Whether the data
      contains the race, ethnicity, or both of involved officers
    </li>
    <li>
      <span class="strong">Civilian demographics</span>: Whether the data
      contains the race, ethnicity, or both of involved community members
    </li>
    <li>
      <span class="strong">Location</span>: Whether the data provides the
      locations of incidents in the form of GPS or other geocode coordinates,
      street address, or nearest intersection
    </li>
    <li>
      <span class="strong">Embedded analytics</span>: Whether the website has
      online tools with which a visitor can create maps and/or make
      visualizations such as charts or graphs without downloading anything
    </li>
    <li>
      <span class="strong">Timespan</span>: How long a span of time the website
      provides data from: more than 2 years, 1-2 years, less than a year, or
      none (if data is taken down shortly after posting without being archived)
    </li>
    <li>
      <span class="strong">Update frequency</span>: Whether the data is updated
      biweekly, monthly, quarterly, biannually, annually, or less than annually
    </li>
    <li>
      <span class="strong">FAQ/User guide</span>: Whether the website contains
      information to help users understand how to use the website and orient
      themselves to its features
    </li>
  </ul>
{:else if params.dataType == "crime"}
  <p>
    Police departments should publicly report information on all criminal
    incidents of which they have become aware. Vera examined available crime
    report information for the following transparency characteristics:
  </p>
  <ul>
    <li>
      <span class="strong">Charge</span>: Whether the dataset notes the nature
      of the crime reported
    </li>
    <li>
      <span class="strong">Accused demographics</span>: Whether race and/or
      ethnicity of the accused person is included in the data when it is known
      to the police
    </li>
    <li>
      <span class="strong">Cleared</span>: Whether the data distinguishes solved
      crimes from those that remain unsolved
    </li>
    <li>
      <span class="strong">URL</span>: An easily located and accessible website
      containing crime report data
    </li>
    <li>
      <span class="strong">Downloadability</span>: Whether the information can
      be downloaded
    </li>
    <li>
      <span class="strong"
        >Data format: The extent to which the data is in a file format that can
        be independently analyzed</span
      >
    </li>
    <li>
      <span class="strong">Aggregation level</span>: Ideally, data should be
      available describing each incident; a lower score is given for data that
      supplied only in aggregate summary over time (e.g. quarterly or annual
      totals)
    </li>
    <li>
      <span class="strong">Codebook or data dictionary</span>: Visitors to the
      website should be provided with a dictionary that explains what kinds of
      events are recorded in the data and the meanings of any abbreviations,
      codes, or specialized jargon in the data that may be unfamiliar.
    </li>
    <li>
      <span class="strong">Accused demographics</span>: Whether the data
      contains the race, ethnicity, or both of involved officers
    </li>
    <li>
      <span class="strong">Victim demographics</span>: Whether the data contains
      the race, ethnicity, or both of the community members who were victims of
      the crime
    </li>
    <li>
      <span class="strong">Location</span>: Whether the data provides the
      locations of incidents in the form of GPS or other geocode coordinates,
      street address, or nearest intersection
    </li>
    <li>
      <span class="strong">Embedded analytics</span>: Whether the website has
      online tools with which a visitor can create maps and/or make
      visualizations such as charts or graphs without downloading anything
    </li>
    <li>
      <span class="strong">Timespan</span>: How long a span of time the website
      provides data from: more than 2 years, 1-2 years, less than a year, or
      none (if data is taken down shortly after posting without being archived)
    </li>
    <li>
      <span class="strong">Update frequency</span>: Whether the data is updated
      biweekly, monthly, quarterly, biannually, annually, or less than annually
    </li>
    <li>
      <span class="strong">FAQ/User guide</span>: Whether the website contains
      information to help users understand how to use the website and orient
      themselves to its features
    </li>
  </ul>
{:else if params.dataType == "officerinvolvedshootings"}
  <p>
    Vera examined available officer involved shooting information for the
    following transparency characteristics:
  </p>
  <ul>
    <li>
      <span class="strong">Fatal/Nonfatal</span>: Whether the data identifies
      both lethal and nonlethal shootings
    </li>
    <li>
      <span class="strong">Justification</span>: Whether a reason for the use of
      deadly force is provided
    </li>
    <li>
      <span class="strong">Includes accidental discharge</span>: Whether
      information is given about incidents in which a firearm was accidentally
      discharged regardless of whether anyone was injured
    </li>
    <li>
      <span class="strong">Bodycam footage</span>: Whether body-worn camera
      footage of the incident is publicly available
    </li>
    <li>
      <span class="strong">URL</span>: An easily located and accessible website
      containing officer involved shooting data
    </li>
    <li>
      <span class="strong">Downloadability</span>: Whether the information can
      be downloaded
    </li>
    <li>
      <span class="strong">Data format</span>: The extent to which the data is
      in a file format that can be independently analyzed
    </li>
    <li>
      <span class="strong">Aggregation level</span>: Ideally, data should be
      available describing each incident; a lower score is given for data that
      supplied only in aggregate summary over time (e.g. quarterly or annual
      totals)
    </li>
    <li>
      <span class="strong">Codebook or data dictionary</span>: Visitors to the
      website should be provided with a dictionary that explains what kinds of
      events are recorded in the data and the meanings of any abbreviations,
      codes, or specialized jargon in the data that may be unfamiliar.
    </li>
    <li>
      <span class="strong">Officer demographics</span>: Whether the data
      contains the race, ethnicity, or both of involved officers
    </li>
    <li>
      <span class="strong">Civilian demographics</span>: Whether the data
      contains the race, ethnicity, or both of involved community members
    </li>
    <li>
      <span class="strong">Location</span>: Whether the data provides the
      locations of incidents in the form of GPS or other geocode coordinates,
      street address, or nearest intersection
    </li>
    <li>
      <span class="strong">Embedded analytics</span>: Whether the website has
      online tools with which a visitor can create maps and/or make
      visualizations such as charts or graphs without downloading anything
    </li>
    <li>
      <span class="strong">Timespan</span>: How long a span of time the website
      provides data from: more than 2 years, 1-2 years, less than a year, or
      none (if data is taken down shortly after posting without being archived)
    </li>
    <li>
      <span class="strong">Update frequency</span>: Whether the data is updated
      biweekly, monthly, quarterly, biannually, annually, or less than annually
    </li>
    <li>
      <span class="strong">FAQ/User guide</span>: Whether the website contains
      information to help users understand how to use the website and orient
      themselves to its features
    </li>
  </ul>
{:else if params.dataType == "policecontactinfo"}
  <p>
    Vera examined available police contact information and evaluated them for
    the following transparency characteristics:
  </p>
  <ul>
    <li>
      <span class="strong">URL</span>: An easily located and accessible website
      containing police contact data
    </li>
    <li>
      <span class="strong">Contact level</span>: Whether information is provided
      for individual officers, subunits within the police department, or solely
      for a main office
    </li>
    <li>
      <span class="strong">Contact type</span>: Whether information is provided
      allowing contact with the police by phone, email, street address, or fax
    </li>
  </ul>
{:else if params.dataType == "policies"}
  <p>
    Vera examined available police policy manuals and evaluated them for the
    following transparency characteristics:
  </p>
  <ul>
    <li>
      <span class="strong">URL</span>: An easily located and accessible website
      containing policies
    </li>
    <li>
      <span class="strong">Downloadability</span>: Whether the information can
      be downloaded
    </li>
  </ul> 
{:else if params.dataType == "training"}
  <p>
    Balancing these concerns with the potential quantity and complexity of
    training information, Vera examined available police training descriptions
    and materials and evaluated them for the following transparency
    characteristics:
  </p>
  <ul>
    <li>
      <span class="strong">URL:</span> An easily located and accessible website containing
      training data
    </li>
    <li>
      <span class="strong">Downloadability</span>: Whether the information can
      be downloaded
    </li>
  </ul>  
{:else if params.dataType == "trafficpedestrianstops"}
  <p>
    Police departments should publicly report information on all incidents in
    which officers stop drivers or pedestrians. Vera examined available traffic
    and pedestrian stop information for the following transparency
    characteristics:
  </p>
  <ul>
    <li>
      <span class="strong">Suspected crime</span>: Whether a suspected crime
      occurred during a stop
    </li>
    <li>
      <span class="strong">Stop type</span>: Whether the nature of the stop
      (traffic or pedestrian) is noted
    </li>
    <li>
      <span class="strong">Source</span>: Whether the dataset provides
      information about the source of the suspicion that lead police to stop the
      person
    </li>
    <li>
      <span class="strong">Frisk</span>: Whether data indicates if police
      conducted a frisk (a limited search consisting of patting down a person’s
      clothing) during the incident
    </li>
    <li>
      <span class="strong">Person search</span>: Whether the data indicates
      incidents in which the police searched a person (e.g. looked inside the
      person’s pockets, backpack, purse or other closed areas)
    </li>
    <li>
      <span class="strong">Car search</span>: Whether the dataset flags
      incidents in which police searched the interior of a car during a traffic
      stop
    </li>
    <li>
      <span class="strong">Search reason</span>: Whether information is provided
      about the reason for any search
    </li>
    <li>
      <span class="strong">Search result</span>: Whether the results of any
      searches are contained in the dataset
    </li>
    <li>
      <span class="strong">Use of force</span>: Whether the data notes stop
      incidents in which police used force
    </li>
    <li>
      <span class="strong">Use of force reason</span>: Whether the data
      indicates the reason for any force used during a stop
    </li>
    <li>
      <span class="strong">Removed from car</span>: Whether the dataset
      distinguishes car stops in which police commanded one or more people to
      exit the stopped vehicle
    </li>
    <li>
      <span class="strong">Outcome</span>: Whether the data indicates how the
      stop was resolved (e.g. arrest, ticket issued, no enforcement action,
      etc.)
    </li>
    <li>
      <span class="strong">URL</span>: An easily located and accessible website
      containing traffic and pedestrian stop data
    </li>
    <li>
      <span class="strong">Downloadability</span>: Whether the information can
      be downloaded
    </li>
    <li>
      <span class="strong">Data format</span>: The extent to which the data is
      in a file format that can be independently analyzed
    </li>
    <li>
      <span class="strong">Aggregation level</span>: Ideally, data should be
      available describing each incident; a lower score is given for data that
      supplied only in aggregate summary over time (e.g. quarterly or annual
      totals)
    </li>
    <li>
      <span class="strong">Codebook or data dictionary</span>: Visitors to the
      website should be provided with a dictionary that explains what kinds of
      events are recorded in the data and the meanings of any abbreviations,
      codes, or specialized jargon in the data that may be unfamiliar.
    </li>
    <li>
      <span class="strong">Officer demographics</span>: Whether the data
      contains the race, ethnicity, or both of involved officers
    </li>
    <li>
      <span class="strong">Civilian demographics</span>: Whether the data
      contains the race, ethnicity, or both of involved community members
    </li>
    <li>
      <span class="strong">Location</span>: Whether the data provides the
      locations of incidents in the form of GPS or other geocode coordinates,
      street address, or nearest intersection
    </li>
    <li>
      <span class="strong">Embedded analytics</span>: Whether the website has
      online tools with which a visitor can create maps and/or make
      visualizations such as charts or graphs without downloading anything
    </li>
    <li>
      <span class="strong">Timespan</span>: How long a span of time the website
      provides data from: more than 2 years, 1-2 years, less than a year, or
      none (if data is taken down shortly after posting without being archived)
    </li>
    <li>
      <span class="strong">Update frequency</span>: Whether the data is updated
      biweekly, monthly, quarterly, biannually, annually, or less than annually
    </li>
    <li>
      <span class="strong">FAQ/User guide</span>: Whether the website contains
      information to help users understand how to use the website and orient
      themselves to its features
    </li>
  </ul>  
{:else}
<p></p>
{/if}
