export default {
  nodes: [
    // { id: "City Score" },
    // { id: "Use of Force" },
    // { id: "Officer Involved Shootings" },
    // { id: "Arrest" },
    // { id: "CFS/911" },
    // { id: "Crime/Crime Mapping" },
    { id: "Traffic/Pedestrian stops" },
    // { id: "Complaints" },
    // { id: "Police contact info" },
    // { id: "Training" },
    // { id: "Policies" },
    { id: "Presence" },
    { id: "Basic Data Collection" },
    { id: "Dataset Specific Variables" },
    { id: "URL" },
    { id: "Downloadable" },
    { id: "Data Format" },
    { id: "Aggregate Level" },
    { id: "Codebook/Data Dictionary" },
    { id: "Officer Demographics" },
    { id: "Civilian Demographics" },
    { id: "Location" },
    { id: "Embedded Analytics" },
    { id: "Timespan" },
    { id: "Update Frequency" },
    { id: "FAQ/User Guide" },
    { id: "Suspected Crime" },
    { id: "Stop Type" },
    { id: "Source" },
    { id: "Frisk" },
    { id: "Person Search" },
    { id: "Car Search" },
    { id: "Search Reason" },
    { id: "Search Result" },
    { id: "Use of Force" },
    { id: "Use of Force Reason" },
    { id: "Removed from Car" },
    { id: "Outcome" },
  ],
  links: [
    // { source: "City Score", target: "Use of Force", value: 13.3 },
    // { source: "City Score", target: "Officer Involved Shootings", value: 13.3 },
    // { source: "City Score", target: "Arrest", value: 10.0 },
    // { source: "City Score", target: "CFS/911", value: 10.0 },
    // { source: "City Score", target: "Crime/Crime Mapping", value: 13.3 },
    // { source: "City Score", target: "Traffic/Pedestrian stops", value: 10.0 },
    // { source: "City Score", target: "Complaints", value: 13.3 },
    // { source: "City Score", target: "Police contact info", value: 6.6 },
    // { source: "City Score", target: "Training", value: 10.0 },
    // { source: "City Score", target: "Policies", value: 6.6 },
    { source: "Traffic/Pedestrian stops", target: "Presence", value: 2 },
    {
      source: "Traffic/Pedestrian stops",
      target: "Basic Data Collection",
      value: 4,
    },
    {
      source: "Traffic/Pedestrian stops",
      target: "Dataset Specific Variables",
      value: 4,
    },
    { source: "Presence", target: "URL", value: 1 },
    { source: "Presence", target: "Downloadable", value: 1 },
    { source: "Basic Data Collection", target: "Data Format", value: 0.3 },
    {
      source: "Basic Data Collection",
      target: "Aggregate Level",
      value: 0.4,
    },
    {
      source: "Basic Data Collection",
      target: "Codebook/Data Dictionary",
      value: 0.6,
    },
    {
      source: "Basic Data Collection",
      target: "Officer Demographics",
      value: 0.6,
    },
    {
      source: "Basic Data Collection",
      target: "Civilian Demographics",
      value: 0.6,
    },
    { source: "Basic Data Collection", target: "Location", value: 0.2 },
    {
      source: "Basic Data Collection",
      target: "Embedded Analytics",
      value: 0.3,
    },
    { source: "Basic Data Collection", target: "Timespan", value: 0.2 },
    {
      source: "Basic Data Collection",
      target: "Update Frequency",
      value: 0.4,
    },
    { source: "Basic Data Collection", target: "FAQ/User Guide", value: 0.4 },
    {
      source: "Dataset Specific Variables",
      target: "Suspected Crime",
      value: 0.52,
    },
    {
      source: "Dataset Specific Variables",
      target: "Stop Type",
      value: 0.12,
    },
    {
      source: "Dataset Specific Variables",
      target: "Source",
      value: 0.14,
    },
    {
      source: "Dataset Specific Variables",
      target: "Frisk",
      value: 0.28,
    },
    {
      source: "Dataset Specific Variables",
      target: "Person Search",
      value: 0.52,
    },
    {
      source: "Dataset Specific Variables",
      target: "Car Search",
      value: 0.4,
    },
    {
      source: "Dataset Specific Variables",
      target: "Search Reason",
      value: 0.28,
    },
    {
      source: "Dataset Specific Variables",
      target: "Search Result",
      value: 0.52,
    },
    {
      source: "Dataset Specific Variables",
      target: "Use of Force",
      value: 0.52,
    },
    {
      source: "Dataset Specific Variables",
      target: "Use of Force Reason",
      value: 0.14,
    },
    {
      source: "Dataset Specific Variables",
      target: "Removed from Car",
      value: 0.28,
    },
    {
      source: "Dataset Specific Variables",
      target: "Outcome",
      value: 0.28,
    },
  ],
};
