import * as d3 from "d3";

const colorScale = d3
  .scaleLinear()
  .domain([
    0, 1, 10, 20, 35, 40, 45, 50, 60, 70, 80, 90, 100
  ])
  .range(["rgba(255, 247, 240, 0)", "rgba(206, 192, 188, 1)", "rgba(190, 175, 187, 1)", "rgba(174, 159, 186, 1)", "rgba(158, 142, 184, 1)", "rgba(142, 126, 183, 1)", "rgba(126, 109, 182, 1)", "rgba(110, 93, 181, 1)", "rgba(94, 76, 179, 1)", "rgba(78, 60, 178, 1)", "rgba(62, 43, 177, 1)", "rgba(14, 0, 44, 1)"])
  .interpolate(d3.interpolateRgb);

export default colorScale;