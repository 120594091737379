export default {
  nodes: [
    // { id: "City Score" },
    // { id: "Use of Force" },
    // { id: "Officer Involved Shootings" },
    // { id: "Arrest" },
    // { id: "CFS/911" },
    // { id: "Crime/Crime Mapping" },
    // { id: "Traffic/Ped stops" },
    // { id: "Complaints" },
    { id: "Police contact info" },
    // { id: "Training" },
    // { id: "Policies" },
    { id: "Presence" },
    { id: "Dataset Specific Variables" },
    { id: "URL" },
    { id: "Contact Level" },
    { id: "Contact Type" },
  ],
  links: [
    // { source: "City Score", target: "Use of Force", value: 13.3 },
    // { source: "City Score", target: "Officer Involved Shootings", value: 13.3 },
    // { source: "City Score", target: "Arrest", value: 10.0 },
    // { source: "City Score", target: "CFS/911", value: 10.0 },
    // { source: "City Score", target: "Crime/Crime Mapping", value: 13.3 },
    // { source: "City Score", target: "Traffic/Ped stops", value: 10.0 },
    // { source: "City Score", target: "Complaints", value: 13.3 },
    // { source: "City Score", target: "Police contact info", value: 6.6 },
    // { source: "City Score", target: "Training", value: 10.0 },
    // { source: "City Score", target: "Policies", value: 6.6 },
    { source: "Police contact info", target: "Presence", value: 3.335 },
    {
      source: "Police contact info",
      target: "Dataset Specific Variables",
      value: 3.335,
    },
    { source: "Presence", target: "URL", value: 3.335 },
    {
      source: "Dataset Specific Variables",
      target: "Contact Level",
      value: 1.6675,
    },
    {
      source: "Dataset Specific Variables",
      target: "Contact Type",
      value: 1.6675,
    },
  ],
};
