<script>
  import { link } from "svelte-spa-router";
  import { onMount } from "svelte";
  onMount(() => window.scrollTo(0, 0));
  import { cityTitle } from "../../utils/store.js";

  cityTitle.set("Not Found");
</script>
<div class="pagewrap">
<h1>404 page not found</h1>
<p>We’re sorry, you’ve reached this page in error.</p>
<p>The page might have been moved, or the web address you are using may be incorrect.</p>
<p></p>
<a href="/" use:link>
  <h3>Home →</h3>
</a>
</div>
<style>
    h1 {
    font-family: "GT Sectra Bold";
    color: #0a0319;
    margin: 0 auto 1.2em;
    font-size: 35px;
    line-height: 130%;
    text-align: center;
  }
  p,a{
    text-align: center;
  }
  @media all and (max-width: 500px) {
    h1{
      margin-top: 0;
    }
    h6{
      margin: 0.75em 0;
    }
    .pagewrap {
      padding: 0 1em 0 1em;
    }
  }
</style>
