<script>
  import Menu from "./Menu.svelte";
  import { cityTitle } from "../utils/store.js";
  import { textSearchFilter } from "../utils/store.js";
  import SearchText from "./SearchText.svelte";

  let y;
</script>

<svelte:window bind:scrollY={y} />

<div id="wrap" class:sticky={y >= 100}>
  <header>
    <div id="name">
      <a href="https://www.vera.org" target="_blank">
        <img
          id="isologo"
          src="https://www.vera.org/dist/img/v2/vera-isologo.svg"
          alt="a red letter V, to represent Vera Institute of Justice"
        />
      </a>
      <h1>
        <a id="logo" href="https://www.vera.org" target="_blank">Vera</a>
        <a href="/">Police Data Transparency Index</a>
        {#if $cityTitle}
          <span class="cityname">· {$cityTitle}</span>
        {:else}
          <span></span>
        {/if}
      </h1>
    </div>

    <div id="browse">
      <div id="filter">
        <SearchText
          searchString={$textSearchFilter}
          label="Search"
          on:change={(e) => ($textSearchFilter = e.detail)}
          on:reset={() => textSearchFilter.reset()}
        />
      </div>
      <div id="menu">
        <Menu />
      </div>
    </div>
  </header>
</div>

<style>
  #logo, a#logo  {
   color: #ef4136 !important;
   margin-right: 20px;
  }
  #wrap {
    display: flex;
    padding: 1rem 0rem;
    margin: 0 auto;
    width: 100%;
    z-index: 500;
    background-color: #0e002c;
  }
  #isologo,
  .cityname {
    display: none;
  }
  .sticky {
    padding: 0rem !important;
    position: fixed;
    width: 100%;
    top: 0;
    animation: smoothScroll 1s forwards;
  }
  @keyframes smoothScroll {
	  0% {
		transform: translateY(-40px);
	  }
	  100% {
  		transform: translateY(0px);
  	}
  }
  .sticky #logo {
    display: none;
  }
  .sticky h1 {
    font-size: 24px;
    padding-top: 4px;
  }
  .sticky #isologo {
    display: inline;
    height: 20px;
    padding: 10px 0 0 20px;
    margin: 0;
  }
  .sticky .cityname {
    display: inline;
    color: #fff;
  }
  .sticky header {
    padding: 0.4em 0;
  }
  header {
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    padding: 1em 0;
    z-index: 500px;
  }
  #name {
    display: flex;
  }
  #name img {
    margin-right: 10px;
  }
  #browse {
    display: flex;
    margin-top: 11px;
  }
  .sticky #browse {
    margin-top: 2px;
    margin-right: 20px;
  }
  #menu {
    z-index: 10;
  }
  h1 {
    font-family: "GT Sectra Bold";
    font-size: 40px;
    font-weight: bold;
    margin: 0;
    padding-top: 5px;
    margin-left: 10px;
  }
  h1 a {
    text-decoration: none;
    color: #fff !important;
  }

  @media all and (min-width: 925px) and (max-width: 1340px) {
    header {
      padding: 1em 2em;
    }
  }
  @media all and (min-width: 800px) and (max-width: 924px) {
    header {
      padding: 1em 1em;
    }
  }
  @media all and (min-width: 976px) and (max-width: 1180px) {
    h1 {
      font-size: 32px;
    }
    #logo {
      width: 150px;
    }
  }
  @media all and (max-width: 975px) {
    #logo {
      width: 140px;
    }
    h1 {
      font-size: 28px;
    }
  }
  @media only screen and (max-width: 830px) {
    header {
      flex-direction: column;
      padding: 0em 0 0em 1em;
    }
    .sticky header {
      padding: 0.4em 1em;
    }
  }
  @media only screen and (max-width: 600px) {
    #wrap {
      display: block;
      padding: 0.75rem 0rem;
    }
    h1 {
      font-size: 25px;
    }
  }
  @media only screen and (max-width: 545px) {
    h1 {
      margin: 0;
    }
    #logo, a#logo  {
     margin-right: 0px;
    }
    .sticky #isologo {
      margin-right:10px;
      padding-left: 5px;
    }
    h1 {
      font-size: 21px;
    }
    .sticky h1 {
      font-size: 20px;
      padding-top: 4px;
    }
  }
</style>
