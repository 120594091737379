<script>
	import Router from "svelte-spa-router";
	import routes from "./utils/routes";
	import Header from "./components/Header.svelte";
	import Footer from "./components/Footer.svelte";
	import loadData from "./utils/loadData";
	import CityNav from "./components/CategoryNav.svelte";
	import CategoryNav from "./components/CityNav.svelte";
	import { MetaTags } from "svelte-meta-tags";

	export let filtered;

	let width;

	let data;

	$: updateData();

	async function getData() {
		let data = await loadData();
		return data[0];
	}

	async function updateData() {
		data = await getData();
	}
	let search = "";

	function searchFor(search) {
		return data.filter((item) => {
			return Object.values(item).some((value) => {
				if (typeof value === "number") {
					value = String(value);
				}
				if (typeof value === "object") {
					value = formatDate(value);
				}

				return value.toLowerCase().includes(search.toLowerCase());
			});
		});
	}

	$: filtered = search ? searchFor(search) : data;
</script>

<MetaTags
	title="Police Data Transparency Index"
	description="Community demands for police accountability require information about what police do. However, police data transparency is low nationwide. Use Vera’s Police Data Transparency Index to see how locations across the United States are performing in their release of data and to access the data that is available."
	canonical="https://policetransparency.vera.org/"
	openGraph={{
		url: "https://policetransparency.vera.org/",
		title: "Police Data Transparency Index",
		description:
			"Community demands for police accountability require information about what police do. However, police data transparency is low nationwide. Use Vera’s Police Data Transparency Index to see how locations across the United States are performing in their release of data and to access the data that is available.",
		images: [
			{
				url: "https://policetransparency.vera.org/img/pti.jpg",
				width: 800,
				height: 600,
				alt: "Police Data Transparency Index | Vera Institute of Justice",
			},
		],
		site_name: "Police Data Transparency Index | Vera Institute of Justice",
	}}
	twitter={{
		handle: "@verainstitute",
		site: "@verainstitute",
		cardType: "summary_large_image",
		title: "Police Data Transparency Index | Vera Institute of Justice",
		description:
			"Community demands for police accountability require information about what police do. However, police data transparency is low nationwide. Use Vera’s Police Data Transparency Index to see how locations across the United States are performing in their release of data and to access the data that is available.",
		image: "https://policetransparency.vera.org/img/pti.jpg",
		imageAlt: "Police Data Transparency Index | Vera Institute of Justice",
	}}
	facebook={{
		appId: "",
	}}
/>

<div id="header">
	<Header bind:value={search} />
</div>
<div id="nav">
	<CategoryNav />
</div>
<div id="nav">
	<CityNav />
</div>
<div class="app-wrapper" bind:clientWidth={width}>
	<Router {routes} />
</div>
<div id="footer">
	<Footer />
</div>

<style>
	.app-wrapper {
		max-width: 1300px;
		margin: 0px auto 5rem;
		padding: 2rem 5rem;
		overflow-x: hidden;
	}
	#header {
		width: 100%;
		z-index: 10;
	}
	:global(a) {
		text-decoration: none;
		color: #551a8b;
	}
	:global(body) {
		margin: 0;
		padding: 0;
		background-color: #fff7f0;
	}
	@media only screen and (max-width: 1360px) {
		.app-wrapper {
			margin: 0px auto 5rem;
			padding: 2rem 3rem;
		}
	}
	@media only screen and (max-width: 1150px) {
		.app-wrapper {
			margin: 0px auto 5rem;
			padding: 2rem 1.5rem;
		}
	}
	@media all and (max-width: 767px) {
		.app-wrapper {
			margin: 0px auto 5rem;
			padding: 1.5rem 1.5rem !important;
		}
	}
	@media all and (max-width: 500px) {
		.app-wrapper {
			margin: 0px auto 1rem;
			padding: 1rem 0 !important;
		}
	}
</style>
