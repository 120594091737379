export default {
  nodes: [
    { id: "Policies" },
    { id: "Presence" },
    { id: "URL" },
    { id: "Downloadable" },
  ],
  links: [
    { source: "Policies", target: "Presence", value: 6.67 },
    { source: "Presence", target: "URL", value: 3.335 },
    { source: "Presence", target: "Downloadable", value: 3.335 },
  ],
};
