<script>
  import { onMount } from "svelte";
  import loadData from "../../../utils/loadData";
  import * as d3 from "d3";
  import { LayerCake, Svg } from "layercake";
  import Sankey from "../../sankey/Sankey.svelte";
  import data from "../../../data/crime";
  import CategoryColumn from "../../city-column/CategoryColumn.svelte";
  import CategoryNav from "../../CategoryNav.svelte";
  import { cityTitle } from "../../../utils/store.js";

  cityTitle.set("Crime Reports");

  let cities = [],
    ccategory = [],
    categoryData = [];

  onMount(async () => {
    window.scrollTo(0, 0);
    let data = await loadData();
    cities = data[0];

    cities.sort((a, b) => d3.ascending(b.cityScore, a.cityScore));
  });
</script>

<div class="categorypage">
  <h2>Crime Reports</h2>
  <p>
    Police learn about crimes when the public reports a crime or a crime is discovered by the police in the course of their duties. Crimes are usually categorized as violent crimes, property crimes, or offenses against public order. Crimes may be reported to the police or discovered as they occur or at a later time, and may be “cleared” (i.e., solved) or remain unresolved.
  </p>
  <div class="chart-wrap">
    <CategoryColumn categoryVar={"crime_subtotal"} />
  </div>
  <p class="anno">Of the 94 jurisdictions in the index, 14 do not provide information on crime reports and received a score of zero.</p>
  <p>
    Cities and counties should publicly report information on all criminal incidents of which the police have become aware. Vera examined available crime report information for the following transparency characteristics:
  </p>
  <ul>
    <li>
      <span class="strong">Charge</span>: Whether the dataset notes the nature of the crime reported.
    </li>
    <li>
      <span class="strong">Accused demographics</span>: Whether race and/or ethnicity of the accused person is included in the data when it is known to the police.
    </li>
    <li>
      <span class="strong">Cleared</span>: Whether the data distinguishes solved crimes from those that remain unsolved.
    </li>
    <li>
      <span class="strong">URL</span>: Whether the city or county makes data available via an easily located and accessible website.
    </li>
    <li>
      <span class="strong">Downloadability</span>: Whether the information can be downloaded.
    </li>
    <li>
      <span class="strong">Data format</span>: The extent to which the data is in a file format that can be independently analyzed.
    </li>
    <li>
      <span class="strong">Aggregation level</span>: Ideally, data should be available describing each incident; Vera gave a lower score for data that is supplied only in aggregate summary over time (e.g., quarterly or annual totals).
    </li>
    <li>
      <span class="strong">Codebook or data dictionary</span>: Visitors to the website should be provided with a dictionary that explains what kinds of events are recorded in the data and the meanings of any abbreviations, codes, or specialized jargon in the data.
    </li>
    <li>
      <span class="strong">Officer demographics</span>: Whether the data contains the race, ethnicity, or both of involved officers.
    </li>
    <li>
      <span class="strong">Victim demographics</span>: Whether the data contains the race, ethnicity, or both of the people who were victims of the crime.
    </li>
    <li>
      <span class="strong">Location</span>: Whether the data provides the locations of incidents in the form of GPS or other geocode coordinates, street address, or nearest intersection.
    </li>
    <li>
      <span class="strong">Embedded analytics</span>: Whether the website has online tools with which a visitor can create maps and/or make visualizations such as charts or graphs without downloading data.
    </li>
    <li>
      <span class="strong">Timespan</span>: The length of time the website provides data from: more than two years, one to two years, less than a year, or none (if data is taken down shortly after posting without being archived).
    </li>
    <li>
      <span class="strong">Update frequency</span>: Whether the data is updated biweekly, monthly, quarterly, biannually, annually, or less than annually.
    </li>
    <li>
      <span class="strong">FAQ/User guide</span>: Whether the website contains information to help users understand how to use the website and orient themselves to its features.
    </li>
  </ul>
  <div class="chart-container">
    <LayerCake {data}>
      <Svg>
        <Sankey colorNodes={(d) => "#2E1D97"} colorLinks={(d) => "#97DFD1"} />
      </Svg>
    </LayerCake>
  </div>
</div>

<style>
  .categorypage {
    margin-left: 120px;
  }
  h2 {
    font-family: "Whyte Black";
    font-size: 50px;
    font-weight: bold;
    margin: 0px 0 50px 0;
    padding-top: 5px;
  }
  span.strong {
    font-family: "Whyte Bold";
  }

  .chart-wrap {
    margin: 50px 0 20px;
  }
  .chart-container {
    display: flex;
    width: 100%;
    height: 500px;
    margin: 50px 0;
  }
  .anno{
    font-style: italic;
    color: #555;
    text-align: right;
    width: 60%;
    margin-left: 30%;
    margin-right: 10%;
    margin-top: 0;
    font-size: 14px;
    margin-bottom: 50px;
  }
  @media all and (max-width: 767px) {
    .categorypage {
      margin-left: 0px;
    }
    h2 {
      margin-top: 0px;
      margin-bottom: 30px;
      font-size: 35px;
      line-height: 130%;
    }
    .chart-wrap {
      margin: 30px 0 10px;
    }
    .anno{
      margin-bottom: 30px;
    }
  }
  @media all and (max-width: 500px) {
    .categorypage {
      padding: 0 1em 0 1em;
    }
    h2{
      bottom:15px;
    }
  }
</style>