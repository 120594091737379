export default {
  nodes: [
    { id: "Complaints" },
    { id: "Internal Complaints" },
    { id: "External Complaints" },
    { id: "Presence" },
    { id: "Basic Data Collection" },
    { id: "Dataset Specific Variables" },
    { id: "URL" },
    { id: "Downloadable" },
    { id: "Internal/External" },
    { id: "Allegation Category" },
    { id: "Specific Allegation" },
    { id: "Occurrence Timestamp" },
    { id: "Complaint Reported Timestamp" },
    { id: "Investigation Timestamp" },
    { id: "Investigation Finding" },
    { id: "Disciplinary Process" },
    { id: "Final Disposition" },
    { id: "Penalty Imposed" },
    { id: "Appeal" },
    { id: "Appeal Result" },
    { id: "Data Format" },
    { id: "Aggregate Level" },
    { id: "Codebook/Data Dictionary" },
    { id: "Officer Demographics" },
    { id: "Civilian Demographics" },
    { id: "Location" },
    { id: "Embedded Analytics" },
    { id: "Timespan" },
    { id: "Update Frequency" },
    { id: "FAQ/User Guide" },
  ],
  links: [
    { source: "Complaints", target: "Internal Complaints", value: 9.9975 },
    { source: "Complaints", target: "External Complaints", value: 3.3325 },
    { source: "Internal Complaints", target: "Presence", value: 1.9995 },
    { source: "External Complaints", target: "Presence", value: .6665 },

    { source: "Internal Complaints", target: "Basic Data Collection", value: 2.99925 },
    { source: "External Complaints", target: "Basic Data Collection", value: .99975 },

    {
      source: "Internal Complaints",
      target: "Dataset Specific Variables",
      value: 4.99875,
    },
    {
      source: "External Complaints",
      target: "Dataset Specific Variables",
      value: 1.66625,
    },
    { source: "Presence", target: "URL", value: 1.333 },
    { source: "Presence", target: "Downloadable", value: 1.333 },
    {
      source: "Dataset Specific Variables",
      target: "Internal/External",
      value: 0.5332,
    },
    {
      source: "Dataset Specific Variables",
      target: "Allegation Category",
      value: 0.33325,
    },
    {
      source: "Dataset Specific Variables",
      target: "Specific Allegation",
      value: 0.86645,
    },
    {
      source: "Dataset Specific Variables",
      target: "Occurrence Timestamp",
      value: 0.7998,
    },
    {
      source: "Dataset Specific Variables",
      target: "Complaint Reported Timestamp",
      value: 0.33325,
    },
    {
      source: "Dataset Specific Variables",
      target: "Investigation Timestamp",
      value: 0.33325,
    },
    {
      source: "Dataset Specific Variables",
      target: "Investigation Finding",
      value: 0.7998,
    },
    {
      source: "Dataset Specific Variables",
      target: "Disciplinary Process",
      value: 0.33325,
    },
    {
      source: "Dataset Specific Variables",
      target: "Final Disposition",
      value: 0.6665,
    },
    {
      source: "Dataset Specific Variables",
      target: "Penalty Imposed",
      value: 0.6665,
    },
    {
      source: "Dataset Specific Variables",
      target: "Appeal",
      value: 0.33325,
    },
    {
      source: "Dataset Specific Variables",
      target: "Appeal Result",
      value: 0.6665,
    },
    { source: "Basic Data Collection", target: "Data Format", value: 0.299925 },
    {
      source: "Basic Data Collection",
      target: "Aggregate Level",
      value: .3999,
    },

    {
      source: "Basic Data Collection",
      target: "Codebook/Data Dictionary",
      value: .59985,
    },
    {
      source: "Basic Data Collection",
      target: "Officer Demographics",
      value: .59985,
    },
    {
      source: "Basic Data Collection",
      target: "Civilian Demographics",
      value: .59985,
    },
    { source: "Basic Data Collection", target: "Location", value: 0.199995 },
    {
      source: "Basic Data Collection",
      target: "Embedded Analytics",
      value: 0.299925,
    },
    { source: "Basic Data Collection", target: "Timespan", value: 0.199995 },
    {
      source: "Basic Data Collection",
      target: "Update Frequency",
      value: 0.3999,
    },
    { source: "Basic Data Collection", target: "FAQ/User Guide", value: 0.3999 },
  ],
};
