<script>
  import { onMount } from "svelte";
  import loadData from "../../utils/loadData";
  import colorScale from "../../utils/colorScale";
  export let ccity;
  import * as d3 from "d3";
  import Btn from "../Btn.svelte";

  let cityNames = [];
  let descriptorNames = [];
  let heatMapData = [];
  let descriptorData = [];
  let currentIndex, currentIndexD;

  onMount(async (d) => {
    let dataset = await loadData();
    let cities = dataset[1];
    cities.sort((a, b) => d3.descending(b.city, a.city));
    let descriptors = dataset[2];
    descriptors.sort((a, b) => d3.descending(b.city, a.city));

    cityNames = cities.map((a) => a.city);
    currentIndex = cityNames.indexOf(ccity.city);
    heatMapData = [cities[currentIndex]][0];

    descriptorNames = descriptors.map((a) => a.city);
    currentIndexD = descriptorNames.indexOf(ccity.city);
    descriptorData = [descriptors[currentIndexD]][0];
  });
</script>

<div id="dept-data">
  Access the {ccity.dept}'s
  <a class="records" target="_blank" href={ccity.stops_url}
    >traffic and pedestrian stops data</a
  >
</div>
<p class="definitions">
  <a href="/#/trafficpedestrianstops">Definitions of each variable →</a>
</p>

<div id="wrap">
  <div id="vars-col-1">
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_download)}"
        >
        <div class="scorenumber score{heatMapData.stops_download}">{heatMapData.stops_download}</div>
        </div>
      Downloadable
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_url)}"
        >
        <div class="scorenumber score{heatMapData.stops_url}">{heatMapData.stops_url}</div>
        </div>
      URL
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_agg_type)}"
        >
        <div class="scorenumber score{heatMapData.stops_agg_type}">{heatMapData.stops_agg_type}</div>
        </div>
      Aggregation level
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_civ_demo)}"
        >
        <div class="scorenumber score{heatMapData.stops_civ_demo}">{heatMapData.stops_civ_demo}</div>
        </div>
      Civilian demographics
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_codebook)}"
        >
        <div class="scorenumber score{heatMapData.stops_codebook}">{heatMapData.stops_codebook}</div>
        </div>
      Codebook or data dictionary
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_data_type)}"
        >
        <div class="scorenumber score{heatMapData.stops_data_type}">{heatMapData.stops_data_type}</div>
        </div>
      Data format
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_embed)}"
        >
        <div class="scorenumber score{heatMapData.stops_embed}">{heatMapData.stops_embed}</div>
        </div>
      Embedded analytics
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_FAQ)}"
        >
        <div class="scorenumber score{heatMapData.stops_FAQ}">{heatMapData.stops_FAQ}</div>
        </div>
      FAQ/User guide
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_location)}"
        >
        <div class="scorenumber score{heatMapData.stops_location}">{heatMapData.stops_location}</div>
        </div>
      Location
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_off_demo)}"
        >
        <div class="scorenumber score{heatMapData.stops_off_demo}">{heatMapData.stops_off_demo}</div>
        </div>
      Officer demographics
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_coverage)}"
        >
        <div class="scorenumber score{heatMapData.stops_coverage}">{heatMapData.stops_coverage}</div>
        </div>
      Timespan
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_freq)}"
        >
        <div class="scorenumber score{heatMapData.stops_freq}">{heatMapData.stops_freq}</div>
        </div>
      Update frequency
    </div>
  </div>
  <div id="vars-col-2">
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(
            heatMapData.stops_vehicle_search
          )}"
        >
        <div class="scorenumber score{heatMapData.stops_vehicle_search}">{heatMapData.stops_vehicle_search}</div>
        </div>
      Car search
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_frisk)}"
        >
        <div class="scorenumber score{heatMapData.stops_frisk}">{heatMapData.stops_frisk}</div>
        </div>
      Frisk
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_outcome)}"
        >
        <div class="scorenumber score{heatMapData.stops_outcome}">{heatMapData.stops_outcome}</div>
        </div>
      Outcome
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_person_search)}"
        >
        <div class="scorenumber score{heatMapData.stops_person_search}">{heatMapData.stops_person_search}</div>
        </div>
      Person search
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_removed)}"
        >
        <div class="scorenumber score{heatMapData.stops_removed}">{heatMapData.stops_removed}</div>
        </div>
      Removed from car
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_search_reason)}"
        >
        <div class="scorenumber score{heatMapData.stops_search_reason}">{heatMapData.stops_search_reason}</div>
        </div>
      Search reason
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_search_result)}"
        >
        <div class="scorenumber score{heatMapData.stops_search_result}">{heatMapData.stops_search_result}</div>
        </div>
      Search result
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_source)}"
        >
        <div class="scorenumber score{heatMapData.stops_source}">{heatMapData.stops_source}</div>
        </div>
      Source
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_type)}"
        >
        <div class="scorenumber score{heatMapData.stops_type}">{heatMapData.stops_type}</div>
        </div>
      Stop type
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_crime)}"
        >
        <div class="scorenumber score{heatMapData.stops_crime}">{heatMapData.stops_crime}</div>
        </div>
      Suspected crime
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_uof)}"
        >
        <div class="scorenumber score{heatMapData.stops_uof}">{heatMapData.stops_uof}</div>
        </div>
      Use of force
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.stops_uof_reason)}"
        >
        <div class="scorenumber score{heatMapData.stops_uof_reason}">{heatMapData.stops_uof_reason}</div>
        </div>
      Use of force reason
    </div>
  </div>
</div>

<style>
  #wrap {
    display: flex;
    padding: 0 2% 2% 2%;
  }
  #vars-col-1,
  #vars-col-2 {
    width: 40%;
    padding-right: 5%;
  }
  .variable {
    display: flex;
    margin-bottom: 8px;
  }
  .var-score {
    width: 50px;
    height: 25px;
    margin-right: 20px;
    border: 1px solid #0a0319;
  }
  .scorenumber{
    text-align: center;
    color:#fff;
    visibility: hidden;
  }
  .score0{
    color:#000;
  }
  .variable:hover .scorenumber,
  .var-score:hover .scorenumber{
    visibility: visible;
  }
  #dept-data {
    padding: 2%;
    font-size: 18px;
    border-bottom: 1px solid #000;
    margin-bottom: 1.5em;
  }
  .definitions {
    padding-left: 1em;
  }
  .strong {
    font-family: "Whyte Bold";
    padding: 0.5em 0;
  }
  @media all and (max-width: 650px) {
    #wrap {
      flex-direction: column;
    }
    #vars-col-1,
    #vars-col-2,
    #dept-data {
      width: 90%;
      padding: 5% 5% 0 5%;
      margin: 0;
      background-color: #fff;
    }
    #dept-data {
      font-size: 16px;
    }
  }
</style>
