<script>
  import loadData from "../../utils/loadData";
  import * as d3 from "d3";
  export let ccity;
  let diffs = [];
  let diffsbyCat = [];
  let diffsD = [];
  let diffsE = [];
  let diffTotalScore = [];
  let diffdates;

  const reasons = [
    "updates to the coding of variables",
    "discovery of a link to a relevant statewide dataset on a local government website",
    "discovery of a report containing information on multiple data types",
    "removal of a dataset not linked on a local government website",
    "sporadic changes in availability of the dataset over time",
    "recategorization of a dataset to a different data type",
    "discovery of a new dataset",
  ];

  const datasets = [
    "arr",
    "cfs",
    "crime",
    "ecomplaints",
    "icomplaints",
    "police",
    "policy",
    "shooting",
    "stops",
    "Tcomplaints",
    "TotalPoints100",
    "training",
    "uof",
  ];
  const categories = [
    "Arrests",
    "Calls for Service/911",
    "Crime Reports",
    "External Complaints",
    "Internal Complaints",
    "Police Contact Info",
    "Policies",
    "Instances of Officers Shooting Firearms",
    "Traffic & Pedestrian Stops",
    "Complaints About Police Misconduct",
    "Total Score",
    "Training",
    "Use of Force",
  ];

  $: updateData();

  const formatDate = d3.timeFormat("%B %d, %Y");

  async function getDiffs() {
    let data = await loadData();
    diffs = d3.filter(
      data[3],
      (d) =>
        d.city == ccity.city &&
        d.Difference !== 0 &&
        d.Dataset !== "TotalPoints100" &&
        d.Dataset !== "icomplaints" &&
        d.Dataset !== "ecomplaints",
    );
    diffs.sort((a, b) => a.Date - b.Date);
    return diffs;
  }

  async function getDiffsbyCat() {
    let data = await loadData();
    diffsE = d3.filter(
      data[3],
      (d) =>
        d.city == ccity.city &&
        d.Difference !== 0 &&
        d.Dataset !== "TotalPoints100" &&
        d.Dataset !== "icomplaints",
    );
    diffsE.sort((a, b) => a.Date - b.Date);
    diffsbyCat = d3.flatGroup(diffsE, (d) => d.Updates);
    return diffsbyCat;
  }

  async function getDiffTotalScore() {
    let data = await loadData();
    diffTotalScore = d3.filter(
      data[3],
      (d) =>
        d.city == ccity.city &&
        d.Dataset == "TotalPoints100" &&
        d.Dataset !== "icomplaints" &&
        d.Original.toFixed(0) !== d.Validation.toFixed(0),
    );

    let allDatesTS = d3.group(diffTotalScore, (d) => d.UpdateDate);
    let mult_datesTS = Array.from(allDatesTS.keys());
    return diffTotalScore;
  }

  async function getDiffDates() {
    let data = await loadData();
    diffsD = d3.filter(
      data[3],
      (d) => d.city == ccity.city && d.Difference !== 0,
    );
    diffsD.sort((a, b) => a.Date - b.Date);
    let allDates = d3.group(diffsD, (d) => d.UpdateDate);
    let mult_dates = Array.from(allDates.keys());

    diffdates = mult_dates;
    return diffdates;
  }

  async function updateData() {
    diffs = await getDiffs();
    diffsbyCat = await getDiffsbyCat();
    diffdates = await getDiffDates();
    diffTotalScore = await getDiffTotalScore();
  }
  let diff;
</script>

{#if diffdates !== undefined}
  {#each diffdates as diffdate, j}
    <div>
      Update: {formatDate(diffdate)}
      {#if diffTotalScore.length > 0}
        {#if diffTotalScore[j] !== undefined}
          <p>
            The total city score was updated from {diffTotalScore[
              j
            ].Original.toFixed(0)} to {diffTotalScore[j].Validation.toFixed(0)}.
          </p>
        {/if}
      {:else}
        <p>The total city score did not change.</p>
      {/if}

      {#if j == 1}
        {#each diffsbyCat as diffCat}
          Due to {reasons[diffCat[0]]}, the
          {#each diffCat[1] as ds, i}
            {#if diffCat[1].length == 1}
              score for {categories[datasets.indexOf(ds.Dataset)]}
            {:else if diffCat[1].length == 2}
              {#if i == 0}
                scores for {categories[datasets.indexOf(ds.Dataset)]}
              {:else if i == 1}
                &nbsp;and {categories[datasets.indexOf(ds.Dataset)]}
              {/if}
            {:else if diffCat[1].length > 2}
              {#if i == 0}
                scores for {categories[datasets.indexOf(ds.Dataset)]}
              {:else if i == diffCat[1].length - 1}
                , and {categories[datasets.indexOf(ds.Dataset)]}
              {:else}
                , {categories[datasets.indexOf(ds.Dataset)]}
              {/if}
            {/if}
          {/each}
          were updated.&nbsp;
        {/each}
      {/if}
    </div>
    <br />
  {/each}
{/if}
