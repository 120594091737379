<script>
  import { location, push } from "svelte-spa-router";
  import { onMount } from "svelte";
  import colorScale from "../utils/colorScale";
  import loadData from "../utils/loadData";
  
  import * as d3 from "d3";
  let cities = [];
  onMount(async () => {
    window.scrollTo(0, 0);
    let data = await loadData();
    cities = d3.filter(data[0], (d) => d.location !== "NA");
    cities.sort((a, b) => d3.ascending(b.cityScore, a.cityScore));
  });

  let currentCity = $location.slice(6).replace(/%20/g, " ")

</script>

{#if $location.includes("changethisback")}
  <div id="city-tiles">
    {#each cities as city}
      <a href="/#/city/{city.location}"><div  class="tile {city.location == currentCity ? 'active' : ''}"  style="background-color:{city.location == currentCity ? colorScale(city.cityScore) : '##fff7f0'}">{city.location == currentCity ? city.cityScore.toFixed(0) : city.location}</div></a>
      {/each}
  </div>
{/if}

<style>
  a.active .tile {
    background-color: #d2bab4;
    color: #fff7f0;
  }
  .tile.active{
    font-size: 35px !important;
    font-family: "Whyte Black" !important;
  }
  #city-tiles {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 200;
    left: 0;
    height: calc(100% - 427px);
    overflow-y: scroll;
    z-index: 100;
  }
  .tile {
    color: #d2bab4;
    padding: 10px;
    font-size: 15px;
    width: 100px;
    height: 100px;
    background-color: #fff7f0;
    border-right: 1px solid #d2bab4;
    border-bottom: 1px solid #d2bab4;
  }
  .tile a:hover {
    text-decoration: none !important;
    color: #fff !important;
  }
  .tile.active {
    color: #fff;
    padding: 35px;
    font-size: 15px;
    width: 50px;
    height: 50px;
    background-color: #d2bab4;
  }
  @media all and (max-width: 767px) {
    #city-tiles {
      display: none;
    }
  }
</style>
