<script>
  import { onMount } from "svelte";
  onMount(() => window.scrollTo(0, 0));
  import { cityTitle } from "../../utils/store.js";

  cityTitle.set("Report");
</script>

<div class="pagewrap">
  <h1>Report an update or correction</h1>

  <p>
    Jurisdictions report police data in a variety of places and use differing
    terminology. In addition, jurisdictions sometimes post new information, take
    down information, cease updating datasets, or move data to different web
    locations.
  </p>

  <p>
    If you are aware of such changes, please <a
      href="mailto:dbodah@vera.org?cc=fwunschel@vera.org&subject=Update/Correction to Police Data Transparency Index"
      >email Vera</a
    > with the relevant information, including the jurisdiction, the type of data,
    and the web address where the data can be found.
  </p>

  <p>
    Twice a year through 2023, Vera will update the Police Data Transparency
    Index in response to these notifications. Vera will acknowledge your message
    on receipt and schedule a review and validation of the relevant data source
    for the next biannual update period. Vera will modify the Police Data
    Transparency Index to reflect the status of data transparency as of the date
    on which the biannual update goes into effect.
  </p>
</div>

<style>
  h1 {
    font-family: "Whyte Black";
  }
  p {
    max-width: 1100px;
  }
  p:nth-of-type(3) {
    min-height: 300px;
  }
  @media all and (max-width: 500px) {
    h1 {
      margin-top: 0;
    }
  }
</style>
