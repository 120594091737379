<script>
</script>

<footer>
  <div id="vera">
    <div id="mobilewrap">
      <div class="section">
        <div id="logo">
          <a href="https://www.vera.org">
            <img
              id="logo"
              src="https://www.vera.org/dist/img/v2/vera-horizontal.svg"
            />
          </a>
        </div>
        <p class="address">
          34 35th Street<br />
          Suite 4-2A<br />
          Brooklyn, NY 11232
        </p>
        <p class="phone">212-334-1300</p>
      </div>
      <div class="section footer-menu">
        <ul>
          <li>
            <a href="https://www.vera.org/who-we-are/about-us">About Us</a>
          </li>
          <li><a href="https://www.vera.org/careers">Careers</a></li>
          <li><a href="https://www.vera.org/events">Events</a></li>
          <li><a href="https://www.vera.org/media-center">Media</a></li>
          <li><a href="https://www.vera.org/contact">Contact Us</a></li>
        </ul>
      </div>
      <div class="section soc">
        <p class="keep">Keep up with us on social media.</p>
        <div class="social-icons">
          <a
            href="https://www.twitter.com/intent/tweet?url=https%3A%2F%2Fwww.vera.org%2F&amp;amp;text=Home %20%40verainstitute"
            class="share-link social-share-link receives-color"
            target="_blank"
          >
            <svg
              class="svg-inline--fa"
              aria-hidden="true"
              prefix="fab"
              data-icon="twitter"
              role="img"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
              />
            </svg>
          </a>
          <a
            href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.vera.org%2F&amp;caption=Home%20&amp;picture=https://www.vera.org/dist/img/social-fallback.jpg&amp;description="
            class="share-link social-share-link receives-color"
            target="_blank"
          >
            <svg
              class="svg-inline--fa"
              aria-hidden="true"
              prefix="fab"
              data-icon="facebook"
              role="img"
              viewBox="0 0 320 512"
            >
              <path
                fill="currentColor"
                d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
              />
            </svg>
          </a>
          <a href="https://www.instagram.com/verainstitute/" target="_blank">
            <svg
              class="svg-inline--fa"
              aria-hidden="true"
              prefix="fab"
              data-icon="instagram"
              role="img"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
              />
            </svg>
          </a>
          <a
            href="https://www.youtube.com/channel/UCdUfqPJeOB_bHFdx1IhR0jQ"
            target="_blank"
          >
            <svg
              class="svg-inline--fa"
              aria-hidden="true"
              prefix="fab"
              data-icon="youtube"
              role="img"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>

    <div class="section">
      <div id="transformative">
        <p>Transformative change, sent to your inbox.</p>
        <button class="button orange arrow">Add your email</button>
      </div>
    </div>
  </div>
  <div id="terms">
    <ul>
      <li>&copy; Vera Institute of Justice. All rights reserved.</li>
      <li><a href="https://www.vera.org/terms-of-use">Terms of Use</a></li>
      <li><a href="https://www.vera.org/gsa-advantage">GSA Advantage</a></li>
    </ul>
  </div>
</footer>

<style>
  footer {
    padding: 4em 0em 0.5em;
    background-color: #0e002c;
    color: #fff;
  }
  .footer-menu {
    padding-top: 40px;
    padding-left: 70px;
  }
  .address {
    margin-top: 37px;
    font-size: 14px;
  }
  .phone {
    font-size: 14px;
  }
  .soc {
    padding-top: 27px;
  }
  #vera {
    display: flex;
    padding: 1em 0 3em 3em;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  ul li {
    list-style-type: none;
    list-style-image: none;
    padding: 0;
    margin: 0;
    line-height: 2;
    font-family: "Whyte Bold";
  }
  ul li a {
    color: #fff;
  }
  ul li a:hover {
    color: #abffeb;
    text-decoration: none;
  }
  ul li a:hover:after {
    content: " ⟶";
    transition: 0.5s;
  }
  .section {
    width: calc(25%);
  }
  .keep {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    width: 195px;
  }
  #transformative {
    background-color: #2e1d97;
    padding: 1em 3em;
    font-family: "Whyte Bold";
    font-size: 24px;
  }
  #transformative button {
    background-color: #ef4136;
    background-image: url("https://www.vera.org/dist/img/v2/arrow-white.svg");
    background-repeat: no-repeat;
    background-position: right 20px center;
    color: #fff;
    font-family: "Whyte Book";
    font-size: 14px;
    padding: 10px;
    padding-left: 20px;
    width: 170px;
    text-align: left;
    border: 0px;
  }
  .button.orange:after {
    background-color: transparent;
    left: 6px;
    top: 6px;
    width: 100%;
    height: 100%;
    border: 2px solid #97dfd1;
  }
  #terms {
    width: 96%;
    border-top: 1px solid #2e1d97;
    padding: 1rem 2%;
    font-size: 14px;
  }
  #terms ul li {
    display: inline-block;
    margin-right: 30px;
    font-family: "Whyte Book";
    font-size: 14px;
    padding: 8px 5px !important;
  }
  #terms ul li a {
    text-decoration: underline !important;
    text-decoration-color: #ef4136 !important;
    padding: 8px 5px !important;
  }
  #terms ul li a:hover {
    background-color: #ef4136;
    color: #fff;
  }
  #terms ul li a:hover:after {
    content: "" !important;
  }
  #terms ul li:last-of-type {
    margin-right: 0;
  }
  .social-icons {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    padding: 0;
    margin: 0;
  }
  .social-icons a {
    background-color: #abffeb;
    color: #160643;
    width: 22px;
    height: 22px;
    font-size: 20px;
    line-height: 18px;
    text-align: center;
    padding: 5px;
    margin-right: 8px;
    transition: all 0.2s ease-in-out;
  }
  .social-icons a:hover {
    background-color: #ef4136;
  }
  .svg-inline--fa {
    overflow: visible;
    box-sizing: content-box;
    height: 1em;
  }

  @media all and (max-width: 1200px) {
    #transformative {
      padding: 1em 2em;
    }
  }
  @media all and (max-width: 1200px) {
    #transformative {
      padding: 1em 1em;
    }
  }
  @media all and (min-width: 901px) {
    #mobilewrap {
      display: flex;
      width: 100%;
    }
  }
  @media all and (max-width: 900px) {
    footer {
      padding: 2em 0em 0.5em;
    }
    #vera {
      padding: 0;
      flex-direction: column;
    }
    .section {
      width: calc(100% -20px);
    }
    #terms {
      line-height: 170%;
    }
    #terms ul li:first {
      display: block;
    }
    #terms ul li {
      padding: 2px 5px !important;
    }
    .soc {
      padding-top: 10px;
      margin-bottom: 0px;
    }
    .section {
      width: calc(100%);
    }
    .footer-menu {
      padding-left: 0;
      padding-top: 10px;
    }
    #transformative {
      margin-top: 20px;
    }
    #logo img {
      width: 50%;
    }
    .address {
      margin-top: 20px;
    }
    .phone {
      color: #ef4136;
    }
    .keep {
      font-family: "Whyte Book";
      font-size: 15px;
      width: 140px;
    }
    .social-icons a {
      margin-right: 50px;
    }
    #mobilewrap {
      padding-left: 3em;
    }
  }
</style>
