<script>
  import { onMount } from "svelte";
  import loadData from "../utils/loadData";
  import * as d3 from "d3";
  import App from "../App.svelte";

  const colors = [
    { color: "#FFF7F0", value: 0, desc: "min" },
    { color: "#BEAFBB", value: 10, desc: "" },
    { color: "#AE9FBA", value: 20, desc: "" },
    { color: "#9E8EB8", value: 30, desc: "" },
    { color: "#9988b8", value: 37, desc: "median" },
    { color: "#8E7EB7", value: 40, desc: "" },
    { color: "#7E6DB6", value: 50, desc: "" },
    { color: "#6E5DB5", value: 60, desc: "" },
    { color: "#5543b2", value: 66, desc: "highest" },
    { color: "#5E4CB3", value: 70, desc: "" },
    { color: "#4E3CB2", value: 80, desc: "" },
    { color: "#3E2BB1", value: 90, desc: "" },
    { color: "#0E002C", value: 100, desc: "max" },
  ];

  const extent = d3.extent(colors, (d) => d.value);

  let pageWidth = d3.select(".app-wrapper").node().getBoundingClientRect();
  console.log("pageWidth", pageWidth);

  const padding = 9;
  const width = pageWidth.width;
  const barHeight = 30;
  const height = 106;

  let appPad;
  if (width > 1150 && width <= 1360) {
    appPad = 126;
  } else if (width > 767 && width <= 1150) {
    appPad = 78;
  } else if (width <= 767) {
    appPad = 80;
  } else {
    appPad = 190;
  }
  console.log("width", pageWidth.width);
  let innerWidth;
  if (width > 767) {
    innerWidth = (width - appPad) * 0.3;
  } else {
    innerWidth = width - appPad;
  }

  const xScale = d3.scaleLinear().range([0, innerWidth]).domain(extent);

  let minScore,
    maxScore,
    citiesO50,
    cities = [];

  var xTicks = colors
    .filter((f) => f.value == 0 || f.value == 100)
    .map((d) => d.value);

  var xAxis = d3
    .axisBottom(xScale)
    .tickSize(barHeight * 1.5)
    .tickValues(xTicks);

  let el;

  onMount(() => {
    var svg = d3.select(el).attr("width", innerWidth).attr("height", height);

    var g = svg.append("g").attr("transform", "translate(" + padding + ", 0)");

    var defs = svg.append("defs");
    var linearGradient = defs
      .append("linearGradient")
      .attr("id", "ptiGradient");
    linearGradient
      .selectAll("stop")
      .data(colors)
      .enter()
      .append("stop")
      .attr(
        "offset",
        (d) => ((d.value - extent[0]) / (extent[1] - extent[0])) * 100 + "%"
      )
      .attr("stop-color", (d) => d.color);

    g.append("rect")
      .attr("width", innerWidth)
      .attr("height", barHeight)
      .style("fill", "url(#ptiGradient)");

    g.selectAll("text").attr("font-size", 16);

    g.append("g").call(xAxis).select(".domain").remove();
  });
  onMount(async () => {
    let data = await loadData();
    cities = data[0];
    console.log("cities", cities);
    maxScore = d3.max(cities, (d) => d.cityScore).toFixed(0);
    minScore = d3.min(cities, (d) => d.cityScore);
    citiesO50 = cities.filter((d) => d.cityScore.toFixed(0) >= 50).length;
    console.log("citiesO50", citiesO50);
  });
</script>

<div id="legend-container">
  <div id="highlights">
    <h6>
      The highest score achieved was {maxScore} out of 100. Only {citiesO50} out
      of 94 places earned a score of 50 or higher.
    </h6>
    <p class="learn">
      Learn more about Vera’s
      <a href="/PTI-factsheet.pdf">findings and recommendations</a>.<br />
      <a href="/#/report">Report an update or correction</a>.
    </p>
  </div>
  <svg bind:this={el} id="legend">
    <g />
  </svg>
</div>

<style>
  svg {
    width: 31.5%;
    height: 100%;
    margin-left: auto;
  }
  h6 {
    font-size: 20px;
    font-style: normal;
    line-height: 30px;
    letter-spacing: 0;
    text-align: left;
    font-family: "Whyte Bold";
    color: #0a0319;
    margin: 1em 0;
  }
  #highlights {
    width: calc(63%);
  }
  #legend {
    height: 70px;
    margin-top: 2em;
  }
  #legend-container {
    display: flex;
  }
  @media all and (max-width: 767px) {
    #legend-container {
      flex-direction: column;
    }
    svg {
      width: 99%;
    }
    h6 {
      width: calc(100%);
    }
  }
  @media all and (max-width: 600px) {
    #highlights {
      width: calc(92%);
      line-height: 140%;
      padding: 0 4%;
    }
    svg {
      width: 92%;
    }
    p.learn {
      margin-bottom: 0px;
    }
    #legend {
      margin-top: 1em;
    }
  }
</style>
