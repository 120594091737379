<script>
  import loadData from "../../utils/loadData";
  import colorScale from "../../utils/colorScale";
  import Btn from "../Btn.svelte";
  import * as d3 from "d3";
  import { textSearchFilter, citiesData } from "../../utils/store.js";

  let sortDirection;

  let cities;
  let empties;
  let diffs;

  let current = "sort1";

  $: sortOrder1 = "ASC";
  $: sortOrder2 = "null";
  $: sortOrder3 = "null";
  $: sortOrder4 = "null";
  $: sortOrder5 = "null";
  $: sortOrder6 = "null";
  $: sortOrder7 = "null";
  $: sortOrder8 = "null";
  $: sortOrder9 = "null";
  $: sortOrder10 = "null";
  $: sortOrder11 = "null";
  $: sortOrder12 = "null";

  $: updateData();

  async function getData() {
    let data = await loadData();
    cities = d3.filter(data[0], (d) => d.location !== "NA");
    cities.sort((a, b) => d3.ascending(a.location, b.location));
    return cities;
  }
  const formatDate = d3.timeFormat("%B %d, %Y");

  async function getDiffs() {
    let data = await loadData();
    diffs = d3.filter(data[3], (d) => d.Difference !== 0);
    diffs.sort((a, b) => a.Date - b.Date);
    return formatDate(diffs[0].UpdateDate);
  }

  $citiesData = cities;

  async function updateData() {
    cities = await getData();
    diffs = await getDiffs();
  }

  export function searchFor($textSearchFilter) {
    if (cities) {
      return cities.filter((item) => {
        return Object.values(item).some((value) => {
          if (typeof value === "number") {
            value = String(value);
          }
          if (typeof value === "object") {
            value = value;
          }

          return value.toLowerCase().includes($textSearchFilter.toLowerCase());
        });
      });
    }
  }

  $: filtered = $textSearchFilter ? searchFor($textSearchFilter) : cities;

  function sortCity(data, key) {
    if (sortDirection == undefined) {
      sortDirection = "ASC";
    }
    let columns = data.cities;
    if (key == "city" && sortDirection == "ASC") {
      sortOrder1 = "ASC";
      sortDirection = "DESC";
      cities = columns.sort((a, b) => d3.ascending(a[key], b[key]));
    } else if (key == "city" && sortDirection == "DESC") {
      sortOrder1 = "DESC";
      sortDirection = "ASC";
      cities = columns.sort((a, b) => d3.descending(a[key], b[key]));
    } else if (key !== "city" && sortDirection == "ASC") {
      sortOrder2 = "ASC";
      sortOrder3 = "ASC";
      sortOrder4 = "ASC";
      sortOrder5 = "ASC";
      sortOrder6 = "ASC";
      sortOrder7 = "ASC";
      sortOrder8 = "ASC";
      sortOrder9 = "ASC";
      sortOrder10 = "ASC";
      sortOrder11 = "ASC";
      sortOrder12 = "ASC";
      sortDirection = "DESC";
      cities = columns.sort((a, b) => d3.descending(a[key], b[key]));
    } else if (key !== "city" && sortDirection == "DESC") {
      sortOrder2 = "DESC";
      sortOrder3 = "DESC";
      sortOrder4 = "DESC";
      sortOrder5 = "DESC";
      sortOrder6 = "DESC";
      sortOrder7 = "DESC";
      sortOrder8 = "DESC";
      sortOrder9 = "DESC";
      sortOrder10 = "DESC";
      sortOrder11 = "DESC";
      sortOrder12 = "DESC";
      sortDirection = "ASC";
      cities = columns.sort((a, b) => d3.ascending(a[key], b[key]));
    }
  }
</script>

Last updated: {diffs}
<div
  class="grid-container"
  role="table"
  aria-label="City Police Data Transparency Index Scores"
>
  <div class="flex-grid header" role="rowgroup">
    <div class="flex-row first" role="columnheader">
      <div class="straight-label first">City</div>
      <button
        class="sort {sortOrder1}"
        class:selected={current === "sort1"}
        on:click={() => (current = "sort1")}
        on:click={sortCity({ cities }, "city")}
      />
    </div>
    <div class="flex-row second three" role="columnheader">
      <Btn>
        <div class="straight-label">
          Score<button
            class="sort {sortOrder2}"
            on:click={sortCity({ cities }, "cityScore")}
            class:selected={current === "sort2"}
            on:click={() => (current = "sort2")}
          />
        </div>
      </Btn>
    </div>
    <div class="flex-row two header complaints" role="columnheader">
      <a href="/#/complaints"
        ><Btn
          tooltip="Communities often express great concern about misbehavior by police officers. Police departments ordinarily have an internal affairs, professional standards, or similar internal office dedicated to receiving and investigating complaints against police officers."
          ><div class="rotate-label">
            Complaints <span class="mobileheader">
              About <span class="textwrap">Police Misconduct</span></span
            >
          </div></Btn
        ></a
      >
      <button
        class="sort {sortOrder3}"
        class:selected={current === "sort3"}
        on:click={() => (current = "sort3")}
        on:click={sortCity({ cities }, "complaints_subtotal")}
      />
    </div>
    <div class="flex-row two ois header" role="columnheader">
      <a href="/#/instancesofofficersshootingfirearms"
        ><Btn
          tooltip="All incidents involving officers firing a gun (apart from during firearms training) should be reported to the public. Vera indexed this data separately from use of force data because shooting incidents are of great interest to the public."
          ><div class="rotate-label">
            <span class="mobileheader">Instances of </span>Officers
            <span class="textwrap">Shooting Firearms</span>
          </div></Btn
        ></a
      >
      <button
        class="sort {sortOrder4}"
        class:selected={current === "sort4"}
        on:click={() => (current = "sort4")}
        on:click={sortCity({ cities }, "shooting_subtotal")}
      />
    </div>
    <div class="flex-row two header" role="columnheader">
      <a href="/#/useofforce"
        ><Btn
          tooltip="Use of force occurs any time an officer uses physical force in the course of an interaction with people in the community."
          ><div class="rotate-label">Use of Force</div></Btn
        ></a
      >
      <button
        class="sort {sortOrder5}"
        class:selected={current === "sort5"}
        on:click={() => (current = "sort5")}
        on:click={sortCity({ cities }, "uof_subtotal")}
      />
    </div>
    <div class="flex-row onepfive header" role="columnheader">
      <a href="/#/arrests"
        ><Btn
          tooltip="Cities and counties should publicly report all incidents in which police officers take people into custody to answer charges of violating the law."
          ><div class="rotate-label">Arrests</div></Btn
        ></a
      >
      <button
        class="sort {sortOrder6}"
        class:selected={current === "sort6"}
        on:click={() => (current = "sort6")}
        on:click={sortCity({ cities }, "arr_subtotal")}
      />
    </div>
    <div class="flex-row onepfive header cfs" role="columnheader">
      <a href="/#/callsforservice"
        ><Btn
          tooltip="“Calls for service” (often abbreviated to CFS) usually occur when a person contacts an emergency call center about an incident and requests a police response and the call center logs the call. Officers are then dispatched to some—but not all—of these calls."
          ><div class="rotate-label">
            Calls for Service/<span class="mobileheader"><br /></span><span
              class="textwrap">911 Calls</span
            >
          </div></Btn
        ></a
      >
      <button
        class="sort {sortOrder7}"
        class:selected={current === "sort7"}
        on:click={() => (current = "sort7")}
        on:click={sortCity({ cities }, "cfs_subtotal")}
      />
    </div>
    <div class="flex-row stops onepfive header" role="columnheader">
      <a href="/#/trafficpedestrianstops"
        ><Btn
          tooltip="Traffic stops are the most frequent kind of encounter between police officers and members of the public. Police may also stop people outside of a car, which is a pedestrian stop."
          ><div class="rotate-label">
            Traffic/Pedestrian <span class="textwrap">Stops</span>
          </div></Btn
        ></a
      >
      <button
        class="sort {sortOrder8}"
        class:selected={current === "sort8"}
        on:click={() => (current = "sort8")}
        on:click={sortCity({ cities }, "stops_subtotal")}
      />
    </div>
    <div class="flex-row onepfive header" role="columnheader">
      <a href="/#/training"
        ><Btn
          tooltip="Police trainings vary greatly across the 18,000+ law enforcement agencies in the United States. Training can be provided by state or regional academies and/or by police departments themselves."
          ><div class="rotate-label">Training</div></Btn
        ></a
      >
      <button
        class="sort {sortOrder9}"
        class:selected={current === "sort9"}
        on:click={() => (current = "sort9")}
        on:click={sortCity({ cities }, "training_subtotal")}
      />
    </div>
    <div class="flex-row one header" role="columnheader">
      <a href="/#/crime"
        ><Btn
          tooltip="Police learn about crimes when the public reports a crime or a crime is discovered by the police in the course of their duties. Crimes are usually categorized as violent crimes, property crimes, or offenses against public order."
          ><div class="rotate-label">Crime Reports</div></Btn
        ></a
      >
      <button
        class="sort {sortOrder10}"
        class:selected={current === "sort10"}
        on:click={() => (current = "sort10")}
        on:click={sortCity({ cities }, "crime_subtotal")}
      />
    </div>
    <div class="flex-row one header" role="columnheader">
      <a href="/#/policecontactinformation"
        ><Btn
          tooltip="Cities and counties should provide the public with information about ways to contact and interact with the local police or sheriff’s department in nonemergency situations."
          ><div class="rotate-label">Police Contact Info</div></Btn
        ></a
      >
      <button
        class="sort {sortOrder11}"
        class:selected={current === "sort11"}
        on:click={() => (current = "sort11")}
        on:click={sortCity({ cities }, "contact_info_subtotal")}
      />
    </div>
    <div class="flex-row one header" role="columnheader">
      <a href="/#/policies"
        ><Btn
          tooltip="Cities and counties have rules and expectations that their police officers or sheriff’s deputies must comply with. Certain policies are of special concern to the public—for example, policies regarding when officers may use force."
          ><div class="rotate-label">Policies</div></Btn
        ></a
      >
      <button
        class="sort last {sortOrder12}"
        class:selected={current === "sort12"}
        on:click={() => (current = "sort12")}
        on:click={sortCity({ cities }, "policy_subtotal")}
      />
    </div>
  </div>

  {#if filtered}
    <ul class="table">
      {#each filtered as city}
        <div class="flex-grid row" role="rowgroup">
          <div class="flex-row first" role="cell">
            <a href="/#/city/{city.location}{city.state_abbr}">
              <div>{city.location}, {city.state}</div>
            </a>
          </div>
          <div
            class="flex-row three score"
            style="background-color:{colorScale(city.cityScore)}"
          >
            <a href="/#/city/{city.location}{city.state_abbr}">
              <Btn
                tooltip="Overall Department Score: {city.cityScore.toFixed(0)}"
                classList="grid-cell {city.arr_cityScore === 0 ? 'zero' : ''}"
              >
                {city.cityScore.toFixed(0)}
              </Btn>
            </a>
          </div>
          <div
            class="flex-row two"
            id="complaints"
            role="cell"
            style="background-color:{colorScale(city.complaints_subtotal)}"
          >
            <a href="/#/city/{city.location}{city.state_abbr}">
              <Btn
                tooltip="Complaints About Police Misconduct: {city.complaints_subtotal.toFixed(
                  0
                )}"
                classList="grid-cell {city.complaints_subtotal === 0
                  ? 'zero'
                  : ''}"
              >
                {city.complaints_subtotal.toFixed(0)}
              </Btn>
            </a>
          </div>
          <div
            class="flex-row two"
            id="shootings"
            role="cell"
            style="background-color:{colorScale(city.shooting_subtotal)}"
          >
            <a href="/#/city/{city.location}{city.state_abbr}">
              <Btn
                tooltip="Instances of Officers Shooting Firearms: {city.shooting_subtotal.toFixed(
                  0
                )}"
                classList="grid-cell {city.shooting_subtotal === 0
                  ? 'zero'
                  : ''}"
              >
                {city.shooting_subtotal.toFixed(0)}
              </Btn>
            </a>
          </div>
          <div
            class="flex-row two"
            id="use_of_force"
            role="cell"
            style="background-color:{colorScale(city.uof_subtotal)}"
          >
            <a href="/#/city/{city.location}{city.state_abbr}">
              <Btn
                tooltip="Use of Force: {city.uof_subtotal.toFixed(0)}"
                classList="grid-cell {city.uof_subtotal === 0 ? 'zero' : ''}"
              >
                {city.uof_subtotal.toFixed(0)}
              </Btn>
            </a>
          </div>
          <div
            class="flex-row onepfive"
            id="arrests"
            role="cell"
            style="background-color:{colorScale(city.arr_subtotal)}"
          >
            <a href="/#/city/{city.location}{city.state_abbr}">
              <Btn
                tooltip="Arrests: {city.arr_subtotal.toFixed(0)}"
                classList="grid-cell {city.arr_subtotal === 0 ? 'zero' : ''}"
              >
                {city.arr_subtotal.toFixed(0)}
              </Btn>
            </a>
          </div>
          <div
            class="flex-row onepfive"
            id="cfs_911"
            role="cell"
            style="background-color:{colorScale(city.cfs_subtotal)}"
          >
            <a href="/#/city/{city.location}{city.state_abbr}">
              <Btn
                tooltip="Calls for Service/911: {city.cfs_subtotal.toFixed(0)}"
                classList="grid-cell {city.cfs_subtotal === 0 ? 'zero' : ''}"
              >
                {city.cfs_subtotal.toFixed(0)}
              </Btn>
            </a>
          </div>
          <div
            class="flex-row onepfive"
            id="stops"
            role="cell"
            style="background-color:{colorScale(city.stops_subtotal)}"
          >
            <a href="/#/city/{city.location}{city.state_abbr}">
              <Btn
                tooltip="Traffic/ Pedestrian Stops: {city.stops_subtotal.toFixed(
                  0
                )}"
                classList="grid-cell {city.stops_subtotal === 0 ? 'zero' : ''}"
              >
                {city.stops_subtotal.toFixed(0)}
              </Btn>
            </a>
          </div>
          <div
            class="flex-row onepfive"
            id="training"
            role="cell"
            style="background-color:{colorScale(city.training_subtotal)}"
          >
            <a href="/#/city/{city.location}{city.state_abbr}">
              <Btn
                tooltip="Training: {city.training_subtotal.toFixed(0)}"
                classList="grid-cell {city.training_subtotal === 0
                  ? 'zero'
                  : ''}"
              >
                {city.training_subtotal.toFixed(0)}
              </Btn>
            </a>
          </div>
          <div
            class="flex-row one"
            id="crime"
            role="cell"
            style="background-color:{colorScale(city.crime_subtotal)}"
          >
            <a href="/#/city/{city.location}{city.state_abbr}">
              <Btn
                tooltip="Crime Reports: {city.crime_subtotal.toFixed(0)}"
                classList="grid-cell {city.crime_subtotal === 0 ? 'zero' : ''}"
              >
                {city.crime_subtotal.toFixed(0)}
              </Btn>
            </a>
          </div>
          <div
            class="flex-row one"
            id="info"
            role="cell"
            style="background-color:{colorScale(city.contact_info_subtotal)}"
          >
            <a href="/#/city/{city.location}{city.state_abbr}">
              <Btn
                tooltip="Police Contact Information: {city.contact_info_subtotal.toFixed(
                  0
                )}"
                classList="grid-cell {city.contact_info_subtotal === 0
                  ? 'zero'
                  : ''}"
              >
                {city.contact_info_subtotal.toFixed(0)}
              </Btn>
            </a>
          </div>

          <div
            class="flex-row one"
            id="policy"
            role="cell"
            style="background-color:{colorScale(city.policy_subtotal)}"
          >
            <a href="/#/city/{city.location}{city.state_abbr}">
              <Btn
                tooltip="Policies: {city.policy_subtotal.toFixed(0)}"
                classList="grid-cell {city.policy_subtotal === 0 ? 'zero' : ''}"
              >
                {city.policy_subtotal.toFixed(0)}
              </Btn>
            </a>
          </div>
        </div>
      {/each}
    </ul>
  {/if}
</div>

<style>
  ul.table {
    margin: 0;
    padding: 0;
  }
  .grid-container {
    display: block;
    margin: 48px 0 0.5em;
    width: 100%;
  }
  .flex-grid {
    display: flex;
    flex-flow: row wrap;
  }
  .flex-grid.header {
    padding-bottom: 0px;
  }
  .flex-grid.row {
    border-bottom: 1px solid #333;
    padding: 0.5em 0;
    height: 80px;
  }
  .flex-grid.row:hover {
    background-color: #f3e8de;
  }
  .flex-grid.row:hover .flex-row.first a div:after {
    content: "❯";
    width: 1em;
    height: 1em;
    margin-left: 10px;
    text-align: center;
  }

  .flex-row.first {
    width: calc(15%);
    text-align: left;
    padding: 0.75em 0.35em;
    flex-grow: 1;
    border: 0;
    color: #0a0319;
    text-shadow: none;
    margin-bottom: 5px;
    font-size: 20px;
    font-family: "Whyte Bold";
  }
  button.sort {
    border-radius: 0;
  }
  .flex-row.first button.sort {
    margin-top: 0px;
  }
  .flex-grid.header a {
    line-height: 120%;
  }
  .flex-grid.header a,
  .flex-row.first a {
    color: #0a0319;
  }
  .flex-row.first a:hover div:after {
    content: "❯";
    width: 1em;
    height: 1em;
    margin-left: 10px;
    text-align: center;
  }
  .flex-row.second button.sort {
    margin-top: 11px;
  }
  .flex-row.ois button.sort,
  .flex-row.cfs button.sort,
  .flex-row.complaints button.sort,
  .flex-row.stops button.sort {
    margin-top: 56px;
  }
  button.sort {
    width: 100%;
    height: 15px;
    margin-top: 75px;
    float: right;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%234f4f4f' d='M16.29 14.29L12 18.59l-4.29-4.3a1 1 0 00-1.42 1.42l5 5a1 1 0 001.42 0l5-5a1 1 0 00-1.42-1.42zM7.71 9.71L12 5.41l4.29 4.3a1 1 0 001.42 0 1 1 0 000-1.42l-5-5a1 1 0 00-1.42 0l-5 5a1 1 0 001.42 1.42z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center 1px;
    background-color: #f3e8de;
    background-size: 13px 13px;
    border: 0px;
  }

  button.sort.selected.ASC {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M11.29 11.46a1 1 0 001.42 0l3-3A1 1 0 1014.29 7L12 9.34 9.71 7a1 1 0 10-1.42 1.46zm3 1.08L12 14.84l-2.29-2.3A1 1 0 008.29 14l3 3a1 1 0 001.42 0l3-3a1 1 0 00-1.42-1.42z'/%3E%3C/svg%3E");
  }

  button.sort.selected.DESC {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M12.71 12.54a1 1 0 00-1.42 0l-3 3A1 1 0 009.71 17L12 14.66 14.29 17a1 1 0 001.42 0 1 1 0 000-1.42zm-3-1.08L12 9.16l2.29 2.3a1 1 0 001.42 0 1 1 0 000-1.42l-3-3a1 1 0 00-1.42 0l-3 3a1 1 0 001.42 1.42z'/%3E%3C/svg%3E");
  }

  .flex-row.score a {
    color: #ffffff !important;
    font-family: "Whyte Black";
    padding-top: 0px;
    font-size: 30px;
  }
  .flex-row.two {
    width: calc(55% * 0.133);
  }
  .flex-row.three {
    width: calc(55% * 0.18);
    margin-right: 0.6em;
  }
  .flex-row.second.three,
  .flex-row.three.sort {
    width: calc(55% * 0.18);
    border: 0px;
    margin-right: 0.6em;
    color: #0a0319;
    height: 10px;
  }
  .flex-row.onepfive {
    width: calc(55% * 0.1);
  }
  .flex-row.one {
    width: calc(55% * 0.066);
  }

  .flex-row {
    text-align: left;
    margin: 0 0.15em;
    padding: 0;
    text-align: center;
    flex-grow: 0;
    color: #fff;
    height: 80px;
  }
  .flex-row a {
    text-decoration: none;
    color: #fff;
  }
  .flex-row a div {
    height: 100%;
  }
  .header .flex-row {
    text-align: left;
  }
  .straight-label {
    padding-top: 40px;
    margin-top: 10px;
  }
  a .straight-label > button.selected {
    font: "Whyte Bold";
  }
  .flex-row.second .straight-label {
    padding-top: 50px;
    margin-top: 10px;
  }
  .rotate-label {
    width: 170px;
    transform: rotate(-50deg);
    margin-left: -20px;
    margin-top: 0px;
  }
  .textwrap {
    margin-left: 20px;
  }

  button {
    width: 100%;
    padding: 3px;
    margin: 3px;
  }
  a {
    width: 100%;
  }
  button.selected {
    background-color: #0e002c;
    color: white;
  }
  @media all and (min-width: 767px) and (max-width: 1100px) {
    .header .flex-row {
      margin: 0.5em 0.2em;
    }
    .flex-row {
      margin: 0 0.2em;
    }
  }
  @media all and (max-width: 980px) {
    .flex-row.first {
      font-size: 15px;
      line-height: 120%;
      padding-top: 0.2em;
    }
    .straight-label {
      padding-top: 58px;
    }
    .flex-row.second button.sort {
      margin-top: 7px;
    }
    .flex-row.ois button.sort,
    .flex-row.cfs button.sort,
    .flex-row.complaints button.sort,
    .flex-row.stops button.sort {
      margin-top: 51px;
    }
    button.sort {
      margin-top: 70px;
    }
  }
  @media all and (max-width: 767px) {
    .header .flex-row {
      margin: 0.5em 1px;
      line-height: 100%;
    }
    .flex-row {
      margin: 0 1px;
    }
    .flex-row.first {
      padding: 0.75em 0em;
    }
    .rotate-label {
      width: 200px;
      transform: rotate(-90deg);
      margin-left: -90px;
      margin-top: -30px;
      line-height: 100%;
    }
    .textwrap {
      margin-left: 0px;
    }
    .flex-row.three {
      margin-right: 0.1em;
    }
    button.sort,
    .flex-row.stops button.sort,
    .flex-row.ois button.sort,
    .flex-row.cfs button.sort,
    .flex-row.complaints button.sort {
      margin-top: 100px;
    }
    .flex-grid.header {
      padding-bottom: 0;
      margin-top: 130px;
    }
    .flex-row.score a {
      font-size: 22px;
    }
    .flex-row.second.three {
      margin-right: 0.3em;
    }
    .mobileheader {
      display: none;
    }
    .flex-row.first button.sort {
      margin-top: 10px;
    }
    .flex-row.second button.sort {
      margin-top: 10px;
    }
    .straight-label {
      padding-top: 40px;
    }
  }
  @media all and (max-width: 500px) {
    .flex-row {
      margin: 0;
    }
    .flex-row.three {
      margin-right: 0px;
    }
    .header .flex-row.first {
      padding-right: 0;
      padding-left: 0;
      margin-top: 2px;
    }
    .straight-label.first {
      padding-left: 5px;
    }
    .flex-row.first button.sort {
      margin-top: 4px;
    }
    .flex-row.first {
      width: calc(10%);
      font-size: 14px;
      font-family: "Whyte Book";
      padding: 16px 3px 0 5px;
    }
    .flex-row.two {
      width: calc(65% * 0.133);
    }
    .flex-row.three {
      width: calc(65% * 0.18);
    }
    .flex-row.second.three,
    .flex-row.three.sort {
      width: calc(65% * 0.18);
    }
    .flex-row.onepfive {
      width: calc(65% * 0.1);
    }
    .flex-row.one {
      width: calc(65% * 0.066);
    }
    button.sort {
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 2.5px;
      border-right: 0.5px solid #d2bab4;
    }
    button.sort.last {
      border-right: 0px;
    }
    .header .flex-row {
      margin: 0px;
    }
    .flex-row.second.three {
      margin-right: 0px;
    }
    .flex-grid.row {
      padding: 0.25em 0;
      height: 70px;
    }
    .flex-grid.header {
      padding-bottom: 13px;
    }
    .flex-row {
      height: 70px;
    }
  }
</style>
