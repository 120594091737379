<script>
  import { onMount } from "svelte";
  import loadData from "../../../utils/loadData";
  import * as d3 from "d3";
  import { LayerCake, Svg } from "layercake";
  import Sankey from "../../sankey/Sankey.svelte";
  import data from "../../../data/uof";
  import CategoryColumn from "../../city-column/CategoryColumn.svelte";
  import CategoryNav from "../../CategoryNav.svelte";
  import { cityTitle } from "../../../utils/store.js";

  cityTitle.set("Use of Force");

  let cities = [],
    ccategory = [],
    categoryData = [];

  onMount(async () => {
    window.scrollTo(0, 0);
    let data = await loadData();
    cities = data[0];

    cities.sort((a, b) => d3.ascending(b.cityScore, a.cityScore));
  });
</script>

<div class="categorypage">
  <h2>Use of Force</h2>
  <p>
    All incidents in which police use force should be recorded and reported to the public.
  </p>
  <p>Use of force occurs any time an officer uses physical force in the course of an interaction with people in the community.</p>
  <p>
    Use of force includes unarmed physical force—from holds to punches and kicks—and all uses of coercive restraints or weapons. This includes, but is not limited to, strikes with a baton or other hard object, use of conducted energy devices (e.g., Tasers), pepper spray, tear gas, bean bag rounds, rubber bullets, and firearms or other deadly weapons.
  </p>
  <div class="chart-wrap">
    <CategoryColumn categoryVar={"uof_subtotal"} />
  </div>
  <p class="anno">Of the 94 jurisdictions in the index, 34 do not provide information on use of force and received a score of zero.</p>
  <p>
    Vera examined use of force data for the following transparency characteristics:
  </p>
  <ul>
    <li>
      <span class="strong">Fatalities</span>: Whether the dataset includes information about uses of force that ended in death.
    </li>
    <li>
      <span class="strong">Type of force used</span>: Whether the dataset described the kind of force used (for example, physical force, pepper spray, strike with an object, gunshot).
    </li>
    <li>
      <span class="strong">Civilian injury</span>: Whether information about injuries to community members is included.
    </li>
    <li>
      <span class="strong">Officer injury</span>: Whether information about injuries to police officers is included.
    </li>
    <li>
      <span class="strong">Justification</span>: Whether a reason for the use of force is provided.
    </li>
    <li>
      <span class="strong">Civilian weapon</span>: Whether the data notes what kind of weapon, if any, the involved community member possessed or used.
    </li>
    <li>
      <span class="strong">Officer weapon</span>: Whether the data notes the kind of weapon, if any, the involved police officer used.
    </li>
    <li>
      <span class="strong">Bodycam footage</span>: Whether body-worn camera footage of the incident is publicly available.
    </li>
    <li>
      <span class="strong">Arrest made</span>: WWhether the police arrested the involved community member.      
    </li>
    <li>
      <span class="strong">URL</span>: Whether the city or county makes data available via an easily located and accessible website.
    </li>
    <li>
      <span class="strong">Downloadability</span>: Whether the information can be downloaded.
    </li>
    <li>
      <span class="strong">Data format</span>: The extent to which the data is
      in a file format that can be independently analyzed
    </li>
    <li>
      <span class="strong">Aggregation level</span>: Ideally, data should be available describing each incident; Vera gave a lower score for data that is supplied only in aggregate summary over time (e.g., quarterly or annual totals).
    </li>
    <li>
      <span class="strong">Codebook or data dictionary</span>: Visitors to the website should be provided with a dictionary that explains what kinds of events are recorded in the data and the meanings of any abbreviations, codes, or specialized jargon in the data.
    </li>
    <li>
      <span class="strong">Officer demographics</span>: Whether the data contains the race, ethnicity, or both of involved officers.
    </li>
    <li>
      <span class="strong">Civilian demographics</span>: Whether the data contains the race, ethnicity, or both of involved community members.
    </li>
    <li>
      <span class="strong">Location</span>: Whether the data provides the locations of incidents in the form of GPS or other geocode coordinates, street address, or nearest intersection.
    </li>
    <li>
      <span class="strong">Embedded analytics</span>: Whether the website has online tools with which a visitor can create maps and/or make visualizations such as charts or graphs without downloading the data.
    </li>
    <li>
      <span class="strong">Timespan</span>:The length of time the website provides data from: more than two years, one to two years, less than a year, or none (if data is taken down shortly after posting without being archived).
    </li>
    <li>
      <span class="strong">Update frequency</span>: Whether the data is updated biweekly, monthly, quarterly, biannually, annually, or less than annually.
    </li>
    <li>
      <span class="strong">FAQ/User guide</span>: Whether the website contains information to help users understand how to use the website and orient themselves to its features.
    </li>
  </ul>
  <div class="chart-container">
    <LayerCake {data}>
      <Svg>
        <Sankey colorNodes={(d) => "#2E1D97"} colorLinks={(d) => "#97DFD1"} />
      </Svg>
    </LayerCake>
  </div>
</div>

<style>
  .categorypage {
    margin-left: 120px;
  }
  h2 {
    font-family: "Whyte Black";
    font-size: 50px;
    font-weight: bold;
    margin: 0px 0 50px 0;
    padding-top: 5px;
  }
  span.strong {
    font-family: "Whyte Bold";
  }

  /*
    The wrapper div needs to have an explicit width and height in CSS.
    It can also be a flexbox child or CSS grid element.
    The point being it needs dimensions since the <LayerCake> element will
    expand to fill it.
  */
  .chart-wrap {
    margin: 50px 0 20px;
  }
  .chart-container {
    display: flex;
    width: 100%;
    height: 500px;
    margin: 50px 0;
  }
  .anno{
    font-style: italic;
    color: #555;
    text-align: right;
    width: 60%;
    margin-left: 30%;
    margin-right: 10%;
    margin-top: 0;
    font-size: 14px;
    margin-bottom: 50px;
  }
  @media all and (max-width: 767px) {
    .categorypage {
      margin-left: 0px;
    }
    h2 {
      margin-top: 0px;
      margin-bottom: 30px;
      font-size: 35px;
      line-height: 130%;
    }
    .chart-wrap {
      margin: 30px 0 10px;
    }
    .anno{
      margin-bottom: 30px;
    }
  }
  @media all and (max-width: 500px) {
    .categorypage {
      padding: 0 1em 0 1em;
    }
    h2{
      bottom:15px;
    }
  }
</style>