<script>
  export let params;
  import { onMount, tick } from "svelte";
  import loadData from "../../utils/loadData";
  import * as d3 from "d3";
  import colorScale from "../../utils/colorScale";
  import ColumnChart from "../city-column/ColumnChart.svelte";
  import Legend2 from "../Legend2.svelte";
  import CityCategoryScoreGrid from "../score-detail/CityCategoryScoreGrid.svelte";
  import CityScoreUpdates from "../score-detail/CityScoreUpdates.svelte";
  import CityScoreUpdatesDate from "../score-detail/CityScoreUpdatesDate.svelte";
  import CityIntro from "../sections/CityIntro.svelte";
  import { LayerCake, Svg } from "layercake";
  import Sankey from "../sankey/Sankey.svelte";
  import data from "../../data/metric_categories";
  import { cityTitle } from "../../utils/store.js";
  import { MetaTags } from "svelte-meta-tags";

  let cityNameOnly = params.cityName.substring(0, params.cityName.length - 2);
  let stateName = params.cityName.slice(-2);
  let cityState = cityNameOnly + ", " + stateName;
  cityTitle.set(cityState);

  let cities = [],
    currentCity = [],
    currentState = "";

  onMount(async () => {
    await tick();
    window.scrollTo(0, 0);
    let data = await loadData();
    cities = data[0];
    currentCity = cities.filter(
      (d) => d.location == cityNameOnly && d.state_abbr == stateName,
    );
    currentState = currentCity[0].state;
    cities.sort((a, b) => d3.ascending(b.cityScore, a.cityScore));
  });
</script>

<MetaTags title="Police Data Transparency Index" description="City......" />
<slot cityname={params} />
<div class="citypage">
  <h2>
    {cityNameOnly}, {currentState}
  </h2>
  {#each currentCity as ccity}
    <div id="score" style="background-color:{colorScale(ccity.cityScore)};">
      {ccity.cityScore.toFixed(0)}
    </div>
    <CityScoreUpdatesDate {ccity} />
    <CityIntro {ccity} />

    <ColumnChart {ccity} />
    <h3>Police Data Transparency scores</h3>
    <p>
      Based on the results of research into community needs, best practices, and
      legal mandates, Vera identified 10 data types that are essential for
      police transparency.
    </p>
    <Legend2 />
    <p>
      Jurisdictions received points for each type of data that they made
      available. They received additional points reflecting how easy that data
      was to access and understand.
    </p>
    <CityCategoryScoreGrid {ccity} />
    <h3>How Vera calculated total scores</h3>
    <p>
      Through Vera’s research—especially interviews with community members—it
      became clear that some of the 10 data types were more important to
      effective transparency than others. From this information, Vera organized
      the types of data important for transparency into three priority levels
      and weighted their scores accordingly:
    </p>
    <ol>
      <li>
        Police use of physical force or weapons, and internal and external
        complaints about police conduct (up to 40 points).
      </li>
      <li>
        Police patrol activities—including responses to calls for service,
        arrests, and traffic and pedestrian stops—and police training (up to 40
        points).
      </li>
      <li>
        Crime reports, department policies, and information about nonemergency
        ways to contact the department (up to 20 points).
      </li>
    </ol>
    <p>
      The figure below shows how each of the data types contributes to the total
      location score. Vera assigned weights to these data types based on their
      priority level. The relative thickness of the lines indicates the
      proportion of the total score accounted for by each data type.
    </p>
    <div class="chart-container">
      <LayerCake {data}>
        <Svg>
          <Sankey colorNodes={(d) => "#2E1D97"} colorLinks={(d) => "#97DFD1"} />
        </Svg>
      </LayerCake>
    </div>
    <CityScoreUpdates {ccity} />
  {/each}
</div>

<style>
  .citypage {
    margin-left: 120px;
  }
  h2 {
    font-family: "Whyte Black";
    font-size: 50px;
    font-weight: bold;
    margin: 0px 0 50px 0;
    padding-top: 5px;
  }
  h3 {
    font-family: "GT Sectra Bold";
    font-weight: bold;
    margin-top: 50px;
    padding-top: 40px;
    font-size: 25px;
    border-top: 1px solid #d2bab4;
  }

  #score {
    color: #fff;
    padding: 35px;
    font-size: 35px;
    width: 50px;
    float: left;
    margin: 0px 20px 20px 0;
    position: absolute;
    left: 0;
    top: 0;
    font-family: "Whyte Black";
  }
  .chart-container {
    display: flex;
    width: 90%;
    height: 400px;
  }
  @media all and (max-width: 767px) {
    .citypage {
      margin-left: 10px;
      padding: 0 1em 0 0.5em;
    }
    h2 {
      padding-left: 110px;
      margin-top: 0px;
      margin-bottom: 40px;
      font-size: 30px;
      font-size: 25px;
      padding-right: 10px;
      line-height: 130%;
    }
    #score {
      font-size: 25px;
      width: 30px;
      margin-bottom: 30px;
      font-family: "Whyte Black";
    }
    h3 {
      margin-top: 0;
    }
  }
</style>
