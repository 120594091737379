<script>
    import { location } from "svelte-spa-router";
</script>

{#if $location == "/callsforservice" || $location == "/useofforce" || $location == "/officerinvolvedshootings" || $location == "/arrests" || $location == "/crime" || $location == "/trafficpedestrianstops" || $location == "/complaints" || $location == "/policecontactinformation" || $location == "/training" || $location == "/policies" || $location == "/instancesofofficersshootingfirearms"}
    <div id="category-tiles">
        <a
            href="/#/complaints"
            class={$location == "/complaints" ? "active" : ""}
            ><div class="tile first">Complaints About Police Misconduct</div></a
        >
        <a
            href="/#/instancesofofficersshootingfirearms"
            class={$location == "/instancesofofficersshootingfirearms"
                ? "active"
                : ""}
            ><div class="tile">Instances of Officers Shooting Firearms</div>
        </a>
        <a
            href="/#/useofforce"
            class={$location == "/useofforce" ? "active" : ""}
            ><div class="tile">Use of Force</div>
        </a>
        <a href="/#/arrests" class={$location == "/arrests" ? "active" : ""}
            ><div class="tile">Arrests</div>
        </a>
        <a
            href="/#/callsforservice"
            class={$location == "/callsforservice" ? "active" : ""}
            ><div class="tile">Calls for Service/911 Calls</div>
        </a>
        <a
            href="/#/trafficpedestrianstops"
            class={$location == "/trafficpedestrianstops" ? "active" : ""}
            ><div class="tile">Traffic/ Pedestrian Stops</div>
        </a>
        <a href="/#/training" class={$location == "/training" ? "active" : ""}
            ><div class="tile">Training</div>
        </a>
        <a href="/#/crime" class={$location == "/crime" ? "active" : ""}
            ><div class="tile">Crime Reports</div>
        </a>
        <a
            href="/#/policecontactinformation"
            class={$location == "/policecontactinformation" ? "active" : ""}
            ><div class="tile">Police Contact Information</div>
        </a>
        <a href="/#/policies" class={$location == "/policies" ? "active" : ""}
            ><div class="tile policies">Policies</div>
        </a>
    </div>
{/if}

<style>
    a.active .tile {
        background-color: #0e002c;
        color: #fff7f0;
    }
    #category-tiles {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 200;
        left: 0;
        z-index: 100;
    }
    .tile {
        color: #0e002c;
        padding: 10px;
        font-size: 15px;
        width: 100px;
        height: 100px;
        background-color: #fff7f0;
        border-right: 1px solid #0e002c;
        border-top:1px solid #2e1d97;
    }
    .tile.first{
        border-top: 0px;
    }
    .tile.policies{
        border-bottom: 1px solid #0e002c;
    }
    a .tile:hover {
        text-decoration: none !important;
        color: #fff !important;
        background-color: #2e1d97;
    }
    .tile.active {
        color: #fff;
        padding: 35px;
        font-size: 15px;
        width: 50px;
        height: 50px;
        background-color: #0e002c;
    }
    @media all and (max-width: 767px) {
        #category-tiles {
            display: none;
        }
    }
</style>
