<script>
  export let params;
  import { onMount } from "svelte";
  import loadData from "../../utils/loadData";
  import * as d3 from "d3";
  import colorScale from "../../utils/colorScale";
  import CategoryColumn from "../city-column/CategoryColumn.svelte";
  import CategoryNav from "../CategoryNav.svelte";
  import Legend2 from "../Legend2.svelte";
  import DataTypeDetail from "../score-detail/DataTypeDetail.svelte";
  import DataTypeIntro from "../sections/DataTypeIntro.svelte";
  import DataTypeBullets from "../sections/DataTypeBullets.svelte";
  import { LayerCake, Svg } from "layercake";
  import Sankey from "../sankey/Sankey.svelte";
  import arrestsdata from "../../data/arrest";
  import callsforservicedata from "../../data/cfs";
  import complaintsdata from "../../data/complaints";
  import crimedata from "../../data/crime";
  import officerinvolvedshootingsdata from "../../data/ois";
  import policecontactinfodata from "../../data/contact";
  import policiesdata from "../../data/policy";
  import trainingdata from "../../data/training";
  import trafficpedestrianstopsdata from "../../data/traffic";
  import useofforcedata from "../../data/uof";

  let cities = [];

  onMount(async () => {
    window.scrollTo(0, 0);
    let data = await loadData();
    cities = data[0];
    cities.sort((a, b) => d3.ascending(b.cityScore, a.cityScore));
  });
  const shortNames = [
    "arrests",
    "callsforservice",
    "complaints",
    "crime",
    "officerinvolvedshootings",
    "policecontactinfo",
    "policies",
    "training",
    "trafficpedestrianstops",
    "useofforce",
  ];
  const varData = [
    arrestsdata,
    callsforservicedata,
    complaintsdata,
    crimedata,
    officerinvolvedshootingsdata,
    policecontactinfodata,
    policiesdata,
    trainingdata,
    trafficpedestrianstopsdata,
    useofforcedata,
  ];
  const properNames = [
    "Arrests",
    "Calls for Service/911",
    "Complaints",
    "Crime/Crime Mapping",
    "Officer Involved Shootings",
    "Police Contact Information",
    "Policies",
    "Training",
    "Traffic and Pedestrian Stops",
    "Use of Force",
  ];
  const dataTypeVar = [
    "arr_subtotal",
    "cfs_subtotal",
    "complaints_subtotal",
    "crime_subtotal",
    "shooting_subtotal",
    "contact_info_subtotal",
    "policy_subtotal",
    "training_subtotal",
    "stops_subtotal",
    "uof_subtotal",
  ];
  const dataTypeIndex = shortNames.indexOf(params.dataType);
  const categoryData = varData[dataTypeIndex];
  console.log("categoryData", categoryData);
  console.log("useofforcedata", useofforcedata);
</script>

<div class="categorypage">
  <h2>
    {properNames[dataTypeIndex]}
  </h2>
  <DataTypeIntro {params} />
  <div class="chart-wrap">
    <CategoryColumn categoryVar={dataTypeVar[dataTypeIndex]} />
  </div>
  <DataTypeBullets {params} />
  <div class="chart-container">
    <LayerCake data={categoryData}>
      <Svg>
        <Sankey colorNodes={(d) => "#2E1D97"} colorLinks={(d) => "#97DFD1"} />
      </Svg>
    </LayerCake>
  </div>
</div>

<style>
  .categorypage {
    margin-left: 120px;
  }
  h2 {
    font-family: "Whyte Black";
    font-size: 50px;
    font-weight: bold;
    margin: 0px 0 50px 0;
    padding-top: 5px;
  }
  span.strong {
    font-family: "Whyte Bold";
  }

  .chart-wrap {
    margin: 50px 0;
  }
  .chart-container {
    display: flex;
    width: 100%;
    height: 500px;
    margin: 50px 0;
  }
  @media all and (max-width: 767px) {
    .categorypage {
      margin-left: 0px;
    }
    h2 {
      margin-top: 0px;
      margin-bottom: 30px;
      font-size: 35px;
      line-height: 130%;
    }
    .chart-wrap {
      margin: 30px 0;
    }
  }
</style>
