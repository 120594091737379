<script>
  import { onMount } from "svelte";
  import loadData from "../../../utils/loadData";
  import * as d3 from "d3";
  import { LayerCake, Svg } from "layercake";
  import Sankey from "../../sankey/Sankey.svelte";
  import data from "../../../data/contact";
  import CategoryColumn from "../../city-column/CategoryColumn.svelte";
  import CategoryNav from "../../CategoryNav.svelte";
  import { cityTitle } from "../../../utils/store.js";

  cityTitle.set("Police Contact Information");

  let cities = [],
    ccategory = [],
    categoryData = [];

  onMount(async () => {
    window.scrollTo(0, 0);
    let data = await loadData();
    cities = data[0];

    cities.sort((a, b) => d3.ascending(b.cityScore, a.cityScore));
  });
</script>

<div class="categorypage">
  <h2>Police Contact Information</h2>
  <p>
    Cities and counties should provide the public with information about ways to contact and interact with the local police or sheriff’s department in nonemergency situations. Local residents may wish to be able to contact officers who patrol their area or to speak informally to supervisors about local concerns. Information such as telephone numbers and email addresses for patrol and other police units should be posted online. This information should go beyond merely providing a single main switchboard number. 
  </p>
  <div class="chart-wrap">
    <CategoryColumn categoryVar={"contact_info_subtotal"} />
  </div>
  <p>
    Vera examined available police contact information and evaluated it for the following transparency characteristics:
  </p>
  <ul>
    <li>
      <span class="strong">URL</span>: An easily located and accessible website containing police contact data.
    </li>
    <li>
      <span class="strong">Contact level</span>: Whether information is provided for individual officers, subunits within the police department, or solely for a main office.
    </li>
    <li>
      <span class="strong">Contact type</span>: Whether information is provided allowing contact with the police by phone, email, street address, or fax.
    </li>
  </ul>
  <div class="chart-container">
    <LayerCake {data}>
      <Svg>
        <Sankey colorNodes={(d) => "#2E1D97"} colorLinks={(d) => "#97DFD1"} />
      </Svg>
    </LayerCake>
  </div>
</div>

<style>
  .categorypage {
    margin-left: 120px;
  }
  h2 {
    font-family: "Whyte Black";
    font-size: 50px;
    font-weight: bold;
    margin: 0px 0 50px 0;
    padding-top: 5px;
  }
  span.strong {
    font-family: "Whyte Bold";
  }

  .chart-wrap {
    margin: 50px 0;
  }
  .chart-container {
    display: flex;
    width: 100%;
    height: 300px;
    margin: 50px 0;
  }
  .anno{
    font-style: italic;
    color: #555;
    text-align: right;
    width: 60%;
    margin-left: 30%;
    margin-right: 10%;
    margin-top: 0;
    font-size: 14px;
    margin-bottom: 50px;
  }
  @media all and (max-width: 767px) {
    .categorypage {
      margin-left: 0px;
    }
    h2 {
      margin-top: 0px;
      margin-bottom: 30px;
      font-size: 35px;
      line-height: 130%;
    }
    .chart-wrap {
      margin: 30px 0 10px;
    }
    .anno{
      margin-bottom: 30px;
    }
  }
  @media all and (max-width: 500px) {
    .categorypage {
      padding: 0 1em 0 1em;
    }
    h2{
      bottom:15px;
    }
  }
</style>