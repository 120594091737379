<script>
  import { onMount } from "svelte";
  import loadData from "../../utils/loadData";
  import colorScale from "../../utils/colorScale";
  export let ccity;
  import * as d3 from "d3";
  import Btn from "../Btn.svelte";

  let cityNames = [];
  let descriptorNames = [];
  let heatMapData = [];
  let descriptorData = [];
  let currentIndex, currentIndexD;

  onMount(async (d) => {
    let dataset = await loadData();
    let cities = dataset[1];
    cities.sort((a, b) => d3.descending(b.city, a.city));
    let descriptors = dataset[2];
    descriptors.sort((a, b) => d3.descending(b.city, a.city));

    cityNames = cities.map((a) => a.city);
    currentIndex = cityNames.indexOf(ccity.city);
    heatMapData = [cities[currentIndex]][0];

    descriptorNames = descriptors.map((a) => a.city);
    currentIndexD = descriptorNames.indexOf(ccity.city);
    descriptorData = [descriptors[currentIndexD]][0];
  });
</script>

<div id="dept-data">
  Access the {ccity.dept}'s
  <a class="records" target="_blank" href={ccity.crime_url}
    >crime reports data</a
  >
</div>
<p class="definitions"><a href="/#/crime">Definitions of each variable →</a></p>
<div id="wrap">
  <div id="vars-col-1">
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.crime_download)}"
        >
        <div class="scorenumber score{heatMapData.crime_download}">{heatMapData.crime_download}</div>
        </div>
      Downloadable
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.crime_url)}"
        >
        <div class="scorenumber score{heatMapData.crime_url}">{heatMapData.crime_url}</div>
        </div>
      URL
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.crime_agg_type)}"
        >
        <div class="scorenumber score{heatMapData.crime_agg_type}">{heatMapData.crime_agg_type}</div>
        </div>
      Aggregation level
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.crime_codebook)}"
        >
        <div class="scorenumber score{heatMapData.crime_codebook}">{heatMapData.crime_codebook}</div>
        </div>
      Codebook or data dictionary
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.crime_data_type)}"
        >
        <div class="scorenumber score{heatMapData.crime_data_type}">{heatMapData.crime_data_type}</div>
        </div>
      Data format
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.crime_embed)}"
        >
        <div class="scorenumber score{heatMapData.crime_embed}">{heatMapData.crime_embed}</div>
        </div>
      Embedded analytics
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.crime_FAQ)}"
        >
        <div class="scorenumber score{heatMapData.crime_FAQ}">{heatMapData.crime_FAQ}</div>
        </div>
      FAQ/User guide
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.crime_location)}"
        >
        <div class="scorenumber score{heatMapData.crime_location}">{heatMapData.crime_location}</div>
        </div>
      Location
    </div>
  </div>
  <div id="vars-col-2">
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.crime_off_demo)}"
        >
        <div class="scorenumber score{heatMapData.crime_off_demo}">{heatMapData.crime_off_demo}</div>
        </div>
      Officer demographics
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.crime_coverage)}"
        >
        <div class="scorenumber score{heatMapData.crime_coverage}">{heatMapData.crime_coverage}</div>
        </div>
      Timespan
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.crime_freq)}"
        >
        <div class="scorenumber score{heatMapData.crime_freq}">{heatMapData.crime_freq}</div>
        </div>
      Update frequency
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.crime_vict_demo)}"
        >
        <div class="scorenumber score{heatMapData.crime_vict_demo}">{heatMapData.crime_vict_demo}</div>
        </div>
      Victim demographics
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.crime_off_demo)}"
        >
        <div class="scorenumber score{heatMapData.crime_off_demo}">{heatMapData.crime_off_demo}</div>
        </div>
      Accused demographic
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.crime_charge)}"
        >
        <div class="scorenumber score{heatMapData.crime_charge}">{heatMapData.crime_charge}</div>
        </div>
      Charge
    </div>
    <div class="variable">
        <div
          class="var-score"
          style="background-color:{colorScale(heatMapData.crime_cleared)}"
        >
        <div class="scorenumber score{heatMapData.crime_cleared}">{heatMapData.crime_cleared}</div>
        </div>
      Cleared
    </div>
  </div>
</div>

<style>
  #wrap {
    display: flex;
    padding: 0 2% 2% 2%;
  }
  #vars-col-1,
  #vars-col-2 {
    width: 40%;
    padding-right: 5%;
  }
  .variable {
    display: flex;
    margin-bottom: 8px;
  }
  .var-score {
    width: 50px;
    height: 25px;
    margin-right: 20px;
    border: 1px solid #0a0319;
  }
  .scorenumber{
    text-align: center;
    color:#fff;
    visibility: hidden;
  }
  .score0{
    color:#000;
  }
  .variable:hover .scorenumber,
  .var-score:hover .scorenumber{
    visibility: visible;
  }
  #dept-data {
    padding: 2%;
    font-size: 18px;
    border-bottom: 1px solid #000;
    margin-bottom: 1.5em;
  }
  .definitions {
    padding-left: 1em;
  }
  .strong {
    font-family: "Whyte Bold";
    padding: 0.5em 0;
  }
  @media all and (max-width: 650px) {
    #wrap {
      flex-direction: column;
    }
    #vars-col-1,
    #vars-col-2,
    #dept-data {
      width: 90%;
      padding: 5% 5% 0 5%;
      margin: 0;
      background-color: #fff;
    }
    #dept-data {
      font-size: 16px;
    }
  }
</style>
