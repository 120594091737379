export default {
  nodes: [
    { id: "City Score" },
    { id: "Use of Force (13.3%)" },
    { id: "Officer Involved Shootings (13.3%)" },
    { id: "Arrest (10%)" },
    { id: "Calls For Service/911 (10%)" },
    { id: "Crime/Crime Mapping (13.3%)" },
    { id: "Traffic/Pedestrian stops (10%)" },
    { id: "Complaints (13.3%)" },
    { id: "Police Contact Information (6.6%)" },
    { id: "Training (10%)" },
    { id: "Policies (6.6%)" },
  ],
  links: [
    { source: "City Score", target: "Use of Force (13.3%)", value: 13.3 },
    {
      source: "City Score",
      target: "Officer Involved Shootings (13.3%)",
      value: 13.3,
    },
    { source: "City Score", target: "Arrest (10%)", value: 10.0 },
    {
      source: "City Score",
      target: "Calls For Service/911 (10%)",
      value: 10.0,
    },
    {
      source: "City Score",
      target: "Crime/Crime Mapping (13.3%)",
      value: 13.3,
    },
    {
      source: "City Score",
      target: "Traffic/Pedestrian stops (10%)",
      value: 10.0,
    },
    { source: "City Score", target: "Complaints (13.3%)", value: 13.3 },
    {
      source: "City Score",
      target: "Police Contact Information (6.6%)",
      value: 6.6,
    },
    { source: "City Score", target: "Training (10%)", value: 10 },
    { source: "City Score", target: "Policies (6.6%)", value: 6.6 },
  ],
};
