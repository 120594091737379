<script>
    import { onMount } from "svelte";
    onMount(() => window.scrollTo(0, 0));
    import { cityTitle } from "../../utils/store.js";

    cityTitle.set("Acknowledgments");
</script>
<div class="pagewrap">
<h1>Acknowledgments</h1>
<p>
    Vera would like to thank the following people, associations, and
    organizations for sharing their advice, guidance, and time throughout the
    process of creating this tool:
</p>

<p>
    Jessi Ahart, Maryland Communities United; Kim Bogucki and Felisa Bryant, IF
    Project; Sam Benavides, Jordan Buckley, Eric Martinez, and Gloria
    Olivio-Mendoza, Mano Amiga; Allie Beth, Marvin Arnold, and Vedisia Green,
    Eye on Surveillance; Center for Popular Democracy; Leon Delouth, Jackie
    Osby, and Ucedrah Osby, All of Us or None; Renee Dominguez, Karl Jacobson,
    and Stacey Spell, Project Longevity; Sade Dumas, Orleans Parish Prison
    Reform Coalition; Lauren Ephraim and David Heppard, Freedom Project; Barbara
    Fair; Josh Harsin and Jomelia Watson-Thompson, ThrYve; Nadine Horton,
    Whalley-Edgewood-Beaver Hills; Catherine John, Black and Brown United in
    Action; John Jairo Lugo, Unidad Latina en Acción; Tahirah Osbourne and
    Justin Richards, Huntsville Bail Fund; Laura Serna; and Marcus Winn and
    India Woods, More<sup>2</sup>.
</p>

<p>
    Vera would like to thank the following colleagues for their support in
    providing research, review, editing, design, and invaluable advice:
</p>

<p>
    Jac Arnade-Colwill, Karen Ball, Daniel Bodah, Léon Digard, Sara Duell, Mary
    Fleck, Daniela Gilbert, Chris Henrichson, Jill Hubley, Kica Matos, Sarah
    Minion, Cristina Onea, Michelle Parris, Jim Parsons, Insha Rahman, Cindy
    Reed, Giorgia Sage, Nina Siulc, William Snowden, Aaron Stagoff-Belfort,
    Maria Theresia Starzmann, Anna Stenkamp, Jason Tan de Bibiana, Elle Teshima,
    Caroline Walcott, and Frankie Wunschel. Thank you to the support of the data
    interns Sierra Billé, Nicholas DiLoreto, Danie DiRuggiero, Janiya Hubbard,
    and Chinedu Onyeka. Thank you to the team at EpsteinWords for editorial
    support.
</p>

<p>
    The Police Data Transparency Index was made possible by the generous support of
    George Loening and Evan Guillemin. Work on this project was supported by a
    volunteer partnership between the Vera Institute of Justice, NBCUniversal,
    and Patterson Belknap Webb & Tyler LLP.
</p>
</div>
<style>
    h1 {
        font-family: "Whyte Black";
    }
@media all and (max-width: 500px) {
    h1{
      margin-top: 0;
    }
    h6{
      margin: 0.75em 0;
    }
    .pagewrap {
      padding: 0 1em 0 1em;
    }
  }
</style>
