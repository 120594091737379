<script>
  import { onMount } from "svelte";
  import loadData from "../../../utils/loadData";
  import * as d3 from "d3";
  import { LayerCake, Svg } from "layercake";
  import Sankey from "../../sankey/Sankey.svelte";
  import data from "../../../data/cfs";
  import CategoryColumn from "../../city-column/CategoryColumn.svelte";
  import CategoryNav from "../../CategoryNav.svelte";
  import { cityTitle } from "../../../utils/store.js";

  cityTitle.set("Calls for Service");

  let cities = [],
    ccategory = [],
    categoryData = [];

  onMount(async () => {
    window.scrollTo(0, 0);
    let data = await loadData();
    cities = data[0];

    cities.sort((a, b) => d3.ascending(b.cityScore, a.cityScore));
  });
</script>

<div class="categorypage">
  <h2>Calls for Service/911 Calls</h2>
  <p>
    “Calls for service” (often abbreviated to CFS) usually occur when a person contacts an emergency call center about an incident and requests a police response and the call center logs the call. Officers are then dispatched to some—but not all—of these calls.
  </p>
  <p>
    Calls for service are usually generated when a member of the public dials 911, but not always. Calls for service may also be “officer initiated,” i.e., an officer contacts the emergency call center to report acting on something they have seen or learned about in the field. Calls for service may also be received by phone or through an in-person report made to police in other ways.
  </p>
  <p>
    An officer sent to respond to a call may contact the dispatcher after completing the assignment with information about how the call was resolved.
  </p>
  <div class="chart-wrap">
    <CategoryColumn categoryVar={"cfs_subtotal"} />
  </div>
  <p class="anno">Of the 94 jurisdictions in the index, 37 do not provide information on calls for service/911 calls and received a score of zero.</p>
  <p>
    Cities and counties should publicly report all calls for police service received by the local emergency call center. Vera examined available calls for service/911 call information for the following transparency characteristics:
  </p>
  <ul>
    <li>
      <span class="strong">Officer initiated</span>: Whether the data distinguishes police responses that were initiated by an officer from those initiated following a request from the public.
    </li>
    <li>
      <span class="strong">Dispatch</span>: Whether the dataset distinguishes calls that did and did not result in an officer being dispatched to the location.
    </li>
    <li>
      <span class="strong">Timestamps</span>: Whether the dataset specifies the time at which a call was received and/or when it was dispatched.
    </li>
    <li>
      <span class="strong">Outcome</span>: Whether information about the resolution of the call is included in the dataset.
    </li>
    <li>
      <span class="strong">Call type</span>: Whether the dataset indicates the categories or types of calls received—e.g., “suspicious person,” “disturbance,” “tow vehicle,” etc.
    </li>
    <li>
      <span class="strong">Priority</span>: Whether information is given about the relative priority level assigned to the call by the staff of the emergency call center.
    </li>
    <li>
      <span class="strong">URL</span>: Whether the city or county makes data available via an easily located and accessible website.
    </li>
    <li>
      <span class="strong">Downloadability</span>: Whether the information can be downloaded.
    </li>
    <li>
      <span class="strong">Data format</span>: The extent to which the data is in a file format that can be independently analyzed.
    </li>
    <li>
      <span class="strong">Ideally, data should be available describing each incident; Vera gave a lower score for data that is supplied only in aggregate summary over time (e.g., quarterly or annual totals).
    </li>
    <li>
      <span class="strong">Codebook or data dictionary</span>: Visitors to the website should be provided with a dictionary that explains what kinds of events are recorded in the data and the meanings of any abbreviations, codes, or specialized jargon in the data.
    </li>
    <li>
      <span class="strong">Location</span>: Whether the data provides the locations of incidents in the form of GPS or other geocode coordinates, street address, or nearest intersection.
    </li>
    <li>
      <span class="strong">Embedded analytics</span>: Whether the website has online tools with which a visitor can create maps and/or make visualizations such as charts or graphs without downloading the data.
    </li>
    <li>
      <span class="strong">Timespan</span>: The length of time the website provides data from: more than two years, one to two years, less than a year, or none (if data is taken down shortly after posting without being archived).
    </li>
    <li>
      <span class="strong">Update frequency</span>: Whether the data is updated biweekly, monthly, quarterly, biannually, annually, or less than annually.
    </li>
    <li>
      <span class="strong">FAQ/User guide</span>: Whether the website contains information to help users understand how to use the website and orient themselves to its features.
    </li>
  </ul>
  <div class="chart-container">
    <LayerCake {data}>
      <Svg>
        <Sankey colorNodes={(d) => "#2E1D97"} colorLinks={(d) => "#97DFD1"} />
      </Svg>
    </LayerCake>
  </div>
</div>

<style>
  .categorypage {
    margin-left: 120px;
  }
  h2 {
    font-family: "Whyte Black";
    font-size: 50px;
    font-weight: bold;
    margin: 0px 0 50px 0;
    padding-top: 5px;
  }
  span.strong {
    font-family: "Whyte Bold";
  }

  .chart-wrap {
    margin: 50px 0 20px;
  }
  .chart-container {
    display: flex;
    width: 100%;
    height: 500px;
    margin: 50px 0;
  }
  .anno{
    font-style: italic;
    color: #555;
    text-align: right;
    width: 60%;
    margin-left: 30%;
    margin-right: 10%;
    margin-top: 0;
    font-size: 14px;
    margin-bottom: 50px;
  }
  @media all and (max-width: 767px) {
    .categorypage {
      margin-left: 0px;
    }
    h2 {
      margin-top: 0px;
      margin-bottom: 30px;
      font-size: 35px;
      line-height: 130%;
    }
    .chart-wrap {
      margin: 30px 0 10px;
    }
    .anno{
      margin-bottom: 30px;
    }
  }
  @media all and (max-width: 500px) {
    .categorypage {
      padding: 0 1em 0 1em;
    }
    h2{
      bottom:15px;
    }
  }
</style>
