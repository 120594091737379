<script>
  import Intro from "../Intro.svelte";
  import Legend from "../Legend.svelte";
  import CityTable from "../grid/CityTable.svelte";
  import filtered from "../../App.svelte";
  import { cityTitle } from "../../utils/store.js";

  cityTitle.set("");

</script>

<Intro />
<Legend />
<CityTable {filtered} />
