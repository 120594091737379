<script>
  import { onMount } from "svelte";
  onMount(() => window.scrollTo(0, 0));
  import { cityTitle } from "../../utils/store.js";

  cityTitle.set("Methodology");
</script>

<div class="pagewrap">
  <h1>Vera's Methodology</h1>
  <p>
    This page provides a brief overview of how Vera created the Police Data
    Transparency Index. For more information, please download a detailed account
    of Vera’s <a href="/PDTI_Full Methodology.pdf">methods and data sources</a>.
  </p>

  <h6>Defining data transparency</h6>

  <p>
    To develop a definition of police data transparency and identify the
    elements that make transparency most useful to the public, Vera drew on
    three sources of information:
  </p>

  <ul>
    <li>interviews and conversations with community members,</li>
    <li>a review of academic literature, and</li>
    <li>
      a scan of legal mandates from across the United States that establish
      requirements about police transparency.
    </li>
  </ul>

  <p>
    <i>Interviews</i>: Vera researchers conducted a total of 22 Zoom interviews
    with 39 people. Interviewees were drawn from communities across the United
    States spanning all major regions of the country, including people from
    urban and rural settings.
  </p>

  <p>
    Interviews were semi-structured. Vera asked respondents to discuss their
    experiences accessing police data, how they wanted to use police data in
    their work, and what they thought an ideal data portal would look like.
    During each interview, Vera showed participants locally available police
    data and asked whether they thought any data types were missing and whether
    the existing data was accessible.
  </p>

  <p>
    To ensure thoroughness and an ethical approach in building this tool, Vera
    held share-back sessions that were open to all community members Vera had
    interviewed. At these sessions, Vera presented findings from the interviews,
    an overview of how the Police Data Transparency Index assigned scores to
    locales, and a preview of the Police Data Transparency Index user interface.
    Participants gave feedback on Vera’s interview findings, suggested changes
    to the scoring technique, and indicated ways to improve the user interface.
  </p>

  <p>
    <i>Review of best-practice guides and academic literature</i>: Vera reviewed
    academic papers and professional guides concerning best practices for police
    data transparency. From these, Vera identified key data types that should be
    made public and elements of how data should be published to ensure
    accessibility.
  </p>

  <p>
    <i>Scan of legal mandates</i>: With the assistance of pro bono legal
    volunteers, Vera reviewed the contents of legal mandates (including
    statutes, court orders, and consent decrees) from across the United States
    that established requirements about police transparency.
  </p>

  <h6>Creating the tool</h6>

  <p>
    Vera drew on the findings of these three research activities to identify:
  </p>
  <ol>
    <li>
      the 10 types of data the tool should capture (e.g., use of force, arrests,
      etc.) and
    </li>
    <li>
      the variables that would best capture the degree to which data is
      accessible and usable (e.g., whether the data can be downloaded, how
      regularly it is updated, etc.).
    </li>
  </ol>

  <p>
    <i>Location selection</i>: In 2020, Vera built a limited index of police
    data available in the largest 50 cities in the United States, plus the
    largest city in each of the 22 states that do not have a top-50 largest
    city. Vera expanded this set of 72 cities to include all locations where
    U.S. members of the Major City Chiefs Association are based, and all
    locations in which Vera interviewed community members about their views on
    transparency. This resulted in a group of 94 cities and counties in which 25
    percent of the U.S. population lives.
  </p>

  <p>
    <i>Data collection</i>: Between October 2021 and January 2022, a team of
    Vera researchers examined the websites of the 94 cities and counties. For
    each of the 10 data types, Vera researchers used a standardized matrix to
    record whether the data was available and the degree to which it was
    accessible. Vera researchers only included in the index official police data
    that was posted or linked by a police department, or on another local
    government website. For validation purposes, a different researcher made a
    second pass on each site. Where differences were noted between the initial
    scan and the validation scan, a third researcher reviewed and resolved the
    discrepancy. The third researcher also checked the unavailability of all
    missing datasets.
  </p>
  <p>
    Vera coded the indexed markers using set criteria to assess availability
    (presence of the data on the web, downloadability) and accessibility (file
    format of the data, inclusion of demographic data, frequency of updates,
    etc.) and produced a subscore for each data type.
  </p>
  <p>
    In May 2022, Vera researchers conducted an additional review of the Police
    Data Transparency Index database to corroborate and update the city scores.
    Researchers rechecked the 94 cities to update the coding of variables in
    each available dataset and to add or remove datasets where necessary and
    consistent with the inclusion criteria. Vera reported updates to city scores
    with explanations on the Police Data Transparency Index website on May 27,
    2022.
  </p>

  <p>
    <i>Calculating total scores</i>: Vera weighted the subscores for each data
    type based on the importance of that data type and calculated a total score
    out of 100 for each location to indicate its degree of police data
    transparency.
  </p>

  <p>
    Interested readers can view a more <a
      href="/PDTI_Scoring_Metric_for_Web_10_17_2023.xlsx"
      >detailed description</a
    >
    of how Vera created the scores, along with a
    <a href="/PTI_For_Web_10_13_2023.xlsx">spreadsheet</a> of the individual variables
    measured and their corresponding point counts.
  </p>
</div>

<style>
  h1 {
    font-family: "Whyte Black";
  }
  h6 {
    font-size: 26px;
    font-style: normal;
    line-height: 34px;
    letter-spacing: 0;
    text-align: left;
    font-family: "Whyte Bold";
    color: #0a0319;
    margin: 1em 0;
  }
  @media all and (max-width: 500px) {
    h1 {
      margin-top: 0;
    }
    h6 {
      margin: 0.75em 0;
    }
    .pagewrap {
      padding: 0 1em 0 1em;
    }
  }
</style>
