<script>
  export let params;
</script>
{#if params.dataType == "useofforce"}
  <p>
    All incidents in which police use force should be recorded and reported to
    the public.
  </p>
  <p>Use of force occurs any time an officer tries to compel compliance.</p>
  <p>
    Use of force includes the use of unarmed physical force, from holds to
    punches and kicks.
  </p>
  <p>
    Use of force also includes all uses of coercive restraints or weapons,
    including but not limited to strikes with a baton or other hard object, use
    of conducted energy devices (i.e. Tasers), pepper spray, tear gas, bean bag
    rounds, rubber bullets, and firearms or other deadly weapons.
  </p>
{:else if params.dataType == "arrests"}
<p>
    Police departments should publicly report all incidents in which officers
    take people into custody to answer charges of violating the law.
  </p>
{:else if params.dataType == "callsforservice"}
  <p>
    “Calls for service” (often abbreviated CFS) usually occur when a person
    contacts an emergency call center about an incident and requests a police
    response and the call center logs the call. Officers are then dispatched to
    some—but not all—of these calls.
  </p>
  <p>
    Calls for service are usually generated when a member of the public dials
    911, but not always. Calls for service may also be “officer initiated,”
    i.e., an officer contacts the emergency call center to report acting on
    something they have seen or learned about in the field. Calls for service
    may also be received by phone or through an in-person report made to police
    in other ways.
  </p>
  <p>
    An officer sent to respond to a call may contact the dispatcher after
    completing the assignment with information about how the call was resolved.
  </p>
{:else if params.dataType == "complaints"}
  <p>
    Communities often express great concern about misbehavior by police
    officers. Police departments ordinarily have an Internal Affairs,
    Professional Standards, or similar internal office dedicated to receiving
    and investigating complaints against police officers. Complaints may be
    filed by community members or by other police officers.
  </p>
  <p>
    Many places have determined that the police should not police themselves and
    have created independent or quasi-independent agencies to remove complaints
    about officer conduct from police control. Vera believes that implementing a
    independent civilian oversight body aligns with community needs and best
    practices.
  </p>
  <p>
    However, parallel internal review of complaints should still be conducted
    even where an external oversight agency exists. Internal reviews can lead to
    insights that inform department leadership of problems with training,
    policy, and supervision.
  </p>
{:else if params.dataType == "crime"}
  <p>
    Police learn about crimes when the public reports a crime or a crime is
    discovered by the police in the course of their duties. Crimes are usually
    categorized as violent crimes, property crimes, or offenses against public
    order. Crimes may be reported to the police or discovered as they occur or
    at a later time, and may be “cleared” (i.e., solved) or remain unresolved.
  </p>
{:else if params.dataType == "officerinvolvedshootings"}
  <p>
    Officer involved shootings are incidents in which an officer fires a gun.
    All incidents involving officers shooting a gun (apart from during firearms
    training) should be reported to the public.
  </p>
  <p>
    Vera indexed this data separately from use of force data because shooting
    incidents are of the greatest interest to the public.
  </p>
  <p>
    Also, shootings also may not appear in use of force reports. For example,
    use of force reports may not contain incidents in which an officer fires a
    gun but does not hit anyone, or when an officer fires a gun accidentally.
    However, these types of incidents should still be reported publicly along
    with incidents in which police gunfire injures or kills a person.
  </p>
{:else if params.dataType == "policecontactinfo"}
  <p>
    Police departments should provide the public with information about ways to
    contact and interact with the department in non-emergency situations. Many
    times, local residents would like to be able to contact officers who patrol
    their area or speak informally to supervisors about local concerns.
    Information such as telephone numbers and email addresses for patrol and
    other police units should be posted online. This information should go
    beyond merely providing a single main switchboard number.
  </p>
{:else if params.dataType == "policies"}
  <p>
    Police departments have rules and expectations that officers must comply
    with, including how officers are to act when conducting various police
    activities.
  </p>
  <p>
    Certain policies are of special concern to the public—for example, policies
    regarding when officers may use force.
  </p>
  <p>
    Police departments should make the manuals containing their official
    policies publicly available. These manuals should be redacted only to the
    extent absolutely necessary to maintain the confidentiality of sensitive
    information.
  </p>
{:else if params.dataType == "training"}
  <p>
    Police trainings vary greatly across the 18,000+ law enforcement agencies in
    the United States. Training can be provided by state or regional academies
    and/or by police departments themselves. The contents of training courses,
    the amount of training, and whether training continues during an officer’s
    service all vary.
  </p>
  <p>
    Training is also one of the most frequent areas focused on when
    jurisdictions seek to make changes to police operations.
  </p>
  <p>
    Because of how much police training varies around the nation and the focus
    on training as an avenue to update or improve police operations, police
    departments should provide the public with information about the training
    provided to staff.
  </p>
{:else if params.dataType == "trafficpedestrianstops"}
  <p>
    Traffic stops are the most frequent kind of encounter between police
    officers and members of the public. Police may also stop people outside of a
    car, which is a pedestrian stop.
  </p>
  <p>
    These encounters are often called “investigative detentions” because the
    person stopped is not free to leave and the officer is trying to determine
    whether a crime has occurred or is in progress.
  </p>
{:else}
<p></p>
{/if}
