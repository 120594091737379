export default {
  nodes: [
    // { id: "City Score" },
    // { id: "Use of Force" },
    // { id: "Officer Involved Shootings" },
    // { id: "Arrest" },
    // { id: "CFS/911" },
    // { id: "Crime/Crime Mapping" },
    // { id: "Traffic/Ped stops" },
    // { id: "Complaints" },
    // { id: "Police contact info" },
    { id: "Training" },
    // { id: "Policies" },
    { id: "Presence" },
    // { id: "Basic Data Collection" },
    // { id: "Dataset Specific Variables" },
    { id: "URL" },
    { id: "Downloadable" },
    // { id: "Data Format" },
    // { id: "Aggregate Level" },
    // { id: "Codebook/Data Dictionary" },
    // { id: "Officer Demographics" },
    // { id: "Civilian Demographics" },
    // { id: "Location" },
    // { id: "Embedded Analytics" },
    // { id: "Timespan" },
    // { id: "Update Frequency" },
    // { id: "FAQ/User Guide" },
    // { id: "Fatalities Included" },
    // { id: "Type of Force Used" },
    // { id: "Officer Injury" },
    // { id: "Civilian Injury" },
    // { id: "Justification" },
    // { id: "Civilian Weapon" },
    // { id: "Weapon Used by Officer" },
    // { id: "Bodycam Footage" },
    // { id: "Arrest Made" },
  ],
  links: [
    // { source: "City Score", target: "Use of Force", value: 13.3 },
    // { source: "City Score", target: "Officer Involved Shootings", value: 13.3 },
    // { source: "City Score", target: "Arrest", value: 10.0 },
    // { source: "City Score", target: "CFS/911", value: 10.0 },
    // { source: "City Score", target: "Crime/Crime Mapping", value: 13.3 },
    // { source: "City Score", target: "Traffic/Ped stops", value: 10.0 },
    // { source: "City Score", target: "Complaints", value: 13.3 },
    // { source: "City Score", target: "Police contact info", value: 6.6 },
    // { source: "City Score", target: "Training", value: 10.0 },
    // { source: "City Score", target: "Policies", value: 6.6 },
    { source: "Training", target: "Presence", value: 10 },
    { source: "Presence", target: "URL", value: 5 },
    { source: "Presence", target: "Downloadable", value: 5 },
  ],
};
